import Avatar from '@components/Avatar';
import CustomButton from '@components/CustomButton';
import StatusDescription from '@components/StatusDescription';
import { ModerationActionsFormatted, ModerationStatuses, ModerationStatusesFormatted } from '@shared/constants';
import { message, notification } from 'antd';
import React, { FC, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { ModerationDataIndexes, ModerationTitles } from '@pages/EventRolePages/ModerationPage/types';
import './styles.scss'
import EyeIcon from '../../../../assets/EyeIcon';
import CommentIcon from '@assets/CommentIcon';
import ArrowIcon from '@assets/ArrowIcon';
import { formatDate } from '@shared/utils';
import ApproveModal from '@pages/EventRolePages/ModerationPage/components/ApproveModal';
import RejectionModal from '@pages/EventRolePages/ModerationPage/components/RejectionModal';
import ModerationDrawer from '@pages/EventRolePages/ModerationPage/components/ModerationDrawer';
import { Options } from '@shared/types';
import InfiniteScrollTable from '@components/InfiniteScrollTable/InfiniteScrollTable';
import PreviewModal from "@pages/EventRolePages/ModerationPage/components/PreviewModal";
import { usePatchOrganizerLandingStatusByIdMutation } from '@store/type-event/landings/landings.api';
import { ModerationStatusAction, OrganizerLanding } from '@store/type-event/models';
import { INIT_PAGE } from "@components/InfiniteScrollTable/constants";
import sendAnalytics from "@hooks/sendAnalytics/sendAnalytics";
import { GTMEventName } from "@hooks/sendAnalytics/constants";
import { useBreakpoints } from '@hooks/browser';

interface ModerationTableProps {
    landings: OrganizerLanding[];
    reasonsData: Options;
    isLoading: boolean;
    refetchLandings: () => void
    isLastPage: boolean
    onNextPage: () => void
    page: number
}

export interface ModalDataType {
    row: OrganizerLanding,
    status: ModerationStatusAction
}

export interface PatchLandingStatusType {
    landing: number
    status: number
    reason?: number
    comment?: string
    partner: string
    statusSlug: ModerationStatuses
}

const ModerationTable: FC<ModerationTableProps> = ({
    landings,
    reasonsData,
    isLoading,
    refetchLandings,
    isLastPage,
    onNextPage,
    page
}) => {
    const isDesktop = useBreakpoints().md
    const [patchLandingStatus] = usePatchOrganizerLandingStatusByIdMutation()
    const [messageApi, contextHolder] = message.useMessage();

    const [rejectionModalData, setRejectionModalData] = useState<ModalDataType | null>(null);
    const [approveModalData, setApproveModalData] = useState<ModalDataType | null>(null);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false)
    const [drawerData, setDrawerData] = useState<OrganizerLanding | null>(null)
    const [isLoadingStatus, setIsLoadingStatus] = useState(false)

    const handleCloseRejectionModal = () => {
        setRejectionModalData(null)
    }

    const handleCloseApproveModal = () => {
        setApproveModalData(null)
    }

    const handleChangeStatus = ({ row, status }: { row: OrganizerLanding, status: ModerationStatusAction }) => {
        if (status.slug === ModerationStatuses.published) {
            setApproveModalData({ row, status })
        }
        if (status.slug === ModerationStatuses.rejected) {
            setRejectionModalData({ row, status })
        }
    }

    const handleCloseDrawer = () => {
        setIsDrawerOpen(false)
    }

    const handleClosePreview = () => {
        setIsPreviewModalOpen(false)
        setIsDrawerOpen(false)
        setDrawerData(null)
    }

    const onPreviewClick = (data: OrganizerLanding) => {
        if (!isDesktop) return null
        setDrawerData(data)
        setIsPreviewModalOpen(true)
        setIsDrawerOpen(true)
    }

    const handleOpenDrawer = () => {
        if (!isDesktop) return null
        setIsDrawerOpen(true)
    }

    const handlePatchLandingStatus = async ({
        landing,
        status,
        reason,
        comment,
        statusSlug,
        partner
    }: PatchLandingStatusType) => {
        try {
            setIsLoadingStatus(true)
            const response = await patchLandingStatus({
                landing, status, reason, comment
            });
            if ('error' in response) {
                setIsLoadingStatus(false)
                void messageApi.open({
                    type: 'error',
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                    content: response?.error?.data?.message || 'OOOPS, something is wrong',
                });
            } else {
                sendAnalytics(`${GTMEventName.landingStatus}_${statusSlug}`)
                refetchLandings()
                if (drawerData) {
                    setDrawerData(response.data.data as unknown as OrganizerLanding)
                }
                setIsLoadingStatus(false)
                notification.open({
                    message: (
                        <span className='moderation-table__notification__message'>
                            <span>
                                Landing page status of
                                {' '}
                                <b>{partner}</b>
                                {' '}
                                has been changed to
                            </span>
                            <StatusDescription status={statusSlug} formattedStatusData={ModerationStatusesFormatted} />
                        </span>
                    ),
                    className: 'moderation-table__notification',
                    placement: 'bottomLeft',
                    closeIcon: false
                });
            }
        } catch (error) {
            setIsLoadingStatus(false)
            return void messageApi.open({
                type: 'error',
                content: 'OOOPS, something is wrong',
            });
        }
    }

    const onRow = useCallback((record: OrganizerLanding) => {
        return {
            onClick: () => {
                setIsDrawerOpen(true)
                setDrawerData(record)
            },
        };
    }, [])

    const columns = [
        {
            title: ModerationTitles.status,
            dataIndex: ModerationDataIndexes.status,
            key: ModerationDataIndexes.status,
            width: 144,
            render: (status: OrganizerLanding['status'], row: OrganizerLanding) => {
                return (
                    <div>
                        <StatusDescription status={status?.name} formattedStatusData={ModerationStatusesFormatted} />
                        {status?.actions
                            ? status?.actions.map(cta => {
                                return (
                                    <div className='my-8' key={cta.id}>
                                        <CustomButton
                                            title={cta.transit}
                                            size='small'
                                            block
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                handleChangeStatus({ status: cta, row })
                                            }}
                                            loading={isLoadingStatus}
                                        />
                                    </div>
                                )
                            })
                            : null}
                    </div>
                )
            }
        },
        {
            title: ModerationTitles.landing_page,
            dataIndex: ModerationDataIndexes.landing_page,
            key: ModerationDataIndexes.landing_page,
            width: 221,
            render: (_: OrganizerLanding['details'], row: OrganizerLanding) => {
                return row.page ? (
                    <div
                        className='moderation-table__landing-page'
                        onClick={(e) => {
                            e.stopPropagation()
                            onPreviewClick(row)
                        }}
                    >
                        <iframe
                            title={`moderation-table_${row.page}`}
                            className='moderation-table__landing-page__iframe'
                            src={row.page}
                        />
                        <EyeIcon />
                    </div>
                ) : (
                    <span>---</span>
                )
            }
        },
        {
            title: ModerationTitles.landing_page_title,
            dataIndex: ModerationDataIndexes.landing_page_title,
            key: ModerationDataIndexes.landing_page_title,
            width: 400,
            render: (_: OrganizerLanding['details'], row: OrganizerLanding) => {
                const splitted = row?.details?.url_path?.link?.split('/') || ''
                const slug = splitted[splitted.length - 1]
                return (
                    <div className='moderation-table__landing-page-title'>
                        <div
                            className='moderation-table__landing-page-title__title'
                        >
                            <span className='moderation-table__landing-page-title__text'>
                                {row?.details?.title || '---'}
                            </span>
                            <span className='moderation-table__landing-page-title__comment'>
                                <span>{row?.details?.history?.length || 0}</span>
                                <CommentIcon fillColor="#57585A" />
                            </span>
                        </div>
                        <div className='moderation-table__landing-page-title__card'>
                            <div className='moderation-table__landing-page-title__card__title'>
                                {row?.details?.url_path?.link && (
                                    <Link to={row?.details?.url_path?.link} target="_blank" onClick={(e) => e.stopPropagation()}>
                                        <span
                                            className='moderation-table__landing-page-title__card__link'
                                        >
                                            {row?.details?.url_path?.link?.replace('https://', '').replace(`${slug}`, '')}
                                        </span>
                                        <span className='text-link campaign-title'>{slug}</span>
                                        <div className='moderation-table__landing-page-title__card__arrow'>
                                            <ArrowIcon />
                                        </div>
                                    </Link>
                                )}
                            </div>
                            <div className='moderation-table__landing-page-title__card__date'>
                                Last modified:
                                {' '}
                                {formatDate(row?.details?.updated_at || '')}
                            </div>
                        </div>
                    </div>
                )
            }
        },
        {
            title: ModerationTitles.sponsor_name,
            dataIndex: ModerationDataIndexes.sponsor_name,
            key: ModerationDataIndexes.sponsor_name,
            width: 276
        },
        {
            title: ModerationTitles.event_name,
            dataIndex: ModerationDataIndexes.event_name,
            key: ModerationDataIndexes.event_name,
            width: 276,
            render: (event: OrganizerLanding['event']) => {
                return (
                    <div className='flex'>
                        <Avatar
                            size='32'
                            url={event?.logo}
                        />
                        <h4 className='mx-6'>{event?.name || '---'}</h4>
                    </div>
                )
            }
        },
    ];

    return (
        <div>
            {contextHolder}
            <InfiniteScrollTable
                isLastPage={isLastPage}
                onNextPage={onNextPage}
                isFirstPage={page === INIT_PAGE}
                loading={isLoadingStatus || isLoading}
                dataSource={landings}
                columns={columns}
                rowKey="id"
                rowClassName={() => 'moderation-table-row'}
                onRow={onRow}
            />
            <PreviewModal
                isOpen={isPreviewModalOpen && Boolean(drawerData)}
                isDrawerOpen={isDrawerOpen}
                drawerData={drawerData}
                onClose={handleClosePreview}
                onOpenDrawer={handleOpenDrawer}
                handleChangeStatus={handleChangeStatus}
            />
            <ModerationDrawer
                isPreviewModalOpen={isPreviewModalOpen}
                isOpen={isDrawerOpen && Boolean(drawerData)}
                drawerData={drawerData}
                onClose={handleCloseDrawer}
                handleChangeStatus={handleChangeStatus}
            />
            {rejectionModalData
                ?
                <RejectionModal
                    isOpen={Boolean(rejectionModalData)}
                    data={rejectionModalData}
                    onClose={handleCloseRejectionModal}
                    reasonsData={reasonsData}
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    handlePatchLandingStatus={handlePatchLandingStatus}
                />
                :
                null}
            {approveModalData
                ?
                <ApproveModal
                    isOpen={Boolean(approveModalData)}
                    data={approveModalData}
                    onClose={handleCloseApproveModal}
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    handlePatchLandingStatus={handlePatchLandingStatus}
                />
                :
                null}
        </div>
    );
};

export default ModerationTable;

import { Divider } from 'antd';
import {
    analytics,
    events,
    leads,
    campaigns,
    moderation,
    pro,
    constructor,
    dashboard,
    profile,
    billing,
    logout,
    settings

} from '../../../pages/routes';
import './styles.scss'
import { SettingsIds, SettingsNames } from "@pages/PartnerRolePages/SettingsPage/constants.ts";
import { HELP_CENTER_LINK } from "@shared/constants.ts";

export const menuItemsPartner = {
    //     dashboard: {
    //     path: dashboard,
    //     title: 'Dashboard'
    // },
    events: {
        path: events,
        title: 'My events'
    },
    campaigns: {
        path: campaigns,
        title: 'Campaigns'
    },
    leads: {
        path: leads,
        title: 'Leads'
    },
    analytics: {
        path: analytics,
        title: 'Analytics'
    },
    pro: {
        path: pro,
        title: (
            <div>
                <span>Boost </span>
                <span className='sidebar-menu-drawer__boost-pro'>PRO</span>
            </div>
        )
    }
}

export const userMenuItemsPartner = {
    profile: {
        path: settings,
        title: 'Profile',
        children: [
            {
                path: `${settings}/${SettingsIds.profile}`,
                title: 'User settings',
            },
            {
                path: `${settings}/${SettingsIds.usersRoles}`,
                title: SettingsNames.usersRoles,
            }
        ]
    },
    billing: {
        path: billing,
        title: 'Billing',
        children: []
    },
    help: {
        path: '/help',
        title: 'Help center',
        children: [
            {
                path: HELP_CENTER_LINK,
                title: 'Help center',
            },
            {
                path: `/video-tour`,
                title: 'Video tour',
            }
        ]
    }
}

export const userMenuItemsEvent = {
    profile: {
        path: settings,
        title: 'Profile',
        children: [
            {
                path: `${settings}/${SettingsIds.adAccounts}`,
                title: SettingsNames.adAccounts,
            },
            {
                path: `${settings}/${SettingsIds.profile}`,
                title: 'User settings',
            },
            {
                path: `${settings}/${SettingsIds.usersRoles}`,
                title: SettingsNames.usersRoles,
            }
        ]
    },
    billing: {
        path: billing,
        title: 'Billing',
        children: []
    },
    help: {
        path: '/help',
        title: 'Help center',
        children: []
    }
}

export const menuItemsEvent = {
    // dashboard: {
    //     path: dashboard,
    //     title: 'Dashboard'
    // },
    events: {
        path: events,
        title: 'My events'
    },
    // audiences: {
    //     path: audiences,
    //     title: 'Audiences'
    // },
    moderation: {
        path: moderation,
        title: 'Moderation'
    },
    // partners: {
    //     path: partners,
    //     title: 'Sponsors'
    // },
    analytics: {
        path: analytics,
        title: 'Analytics'
    },
}
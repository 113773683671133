import { message } from 'antd';
import { createContext, useState, ReactNode, useContext } from 'react';
import { campaignInitialAds, campaignInitialForm } from '@pages/PartnerRolePages/Campaigns/CampaignEditorPage/constants';
import { CampaignEditorBody, CampaignEditorResponse } from '@store/type-partner/campaigns/models';
import { ExitPageContext } from "@contexts/ExitPageContext.tsx";

interface CampaignEditorContextType {
    successNotification: ({ text }: { text: string }) => void
    errorNotification: ({ text }: { text: string }) => void

    formErrors: Record<string, string>;
    setFormErrors: (errors: Record<string, string>) => void;

    formValues: CampaignEditorBody;
    setFormValues: React.Dispatch<React.SetStateAction<CampaignEditorBody>>;
    onInitForm: (form: CampaignEditorBody) => void;
    handleFormChange: (form: CampaignEditorBody) => void;
    isLoading: boolean;
    setLoading: (isLoading: boolean) => void;
    isLogoLoading: boolean;
    setLogoLoading: (isLoading: boolean) => void;
    isImagesLoading: boolean;
    setImagesLoading: (isLoading: boolean) => void;
    isSaving: boolean;
    setSaving: (isLoading: boolean) => void;

    updateInitialFormHandler: (response: CampaignEditorResponse) => void;
    onTitlesChange: ({ idx, value }: { idx: number, value: string }) => void;
    onAddTitle: () => void;
    onDeleteTitle: (idx: number) => void
    onTitlesShortChange: ({ idx, value }: { idx: number, value: string }) => void;
    onAddTitleShort: () => void;
    onDeleteTitleShort: (idx: number) => void;
    updateImagesListHandler: (url: string) => void;
    onDeleteImage: (idx: number) => void;
    updateLogosListHandler: (url: string) => void;
    onDeleteLogo: (idx: number) => void;
    onAssetSingleItemChange: (
        { key, value }
            :
            { key: 'business_name' | 'description', value: string }
    ) => void

    campaignStatus: { id: number, name: string };
    setCampaignStatus: (data: { id: number, name: string }) => void
}

export const CampaignEditorContext = createContext<CampaignEditorContextType>({
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    successNotification: () => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    errorNotification: () => { },
    formErrors: {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setFormErrors: () => { },
    formValues: campaignInitialForm,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setFormValues: () => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onInitForm: () => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    handleFormChange: () => { },
    isLoading: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setLoading: () => { },
    isLogoLoading: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setLogoLoading: () => { },
    isImagesLoading: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setImagesLoading: () => { },

    isSaving: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setSaving: () => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    updateInitialFormHandler: () => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onTitlesChange: () => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onAddTitle: () => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onDeleteTitle: () => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onTitlesShortChange: () => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onAddTitleShort: () => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onDeleteTitleShort: () => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    updateImagesListHandler: () => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onDeleteImage: () => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    updateLogosListHandler: () => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onDeleteLogo: () => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onAssetSingleItemChange: () => { },

    campaignStatus: {
        id: 0,
        name: ''
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setCampaignStatus: () => { }
});

interface CampaignEditorProviderProps {
    children: ReactNode;
}

export const CampaignEditorProvider: React.FC<CampaignEditorProviderProps> = ({ children }) => {

    const [messageApi, contextHolder] = message.useMessage();
    const [formValues, setFormValues] = useState<CampaignEditorBody>(campaignInitialForm)
    const [formErrors, setFormErrors] = useState({})
    // LOADERS
    const [isLoading, setLoading] = useState(false)
    const [isLogoLoading, setLogoLoading] = useState(false)
    const [isImagesLoading, setImagesLoading] = useState(false)
    const [isSaving, setSaving] = useState(false)

    // FORM DESTRUCTURE
    const titles = formValues.ads[0]?.assets?.title || []
    const shortTitles = formValues.ads[0]?.assets?.short_title || []
    // const imagesList = formValues.ads[0]?.assets?.image_original || []
    // const logosList = formValues.ads[0]?.assets?.logo_original || []
    // const businessNames = formValues.ads[0]?.assets?.business_name || []

    const {
        isExitPageEnabled,
        setIsExitPageEnabled,
    } = useContext(ExitPageContext);

    const [campaignStatus, setCampaignStatus] = useState({
        id: 0,
        name: ''
    })
    // NOTIFICATION
    const successNotification = ({ text }: { text: string }) => {
        void messageApi.open({
            type: 'success',
            content: text,
        });
    };

    const errorNotification = ({ text }: { text: string }) => {
        void messageApi.open({
            type: 'error',
            content: text,
        });
    };
    //****/

    const onInitForm = (fields: CampaignEditorBody) => {
        setFormErrors({})
        setFormValues({ ...formValues, ...fields });
    }

    const handleFormChange = (fields: CampaignEditorBody, isInit?: boolean) => {
        console.log('handleFormChange', fields);
        if (!isExitPageEnabled && !isInit) {
            setIsExitPageEnabled(true)
        }
        setFormErrors({})
        setFormValues({ ...formValues, ...fields });
    };


    const updateInitialFormHandler = (response: CampaignEditorResponse) => {

        const { data } = response;
        const { channels, ads, audiences } = data;
        setCampaignStatus({ id: +data.status.id, name: data.status.name })
        const {
            assets = {
                title: "",
                short_title: "",
                description: "",
                image_original: "",
                logo_original: "",
                business_name: ""
            }
        } = ads.length ? ads[0] : {};

        const {
            title: adTitle,
            short_title: adShortTitle,
            description: adDescription,
            image_original: adImageOriginal,
            logo_original: adLogoOriginal,
            business_name: adBusinessName,
        } = assets;


        const updatedChannels = channels.map(ch => ({
            id: ch?.id || null,
            type: +ch?.type || +ch?.type.id || null,
            bid: +ch.bid,
            title: ch.title,
            budget: +ch.budget || 0,
            status: ch.status?.id || 2
        }));

        const title = adTitle || campaignInitialAds[0].assets.title;
        const short_title = adShortTitle || campaignInitialAds[0].assets.short_title;
        const description = adDescription || campaignInitialAds[0].assets.description;
        const image_original = adImageOriginal || campaignInitialAds[0].assets.image_original;
        const logo_original = adLogoOriginal || campaignInitialAds[0].assets.logo_original;
        const business_name = adBusinessName || campaignInitialAds[0].assets.business_name;

        handleFormChange({
            ...campaignInitialForm,
            ...data,
            title: data?.title || 'Default Campaign Title',
            audiences: audiences ? audiences.map(item => +item.id) : [],
            goal: +data.goal.id,
            channels: updatedChannels,
            ads: [
                {
                    landing: ads[0]?.landing?.id ? ads[0].landing.id : '',
                    id: ads[0]?.id || '',
                    assets: {
                        title,
                        short_title,
                        description,
                        image_original,
                        logo_original,
                        business_name
                    }
                }
            ]
        }, true);
    };

    // FORM HANDLERS
    const onTitlesChange = ({ idx, value }: { idx: number, value: string }) => {
        const titles = formValues.ads[0].assets.title;

        const actualItem = { ...titles[idx], value }; // Create a new object with the updated value
        const updatedTitles = [...titles.slice(0, idx), actualItem, ...titles.slice(idx + 1)]; // Update the array of titles

        const next = {
            ...formValues,
            ads: [
                {
                    ...formValues.ads[0],
                    assets: {
                        ...formValues.ads[0].assets,
                        title: updatedTitles
                    }
                }
            ]
        };
        handleFormChange(next);
    };


    const onAddTitle = () => {
        const titles = [...formValues.ads[0].assets.title]; // Create a new array to avoid mutating the original
        titles.push({ id: null, value: '' }); // Push a new empty AdAsset object
        const next = {
            ...formValues,
            ads: [
                {
                    ...formValues.ads[0],
                    assets: {
                        ...formValues.ads[0].assets,
                        title: titles
                    }
                }
            ]
        };

        handleFormChange(next);
    };

    const onDeleteTitle = (idx: number) => {
        const titlesList = titles.filter((_, index) => index !== idx);
        const next = {
            ...formValues,
            ads: [
                {
                    ...formValues.ads[0],
                    assets: {
                        ...formValues.ads[0].assets,
                        title: titlesList
                    }
                }
            ]
        };
        handleFormChange(next);
    }


    const onTitlesShortChange = ({ idx, value }: { idx: number, value: string }) => {
        const short_titles = formValues.ads[0].assets.short_title;

        const actualItem = { ...short_titles[idx], value }; // Create a new object with the updated value
        const updatedTitles = [...short_titles.slice(0, idx), actualItem, ...short_titles.slice(idx + 1)]; // Update the array of short_titles

        const next = {
            ...formValues,
            ads: [
                {
                    ...formValues.ads[0],
                    assets: {
                        ...formValues.ads[0].assets,
                        short_title: updatedTitles
                    }
                }
            ]
        };
        handleFormChange(next);

    }

    const onAddTitleShort = () => {
        const short_title = [...formValues.ads[0].assets.short_title]; // Create a new array to avoid mutating the original
        short_title.push({ value: '' }); // Push a new empty AdAsset object
        const next = {
            ...formValues,
            ads: [
                {
                    ...formValues.ads[0],
                    assets: {
                        ...formValues.ads[0].assets,
                        short_title
                    }
                }
            ]
        };

        handleFormChange(next);

    }
    const onDeleteTitleShort = (idx: number) => {
        const titlesList = shortTitles.filter((_, index) => index !== idx);
        const next = {
            ...formValues,
            ads: [
                {
                    ...formValues.ads[0],
                    assets: {
                        ...formValues.ads[0].assets,
                        short_title: titlesList
                    }
                }
            ]
        };
        handleFormChange(next);
    }

    const updateImagesListHandler = (url: string) => {
        const imagesList = formValues.ads[0].assets.image_original;
        const nextImages = [...imagesList, { id: null, value: url }]
        const next = {
            ...formValues,
            ads: [
                {
                    ...formValues.ads[0],
                    assets: {
                        ...formValues.ads[0].assets,
                        image_original: nextImages
                    }
                }
            ]
        };
        handleFormChange(next);
    }

    const onDeleteImage = (idx: number) => {
        const imagesList = formValues.ads[0].assets.image_original;
        const imagesNext = imagesList.filter((_, index) => index !== idx);
        const next = {
            ...formValues,
            ads: [
                {
                    ...formValues.ads[0],
                    assets: {
                        ...formValues.ads[0].assets,
                        image_original: imagesNext
                    }
                }
            ]
        };
        handleFormChange(next);
    }
    const updateLogosListHandler = (url: string) => {
        const logosList = formValues.ads[0].assets.logo_original;
        const nextLogos = [...logosList, { id: null, value: url }]
        const next = {
            ...formValues,
            ads: [
                {
                    ...formValues.ads[0],
                    assets: {
                        ...formValues.ads[0].assets,
                        logo_original: nextLogos
                    }
                }
            ]
        };
        handleFormChange(next);
    }

    const onDeleteLogo = (idx: number) => {
        const logosList = formValues.ads[0].assets.logo_original;
        const logosNext = logosList.filter((_, index) => index !== idx);
        const next = {
            ...formValues,
            ads: [
                {
                    ...formValues.ads[0],
                    assets: {
                        ...formValues.ads[0].assets,
                        logo_original: logosNext
                    }
                }
            ]
        };
        handleFormChange(next);
    }

    const onAssetSingleItemChange = (
        { key, value }
            :
            { key: 'business_name' | 'description', value: string }
    ) => {
        const next = {
            ...formValues,
            ads: [
                {
                    ...formValues.ads[0],
                    assets: {
                        ...formValues.ads[0].assets,
                        [key]: [
                            {
                                ...formValues.ads[0].assets[key][0],
                                value: value
                            }
                        ]
                    }
                },
                ...formValues.ads.slice(1) // Keep other ads unchanged
            ]
        };
        handleFormChange(next);
    };


    return (
        <CampaignEditorContext.Provider value={
            {
                successNotification,
                errorNotification,
                formErrors,
                setFormErrors,

                onInitForm,
                handleFormChange,
                formValues,
                setFormValues,
                // LOADERS
                isLoading,
                setLoading,
                isSaving,
                setSaving,
                isLogoLoading,
                setLogoLoading,
                isImagesLoading,
                setImagesLoading,

                updateInitialFormHandler,
                // FORM
                onTitlesChange,
                onAddTitle,
                onDeleteTitle,
                onTitlesShortChange,
                onAddTitleShort,
                onDeleteTitleShort,
                updateImagesListHandler,
                onDeleteImage,
                updateLogosListHandler,
                onDeleteLogo,
                onAssetSingleItemChange,

                campaignStatus,
                setCampaignStatus
            }}>
            <>
                {contextHolder}
                {children}
            </>
        </CampaignEditorContext.Provider>
    );
};

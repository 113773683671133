import { message, notification, Spin, Tooltip, Typography, Segmented, Button } from 'antd'
import ZeroState from './components/ZeroState'
import InfoIcon from "@icons/header/InfoIcon.tsx";
import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
    useGetOrganizerSetupLandingsQuery,
    usePatchOrganizerSetupLandingStatusByIdMutation
} from "@store/type-event/landings/landings.api.ts";
import StatusDescription from "@components/StatusDescription";
import { ModerationStatusesFormatted } from "@shared/constants.ts";
import { ModerationStatusAction, OrganizerLanding, SetupLanding, SetupLandingAction } from "@store/type-event/models.ts";
import sendAnalytics from "@hooks/sendAnalytics/sendAnalytics.tsx";
import { GTMEventName } from "@hooks/sendAnalytics/constants.ts";
import './styles.scss'
import {
    segmentedOptions, SegmentedValues, SetupLandingStatuses,
} from "@pages/EventRolePages/EventPage/setup/landing-page-templates/constants.tsx";
import FullscreenIcon from "@assets/event-setup/FullscreenIcon.tsx";
import {
    ModalDataType,
    PatchSetupLandingStatusType
} from "@pages/EventRolePages/EventPage/setup/landing-page-templates/types.ts";
import { useAuth } from "@contexts/AuthContext.tsx";
import { isHubSpotScriptLoaded, toggleHubspotChat } from "@components/HubSpotButton/helpers.ts";
import RejectionModal from "@pages/EventRolePages/EventPage/setup/landing-page-templates/components/RejectionModal.tsx";
import ApproveModal from "@pages/EventRolePages/EventPage/setup/landing-page-templates/components/ApproveModal.tsx";
import { CheckOutlined } from "@ant-design/icons";
import RejectIcon from "@assets/RejectIcon.tsx";
import PreviewModal from "@pages/EventRolePages/EventPage/setup/landing-page-templates/components/PreviewModal.tsx";

const LandingPageTemplates = () => {
    const { eventId } = useParams()

    const { data: landings, isLoading: isLandingsLoading, refetch: refetchLandings } = useGetOrganizerSetupLandingsQuery({ eventId: eventId || '' })
    const [patchLandingStatus] = usePatchOrganizerSetupLandingStatusByIdMutation()

    const { isHubspotChatOpen, setIsHubspotChatOpen } = useAuth()

    const iframeRef = useRef<HTMLIFrameElement | null>(null);

    const [approveModalData, setApproveModalData] = useState<ModalDataType | null>(null);
    const [rejectionModalData, setRejectionModalData] = useState<ModalDataType | null>(null);
    const [isLoadingStatus, setIsLoadingStatus] = useState(false)
    const [segmentedValue, setSegmentedValue] = useState(SegmentedValues.laptop)
    const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false)

    const landing = landings?.data?.[0] as unknown as SetupLanding

    useEffect(() => {
        if (iframeRef.current) {
            const iframeDocument = iframeRef.current.contentDocument;
            if (iframeDocument) {
                iframeDocument.open();
                iframeDocument.write(landing.preview || '');
                iframeDocument.close();
            }
        }
    }, [landing]);

    const handleChangeStatus = ({ row, status }: { row: OrganizerLanding | SetupLanding, status: ModerationStatusAction | SetupLandingAction }) => {
        if (status.slug === SetupLandingStatuses.enabled) {
            setApproveModalData({ row: row as SetupLanding, status })
        }
        if (status.slug === SetupLandingStatuses.rejected) {
            setRejectionModalData({ row: row as SetupLanding, status })
        }
    }

    const handleCloseRejectionModal = () => {
        setRejectionModalData(null)
    }

    const handleCloseApproveModal = () => {
        setApproveModalData(null)
    }

    const handlePatchLandingStatus = async ({
        landing: landingId,
        status,
        comment,
        statusSlug,
    }: PatchSetupLandingStatusType) => {
        try {
            setIsLoadingStatus(true)
            const response = await patchLandingStatus({
                landing: landingId, status, comment, eventId: eventId || ''
            });
            if ('error' in response) {
                setIsLoadingStatus(false)
                void message.open({
                    type: 'error',
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                    content: response?.error?.data?.message || 'OOOPS, something is wrong',
                });
            } else {
                sendAnalytics(`${GTMEventName.landingStatus}_${statusSlug}`)
                await refetchLandings()
                setIsLoadingStatus(false)
                notification.open({
                    message: (
                        <span className='moderation-table__notification__message'>
                            <span>
                                Landing page status has been changed to
                            </span>
                            <StatusDescription status={statusSlug} formattedStatusData={ModerationStatusesFormatted} />
                        </span>
                    ),
                    className: 'moderation-table__notification',
                    placement: 'bottomLeft',
                    closeIcon: false
                });
            }
        } catch (error) {
            setIsLoadingStatus(false)
            return void message.open({
                type: 'error',
                content: 'OOOPS, something is wrong',
            });
        }
    }

    const handleClosePreview = () => {
        setIsPreviewModalOpen(false)
    }

    const onRequestChangeClick = () => {
        if (isHubSpotScriptLoaded()) {
            const isOpenClass = document.querySelector('.hs-messages-widget-open')
            const isOpen = Boolean(isOpenClass || isHubspotChatOpen)
            toggleHubspotChat(isOpen)
            setIsHubspotChatOpen(!isOpen);
        } else {
            console.error('HubSpot Conversations not loaded');
        }
    }

    return (
        <div className='event-setup__landing-page-template'>
            <div className='ad-accounts-page__header-title'>
                <Typography.Title level={4}>
                    Landing Page Template
                </Typography.Title>
                <Tooltip
                    overlayClassName='tooltip-general billing-tab-tooltip'
                    title={(
                        <div>
                            Landing Page Template
                        </div>
                    )}
                    placement="bottom"
                    overlayStyle={{
                        width: '230px'
                    }}
                >
                    <div
                        className='cursor-pointer'
                        style={{ marginTop: '.5em' }}
                    >
                        <InfoIcon fillColor="#252628" width={18} height={18} />
                    </div>
                </Tooltip>

            </div>
            {isLandingsLoading && (
                <Spin size="small" />
            )}
            {!isLandingsLoading && !landing && (
                <ZeroState />
            )}
            {!isLandingsLoading && landing && (
                <div>
                    <div className="event-setup__landing-page-template__header">
                        <StatusDescription
                            status={landing?.status?.name}
                        />
                        <Button
                            className="ml-auto"
                            onClick={onRequestChangeClick}
                        >
                            Request a change
                        </Button>
                        {landing?.status?.actions?.map((cta) => {
                            return (
                                <Button
                                    className={cta.slug === SetupLandingStatuses.rejected ? 'reject-btn' : 'success-btn'}
                                    icon={cta.slug === SetupLandingStatuses.rejected ? <RejectIcon /> : <CheckOutlined />}
                                    danger={cta.slug === SetupLandingStatuses.rejected}
                                    loading={isLoadingStatus}
                                    key={cta.id}
                                    onClick={() => handleChangeStatus({
                                        row: landing,
                                        status: cta
                                    })}
                                >
                                    {cta.transit}
                                </Button>
                            )
                        })}
                    </div>
                    <div className="event-setup__landing-page-template__content">
                        {Boolean(landing?.preview) && (
                            <>
                                <div className="preview-modal__link">
                                    <iframe
                                        ref={iframeRef}
                                        title={`moderation-table-preview_${landing.id}`}
                                        className={`preview-modal__iframe preview-modal__iframe__${segmentedValue}`}
                                    />
                                </div>
                                <div className="event-setup__landing-page-template__segmented">
                                    <Segmented
                                        className="event-setup__segmented"
                                        options={segmentedOptions}
                                        value={segmentedValue}
                                        defaultValue={SegmentedValues.laptop}
                                        onChange={setSegmentedValue}
                                    />
                                </div>
                                <div
                                    className="event-setup__landing-page-template__preview-btn"
                                    onClick={() => {
                                        setIsPreviewModalOpen(true)
                                    }}
                                >
                                    <FullscreenIcon />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            )}
            <PreviewModal
                isOpen={isPreviewModalOpen && Boolean(landing)}
                isDrawerOpen={false}
                drawerData={landing}
                onClose={handleClosePreview}
                handleChangeStatus={handleChangeStatus}
                isLoading={isLoadingStatus}
            />
            {rejectionModalData
                ? (
                    <RejectionModal
                        isOpen={Boolean(rejectionModalData)}
                        data={rejectionModalData}
                        onClose={handleCloseRejectionModal}
                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                        handlePatchLandingStatus={handlePatchLandingStatus}
                    />
                )
                :
                null}
            {approveModalData
                ? (
                    <ApproveModal
                        isOpen={Boolean(approveModalData)}
                        data={approveModalData}
                        onClose={handleCloseApproveModal}
                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                        handlePatchLandingStatus={handlePatchLandingStatus}
                    />
                )
                :
                null}
        </div>
    )
}

export default LandingPageTemplates

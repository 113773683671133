import React, { useMemo, useEffect, useContext, useState, useCallback } from 'react';
import './styles.scss'
import EventsPageLayout from '@layouts/EventsPageLayout';
import { useParams, useLocation } from 'react-router-dom';
import HeaderBreadcrumb from '@components/Header/HeaderBreadcrumb';
import { useGetEventByIdFromCatalogQuery } from '@store/type-partner/events-catalog/partner-event-catalog.api';
import NonEditableSection
    from "@pages/PartnerRolePages/LandingPageConstructor/components/NonEditableSection /NonEditableSection";
import EmptySectionsBlock
    from "@pages/PartnerRolePages/LandingPageConstructor/components/EmptySectionsBlock/EmptySectionsBlock";
import { getLandingConstructorCrumbs } from "@pages/PartnerRolePages/LandingPageConstructor/helpers";
import { JoditOptionName } from './components/LandingPageWYSIWYGconstructor/assets/models';
import { LandingEditorContext } from '@contexts/LandingEditorContext';
import SectionSettingsDrawer from './components/drawers/settings/SectionSettingsDrawer';
import AddSectionModal from './components/modals/AddSectionModal';
import { OrganizerLanding } from "@store/type-event/models";
import StatusDescription from "@components/StatusDescription";
import { ModerationStatusesFormatted } from "@shared/constants";
import InfoIcon from "@icons/header/InfoIcon";
import { Button, Typography } from "antd";
import RejectionReasonModal
    from "@pages/PartnerRolePages/MyEvents/pages/MyEventEditPage/components/EventLandingPages/components/RejectionReasonModal";
import LoaderAi from './components/loaders/LoaderAi';
import { LandingAiGenerationSteps } from './models';
import { footerHtmlDemo, headerHtmlDemo } from "@pages/PartnerRolePages/LandingPageConstructor/mocksDemo";
import { useLazyGetUserQuery } from '@store/main/-user/user.api';
import { useGetPartnerEventByIdQuery } from "@store/type-partner/events/partner-event.api";
import {
    useGetPartnerLandingByIdQuery
} from '@store/type-partner/landings/landings.api';
import usePageAnalytics from '@hooks/sendAnalytics/usePageAnalytics';
import PublishedModal from './components/modals/PublishedModal';
import SectionComponent
    from "@pages/PartnerRolePages/LandingPageConstructor/components/SectionComponent/SectionComponent.tsx";
import { HERO } from "@pages/PartnerRolePages/LandingPageConstructor/constants.ts";
import PopoverButton from "@pages/PartnerRolePages/LandingPageConstructor/components/PopoverButton/PopoverButton.tsx";
import { generateUniqueStringId } from "@shared/utils.ts";
import { PartnerLandingSectionLayout } from "@store/type-partner/landings/models.ts";

const { Paragraph } = Typography;

const LandingPageConstructor = () => {
    usePageAnalytics('lp_editor');

    const {
        clickedSectionId,
        setClickedSectionId,
        editorSections,
        setEditorSections,
        layoutSettingsData,
        onDeleteSectionFromList,
        setLandingErrors,
        landingErrors,
        landingTitle,
        setLandingTitle,
        landingUrl,
        setLandingUrl,
        updateLandingHandler,
        createLandingHandler,
        isLoading,
        // isLandingLoading,
        isPublishedModalOpen,
        onClosePublishedModal,
        onPublishClick,
        isPublishLoading,
        isAiLoading,
        setAiLoading,
        onPreviewClick,
        onSaveSectionSettingsClick
    } = useContext(LandingEditorContext);
    const { eventId = '', landingId = '', type = '' } = useParams()

    const [isRejectionModalOpen, setIsRejectionModalOpen] = useState(false)
    const [loadedHeader, setLoadedHeader] = useState('')
    const [loadedFooter, setLoadedFooter] = useState('')
    const [isLandingDataLoading, setIsLandingDataLoading] = useState(Boolean(landingId))

    const { data: eventData } = useGetEventByIdFromCatalogQuery(
        { id: eventId?.toString() || '' },
        { skip: !eventId }
    );
    const { data: eventsRes, isLoading: isEventsResLoading } = useGetPartnerEventByIdQuery({ id: eventId || '' }, { skip: !eventId })
    const { data: fetchedLandingData, isLoading: isLandingLoading, isFetching: isLandingsFetching, refetch: refetchLandingData } = useGetPartnerLandingByIdQuery({ landingId, eventId }, { skip: !landingId })

    const [dumbGetUser] = useLazyGetUserQuery()

    const { pathname } = useLocation()

    const breadCrumbs = useMemo(() => getLandingConstructorCrumbs(pathname), [pathname, eventData])

    // WYSIWYG editor options
    const onOptionClick = useCallback(({ option, id, selectedLayout }: { option: JoditOptionName, id: string, selectedLayout?: PartnerLandingSectionLayout }) => {
        switch (option) {
        case JoditOptionName.Delete:
            onDeleteSectionFromList(id)
            break;
        case JoditOptionName.Clone:
            // eslint-disable-next-line no-case-declarations
            const copyItem = editorSections.find(item => item.id === id);
            if (copyItem) {
                const copyIndex = editorSections.findIndex(item => item.id === id);
                const newList = [...editorSections.slice(0, copyIndex), { ...copyItem, id: generateUniqueStringId() }, ...editorSections.slice(copyIndex)]
                setEditorSections(newList)
            }
            break;
        case JoditOptionName.Select: {
            if (selectedLayout) {
                const selectedIndex = editorSections.findIndex(item => item.id === id);
                const newList = editorSections.map((section, index) => {
                    return index === selectedIndex ? { layout: selectedLayout, id: generateUniqueStringId() } : section
                })
                setEditorSections(newList)
            }
            break;
        }
        case JoditOptionName.Up:
            // eslint-disable-next-line no-case-declarations
            const indexUp = editorSections.findIndex(item => item.id === id);
            if (indexUp > 0) {
                const newList = [...editorSections];
                const temp = newList[indexUp - 1];
                newList[indexUp - 1] = newList[indexUp];
                newList[indexUp] = temp;
                setEditorSections(newList);
            }
            break;
        case JoditOptionName.Down:
            // eslint-disable-next-line no-case-declarations
            const indexDown = editorSections.findIndex(item => item.id === id);
            if (indexDown < editorSections.length - 1) {
                const newList = [...editorSections];
                const temp = newList[indexDown + 1];
                newList[indexDown + 1] = newList[indexDown];
                newList[indexDown] = temp;
                setEditorSections(newList);
            }
            break;

        default:
            break;
        }
    }, [editorSections, onDeleteSectionFromList, setEditorSections])

    const [aiLoaderStep, setAiLoaderStep] = useState<LandingAiGenerationSteps>('title')
    useEffect(() => {
        const handleEsc = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                setClickedSectionId('')
            }
        };
        document.addEventListener('keydown', handleEsc);
        return () => {
            document.removeEventListener('keydown', handleEsc);
        };
    }, []);

    useEffect(() => {
        if (fetchedLandingData?.data) {
            setIsLandingDataLoading(true)
            const { data } = fetchedLandingData
            const content = fetchedLandingData.data.content || []
            const sections = content.map((item, idx) => ({ id: item.name + idx.toString(), layout: item }))
            setEditorSections(sections)
            setLandingTitle(data.title)
            setLandingUrl(data.url.path)
            setTimeout(() => {
                setIsLandingDataLoading(false)
            }, 700)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchedLandingData])

    //    cb hell for testing
    useEffect(() => {
        if (type === 'ai') {
            const timer = 500
            const steps: LandingAiGenerationSteps[] = ['title', 'description', 'images', 'finish'];
            setAiLoading(true);
            void dumbGetUser().then(() => {
                setAiLoaderStep(steps[0])
                setTimeout(() => {
                    void dumbGetUser().then(() => {
                        setAiLoaderStep(steps[1])
                        setTimeout(() => {
                            void dumbGetUser().then(() => {
                                setAiLoaderStep(steps[3])
                                setTimeout(() => {
                                    setLoadedHeader(headerHtmlDemo)
                                    // setEditorSections(mockedAiSections)
                                    setLoadedFooter(footerHtmlDemo)
                                    setAiLoading(false);
                                }, timer);
                            });
                        }, timer);
                    });
                }, timer);
            })
        } else {
            // setLoadedHeader(headerHtml)
            // setLoadedFooter(footerHtml)
            setAiLoading(false);
            setAiLoaderStep('title');
        }
    }, [type]);

    const onInfoIconClick = () => {
        setIsRejectionModalOpen(true)
    }

    const onCloseRejectionModal = () => {
        setIsRejectionModalOpen(false)
    }

    const onSaveClick = () => {
        landingId ? updateLandingHandler() : createLandingHandler()
    }

    const onSaveSectionAndUrlClick = () => {
        createLandingHandler()
        onSaveSectionSettingsClick()
    }

    const renderedEditorSections = useMemo(
        () => {
            const isFirstHero = editorSections?.[0]?.layout?.name?.includes(HERO)
            return editorSections.map((section, idx) => {
                const isActive = section.id === clickedSectionId

                return (
                    <SectionComponent
                        isActive={isActive}
                        isUpBtnDisabled={idx === 1 && isFirstHero}
                        key={section.id}
                        section={section}
                        idx={idx}
                        onOptionClick={onOptionClick}
                        isLoading={isLandingDataLoading}
                    />
                )
            })
        },
        [editorSections, clickedSectionId, onOptionClick, isLandingDataLoading]
    );

    return (
        <EventsPageLayout className='landing-editor-page' isProHidden>
            <HeaderBreadcrumb
                className='landing-editor-page__breadcrumbs'
                crumbs={breadCrumbs}
                rightSide={(
                    <div className='flex mr-auto w-full'>
                        <div className='vertical-divider' />
                        <div>
                            <div className='landing-constructor__project-title'>
                                <Paragraph editable={{
                                    onChange: (value) => {
                                        if (value.trim()) {
                                            setLandingTitle(value)
                                        }
                                    },
                                    onStart: () => setLandingErrors({}),
                                }}
                                >
                                    {landingTitle}
                                </Paragraph>
                            </div>
                            {landingErrors?.title && (
                                <div className='text-regular-14 label ml-6 error'>{landingErrors.title}</div>
                            )}
                        </div>
                        {/*<div className='vertical-divider mr-16 ml-16' />*/}
                        {/* <Button disabled className="landing-editor-page__do-button" type="text" icon={<UndoIcon />} /> */}
                        {/* <Button className="landing-editor-page__do-button mr-auto" type="text" icon={<RedoIcon />} /> */}
                        <aside
                            className="flex-center ml-auto"
                        >
                            {landingId && (
                                <>
                                    <div className='my-events-landing-pages-card__status whitespace-nowrap mx-8'>
                                        <StatusDescription
                                            status={(fetchedLandingData?.data as unknown as OrganizerLanding)?.status?.name}
                                            formattedStatusData={ModerationStatusesFormatted}
                                            size="small"
                                        />
                                        {(fetchedLandingData?.data as unknown as OrganizerLanding)?.status?.comment && (
                                            <div onClick={onInfoIconClick}>
                                                <InfoIcon fillColor="#252628" width={16} height={16}/>
                                            </div>
                                        )}
                                    </div>
                                    <div className='vertical-divider mr-6 ml-12'/>
                                </>
                            )}
                            {landingId && (fetchedLandingData?.data as unknown as OrganizerLanding)?.status?.actions.length ? (
                                <Button
                                    loading={isLoading}
                                    disabled={!editorSections.length}
                                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                                    onClick={onSaveClick}
                                >
                                    Save
                                </Button>
                            ) : (
                                <PopoverButton
                                    isSave
                                    disabled={!editorSections.length}
                                    isUrlLoading={isLandingLoading || isEventsResLoading}
                                    subdomain={eventsRes?.data?.subdomain}
                                    landingUrl={landingUrl}
                                    isLoading={isLandingLoading || isLandingsFetching || isEventsResLoading || isLandingDataLoading}
                                    isDropdownButtonLoading={isPublishLoading || isLandingLoading}
                                    landingErrors={landingErrors}
                                    setLandingErrors={setLandingErrors}
                                    setLandingUrl={setLandingUrl}
                                    onSaveClick={onSaveClick}
                                    onSaveSectionAndUrlClick={onSaveSectionAndUrlClick}
                                    onPublishClick={onPublishClick}
                                    transit="Save"
                                />
                            )}
                            {landingId ? (
                                <Button
                                    className="mr-6 ml-0"
                                    shape="round"
                                    onClick={onPreviewClick}
                                >
                                    Preview
                                </Button>
                            )
                                :
                                null}
                            {landingId ? (
                                fetchedLandingData?.data && (fetchedLandingData?.data as unknown as OrganizerLanding)?.status?.actions?.map(({
                                    id,
                                    transit
                                }) => {
                                    return (
                                        <PopoverButton
                                            key={id}
                                            id={id}
                                            isUrlLoading={isLandingLoading || isEventsResLoading}
                                            subdomain={eventsRes?.data?.subdomain}
                                            landingUrl={landingUrl}
                                            isLoading={isLandingLoading || isLandingsFetching || isEventsResLoading || isLandingDataLoading}
                                            isDropdownButtonLoading={isPublishLoading || isLandingLoading}
                                            landingErrors={landingErrors}
                                            setLandingErrors={setLandingErrors}
                                            setLandingUrl={setLandingUrl}
                                            onSaveClick={onSaveClick}
                                            onPublishClick={onPublishClick}
                                            transit={transit}
                                        />
                                    )
                                })
                            )
                                :
                                null}
                        </aside>
                    </div>
                )}
            />

            <div className={`landing-constructor__wrapper ${!isLandingLoading && !isEventsResLoading && !isLandingDataLoading && !editorSections.length ? 'landing-constructor__wrapper-empty' : ''}`}>
                <div className={`main-page landing-constructor__page ${!isLandingLoading && !isEventsResLoading && !isLandingDataLoading && !editorSections.length ? 'landing-constructor__page-empty' : ''}`}>
                    {/* TOFIX: get header from BE  */}
                    <NonEditableSection html={loadedHeader} />

                    {renderedEditorSections}

                    {!isLandingLoading && !isEventsResLoading && !isLandingDataLoading && !editorSections.length && (
                        <EmptySectionsBlock />
                    )}

                    <NonEditableSection html={loadedFooter} />
                </div>
            </div>

            {/* MODAL SECTION  */}
            <AddSectionModal />
            {layoutSettingsData && clickedSectionId
                ? (
                    <SectionSettingsDrawer
                        isOpen={!!layoutSettingsData}
                        layoutData={layoutSettingsData}
                    />
                )
                :
                null}

            <RejectionReasonModal
                isOpen={Boolean(isRejectionModalOpen)}
                onClose={onCloseRejectionModal}
                comment={(fetchedLandingData?.data as unknown as OrganizerLanding)?.status?.comment || ''}
                reason={(fetchedLandingData?.data as unknown as OrganizerLanding)?.status?.reason || ''}
            />
            <PublishedModal
                isOpen={Boolean(isPublishedModalOpen)}
                onClose={onClosePublishedModal}
            />
            <LoaderAi
                isOpen={isAiLoading}
                step={aiLoaderStep}
            />
        </EventsPageLayout>
    )
}

export default LandingPageConstructor

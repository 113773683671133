export const setTooltipCoordinatesPlacementTop = ({ current, id, isCampaign, isDesktop, isMobileCampaign }: { current: HTMLElement | null, id: string, isCampaign?: boolean, isDesktop?: boolean, isMobileCampaign?: boolean }) => {
    if (current) {
        const rect = (current as HTMLElement).getBoundingClientRect();
        const top = rect.top
        const element = document.querySelector(`#${id}`)
        const elementMask = document.querySelector(`#${id}__mask`)
        const elementRect = (element as HTMLElement)?.getBoundingClientRect() || {};
        const widthAmount = ((current as HTMLElement).offsetWidth - elementRect.width) / 2
        const offsetHeight = (current as HTMLElement).offsetHeight
        const offsetWidth = (current as HTMLElement).offsetWidth
        const finalTop = top - elementRect.height + 10
        const distanceFromBottom = window.innerHeight - finalTop - elementRect.height;
        const distanceFromBottomCampaign = window.innerHeight - top + 18;
        const sidebarWidth = isDesktop && !isCampaign ? 87 : isDesktop ? 104 : 12
        if (element) {
            if (isMobileCampaign) {
                (element as HTMLElement).style.bottom = `${distanceFromBottomCampaign || 0}px`;
                (element as HTMLElement).style.top = 'auto';
                (element as HTMLElement).style.left = `${sidebarWidth + widthAmount || 0}px`;
            } else if (isCampaign) {
                (element as HTMLElement).style.bottom = `${distanceFromBottomCampaign || 0}px`;
                (element as HTMLElement).style.top = 'auto';
                (element as HTMLElement).style.left = `${sidebarWidth + widthAmount || 0}px`;
            } else {
                (element as HTMLElement).style.right = `${(widthAmount) || 0}px`;
                (element as HTMLElement).style.top = 'auto';
                (element as HTMLElement).style.bottom = `${distanceFromBottom || 0}px`;
            }
        }
        if (elementMask) {
            if (isMobileCampaign) {
                (elementMask as HTMLElement).style.top = `${(top - 8) || 0}px`;
                (elementMask as HTMLElement).style.left = `${sidebarWidth}px`;
                (elementMask as HTMLElement).style.width = `${offsetWidth + 16}px`;
                (elementMask as HTMLElement).style.height = `auto`;
                (elementMask as HTMLElement).style.bottom = '72px';
                elementMask.classList.add('tour-component-wrapper__mask__hidden')
            } else if (isCampaign) {
                (elementMask as HTMLElement).style.top = `${(top - 8) || 0}px`;
                (elementMask as HTMLElement).style.left = `${sidebarWidth}px`;
                (elementMask as HTMLElement).style.width = `${offsetWidth + 16}px`;
                (elementMask as HTMLElement).style.height = `${(offsetHeight + 16) || 0}px`;
            } else {
                (elementMask as HTMLElement).style.left = `${sidebarWidth}px`;
                (elementMask as HTMLElement).style.top = `${(top + 22) || 0}px`;
                (elementMask as HTMLElement).style.height = `${(offsetHeight - 30) || 0}px`;
            }
        }
    }

    positionMaskElements(id)
}

export const setTooltipCoordinatesPlacementRight = ({ current, id, config, isTop }: { current: HTMLElement | null, id: string, config?: { right: number }, isTop?: boolean }) => {
    const { right = 0 } = config || {}
    if (current) {
        const rect = (current as HTMLElement).getBoundingClientRect();
        const top = rect.top
        const element = document.querySelector(`#${id}`)
        const elementRect = (element as HTMLElement)?.getBoundingClientRect() || {};
        const topAmount = ((current as HTMLElement).offsetHeight - elementRect.height) / 2
        const isTopAmount = top - ((current as HTMLElement).offsetHeight / 2)
        const distanceFromRight = window.innerWidth - rect.right;
        const widthElement = elementRect.width + 6 + 4
        if (element) {
            (element as HTMLElement).style.top = isTop ? `${isTopAmount}px` : `${top + topAmount || 0}px`;
            (element as HTMLElement).style.right = `${(distanceFromRight - widthElement + right) || 0}px`;
        }
    }
    positionMaskElements(id)

}

export const setTooltipCoordinatesPlacementBottom = ({ current, id, isCampaign, isDesktop }: { current: HTMLElement | null, id: string, isCampaign?: boolean, isDesktop?: boolean }) => {
    if (current) {
        const rect = (current as HTMLElement).getBoundingClientRect();
        const top = rect.top
        const element = document.querySelector(`#${id}`)
        const elementMask = document.querySelector(`#${id}__mask`)
        const elementRect = (element as HTMLElement)?.getBoundingClientRect() || {};
        const widthAmount = ((current as HTMLElement).offsetWidth - elementRect.width) / 2
        const offsetHeight = (current as HTMLElement).offsetHeight
        const offsetWidth = (current as HTMLElement).offsetWidth
        const distanceFromBottom = window.innerHeight - top - elementRect.height - rect.height - 10;
        const distanceFromBottomCampaign = window.innerHeight - top + 18;
        const sidebarWidth = isDesktop ? 104 : 24
        if (element) {
            if (isCampaign) {
                (element as HTMLElement).style.bottom = `${distanceFromBottomCampaign || 0}px`;
                (element as HTMLElement).style.top = 'auto';
                (element as HTMLElement).style.left = `${sidebarWidth + widthAmount || 0}px`;
            } else {
                (element as HTMLElement).style.top = 'auto';
                (element as HTMLElement).style.left = `${sidebarWidth + widthAmount || 0}px`;
                (element as HTMLElement).style.bottom = `${distanceFromBottom || 0}px`;
            }
        }
        if (elementMask) {
            if (isCampaign) {
                (elementMask as HTMLElement).style.top = `${(top - 8) || 0}px`;
                (elementMask as HTMLElement).style.left = `${sidebarWidth}px`;
                (elementMask as HTMLElement).style.width = `${offsetWidth + 16}px`;
                (elementMask as HTMLElement).style.height = `${(offsetHeight + 16) || 0}px`;
            } else {
                (elementMask as HTMLElement).style.top = `${(top + 22) || 0}px`;
                (elementMask as HTMLElement).style.height = `${(offsetHeight - 30) || 0}px`;
            }
        }
    }
    positionMaskElements(id)

}

export const setInfoTooltipCoordinates = (id: string, isMobile?: boolean) => {
    const element = document.querySelector(`#${id}`) as HTMLElement
    if (element) {
        if (isMobile) {
            element.style.top = '458px';
            element.style.right = `auto`;
            element.style.left = `20px`;
            element.style.bottom = `auto`;
        } else {
            element.style.top = '52px';
            element.style.right = `130px`;
            element.style.left = `auto`;
            element.style.bottom = `auto`;
        }
    }
}

export const setMenuTooltipCoordinates = (id: string, isMobile?: boolean) => {
    const element = document.querySelector(`#${id}`) as HTMLElement
    if (element) {
        if (isMobile) {
            element.style.top = '55px';
            element.style.right = `auto`;
            element.style.left = `12px`;
            element.style.bottom = `auto`;
        }
    }
}

export const scrollToElement = ({ current, id, propTop, isBottomHidden, prev }: { current: HTMLElement | null, id: string, propTop?: number, isBottomHidden?: boolean, prev?: HTMLElement | null }) => {
    if (current) {
        const elementMask = document.querySelector(`#${id}__mask`) as HTMLElement
        const offsetHeight = (current as HTMLElement).offsetHeight
        const scrollTop = window.scrollY || document.documentElement.scrollTop;
        const prevHeight = (prev as HTMLElement).offsetHeight + 16
        window.scrollTo({ top: scrollTop + prevHeight + (propTop || 0), behavior: 'smooth' })

        if (elementMask) {
            if (isBottomHidden) {
                elementMask.classList.add('tour-component-wrapper__mask__hidden')
                elementMask.style.height = 'auto';
                elementMask.style.bottom = '72px';
            } else {
                elementMask.style.height = `${(offsetHeight + 16 || 0)}px`;
            }
        }
    }
    positionMaskElements(id)
}

export const positionMaskElements = (id: string) => {
    const mask = document.getElementById(`${id}__mask`);
    if (!mask) return;

    const rect = mask.getBoundingClientRect();

    const topElement = mask.querySelector(`#${id}__mask__top`) as HTMLElement;

    if (topElement) {
        topElement.style.top = '0';
        topElement.style.left = '0';
        topElement.style.right = '0';
        topElement.style.height = `${rect.top}px`;
    }

    const rightElement = mask.querySelector(`#${id}__mask__right`) as HTMLElement;

    if (rightElement) {
        rightElement.style.top = '0';
        rightElement.style.right = '0';
        rightElement.style.bottom = '0';
        rightElement.style.width = `${window.innerWidth - rect.right}px`;
    }

    const bottomElement = mask.querySelector(`#${id}__mask__bottom`) as HTMLElement;
    if (bottomElement) {
        bottomElement.style.left = '0';
        bottomElement.style.right = '0';
        bottomElement.style.bottom = '0';
        bottomElement.style.height = `${window.innerHeight - rect.bottom}px`;
    }

    const leftElement = mask.querySelector(`#${id}__mask__left`) as HTMLElement;
    if (leftElement) {
        leftElement.style.top = '0';
        leftElement.style.left = '0';
        leftElement.style.bottom = '0';
        leftElement.style.width = `${rect.left}px`;
    }
}

export const scrollToElementCenter = (element: HTMLElement | null, minDistanceFromTop = 200) => {
    if (element) {
        const rect = element.getBoundingClientRect();
        window.scrollTo({
            top: rect.top - minDistanceFromTop + window.scrollY,
            behavior: 'smooth'
        });
    }
};

import { LandingAiGenerationSteps, LandingSectionCategory } from './models';

const baseUrl = import.meta.env.VITE_API_URL as string;

export const imageAction = `${baseUrl}/partner/landings/image`

export const OptionName: Record<string, string> = {
    BackgroundComponent: 'Background',
    BackgroundColorComponent: 'Background',
    BackgroundImageComponent: 'Background',
    ImageComponent: 'Image',
    ImagesComponent: 'Images',
    VideoComponent: 'Video',
    PdfComponent: 'Pdf',
    ButtonComponent: 'Button',
    TextComponent: 'Text base',
    IconsComponent: 'Icons',
    PlayButtonComponent: 'Button “Play”',
    CornerImagesComponent: 'Images',
    CornerImageComponent: 'Image',
    FullBackgroundImageComponent: 'Image',
}

export enum OptionIds  {
    BackgroundComponent =  'BackgroundComponent',
    BackgroundColorComponent =  'BackgroundColorComponent',
    BackgroundImageComponent = 'BackgroundImageComponent',
    ImageComponent = 'ImageComponent',
    ImagesComponent = 'ImagesComponent',
    VideoComponent = 'VideoComponent',
    PdfComponent = 'PdfComponent',
    ButtonComponent = 'ButtonComponent',
    TextComponent = 'TextComponent',
    IconsComponent = 'IconsComponent',
    CardsComponent = 'CardsComponent',
    PlayButtonComponent = 'PlayButtonComponent',
    CornerImagesComponent = 'CornerImagesComponent',
    CornerImageComponent = 'CornerImageComponent',
    FullBackgroundImageComponent = 'FullBackgroundImageComponent',
    ButtonsComponent = 'ButtonsComponent',
}

export const sectionTitle: Record<LandingSectionCategory, string> = {
    hero: 'Hero',
    about: 'About',
    service: 'Services',
    services: 'Services',
    team: 'Team',
    cta: 'Call to Action',
    form: 'Form',
    text: 'Text',
    gallery: 'Images & Gallery',
    video: 'Video',
};

export const aiGenerationStepsText: Record<LandingAiGenerationSteps, string> = {
    title: 'Boost Ai Assistant is trying to get the company name...',
    description: 'The company description generation is in progress...',
    images: 'In a couple of seconds, Ai Assistant will generate the necessary visual content...',
    finish: "Great! Let's check out the result 🚀",
    video: ''
};

export const HERO = 'hero'

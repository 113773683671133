import EventsPageLayout from '@layouts/EventsPageLayout'
import React, { useState, useMemo, useCallback, useEffect } from 'react'
import { DatePicker, Button, Spin } from 'antd';
import dayjs, { Dayjs } from "dayjs";
import ContentLayout from "@layouts/ContentLayout.tsx";
import { useGetPartnerStatisticsMetricsQuery, useGetPartnerStatisticsQuery } from '@store/type-partner/statistics/statistics.api';
import ExitCrossIcon from '@assets/ExitCrossIcon'
import { INIT_PAGE } from "@components/InfiniteScrollTable/constants.ts";
import { useGetPartnerEventQuery } from "@store/type-partner/events/partner-event.api.ts";
import { useGetPartnerLandingsByEventIdQuery } from "@store/type-partner/landings/landings.api.ts";
import { useGetPartnerCampaignsQuery } from "@store/type-partner/campaigns/campaigns.api.ts";
import AnalyticsTable from "@components/Analytics/components/AnalyticsTable.tsx";
import "@components/Analytics/styles.scss";
import CustomResponsiveLine from "@components/Analytics/components/CustomResponsiveLine.tsx";
import { getResponsiveLineData } from "@components/Analytics/helpers.ts";
import { Filters, FiltersKeys, StatisticsMetrics } from "@shared/statisticTypes.ts";
import { initialFilters } from "@components/Analytics/constants.tsx";
import InfiniteSelect from "@components/InfiniteSelect/InfiniteSelect.tsx";
import { useBreakpoints } from '@hooks/browser';
import './styles.scss'
import { GAP_INIT } from "@shared/constants.ts";
import { getCurrencySymbol } from "@shared/utils.ts";

const { RangePicker } = DatePicker;

const AnalyticsPageDesktop: React.FC = () => {
    const isDesktop = useBreakpoints().md

    const [statisticsLeftValue, setStatisticsLeftValue] = useState<StatisticsMetrics[]>([])
    const [statisticsRightValue, setStatisticsRightValue] = useState<StatisticsMetrics[]>([])
    const [filters, setFilters] = useState<Filters>(initialFilters)
    const [eventsSearchValue, setEventsSearchValue] = useState<string>()
    const [campaignsSearchValue, setCampaignsSearchValue] = useState<string>()
    const [landingsPage, setLandingsPage] = useState(INIT_PAGE);
    const [eventsPage, setEventsPage] = useState(INIT_PAGE);
    const [campaignsPage, setCampaignsPage] = useState(INIT_PAGE);
    const [currency, setCurrency] = useState<string>()

    const { data: events, isLoading: isEventsLoading, isFetching: isEventsFetching } = useGetPartnerEventQuery({ filters: { search: eventsSearchValue, page: eventsPage } })
    const { data: landingsRes, isLoading: isLandingsLoading, isFetching: isLandingsFetching } = useGetPartnerLandingsByEventIdQuery({ id: Number(filters?.events?.[0]), page: landingsPage }, { skip: !filters?.events?.[0] })
    const { data: campaignsRes, isLoading: isCampaignsLoading, isFetching: isCampaignsFetching } = useGetPartnerCampaignsQuery({ filter: { events: filters?.events, search: campaignsSearchValue, page: campaignsPage } }, { skip: !filters?.events?.length })
    const { data: statisticsMetrics } = useGetPartnerStatisticsMetricsQuery()
    const { data: statistics, isLoading: isStatisticsLoading, isFetching: isStatisticsFetching } = useGetPartnerStatisticsQuery(filters, { skip: !filters?.dateFrom })

    const currenciesOptions = useMemo(() => {
        return statistics?.data?.sums?.map(({ currency: currencyValue }) => {
            return ({ value: currencyValue || '', label: `${getCurrencySymbol(currencyValue)} ${currencyValue ? currencyValue.toUpperCase() : ''}` })
        }) || []
    }, [statistics])

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (currenciesOptions && currenciesOptions[0]?.value) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
            setCurrency(currenciesOptions[0].value)
        }
    }, [currenciesOptions]);

    useEffect(() => {
        const analyticsSideLeftValue = localStorage.getItem(`analytics_side_value_partner_left`);
        const analyticsSideRightValue = localStorage.getItem(`analytics_side_value_partner_right`);
        if (analyticsSideLeftValue) {
            setStatisticsLeftValue((JSON.parse(analyticsSideLeftValue) || []) as StatisticsMetrics[])
        }
        if (analyticsSideRightValue) {
            setStatisticsRightValue((JSON.parse(analyticsSideRightValue) || []) as StatisticsMetrics[])
        }
        if (!analyticsSideLeftValue) {
            const initialValue = statisticsMetrics?.data?.[0] ? [{
                ...(statisticsMetrics?.data?.[0] || {}),
                value: statisticsMetrics?.data?.[0]?.metric
            }] : []
            setStatisticsLeftValue(initialValue)
        }
    }, [statisticsMetrics?.data]);

    const setInitialFilters = useCallback(() => {
        const today = dayjs();
        const thirtyDaysAgo = dayjs().subtract(30, 'day');
        const todayFormatted = today.format('YYYY-MM-DD');
        const thirtyDaysAgoFormatted = thirtyDaysAgo.format('YYYY-MM-DD');
        setFilters({
            ...initialFilters,
            dateFrom: thirtyDaysAgoFormatted,
            dateTo: todayFormatted
        })
    }, [])

    useEffect(() => {
        setInitialFilters()
    }, []);

    const onDateChange = (dateStrings: string[]) => {
        setFilters((prev) => ({
            ...prev,
            dateFrom: dateStrings[0],
            dateTo: dateStrings[1]
        }))
    }

    const statisticsDates = useMemo(() => {
        return statistics?.data?.dates || []
    }, [statistics])

    const { leftData, rightData, leftMax, rightMax, leftMin, rightMin } = useMemo(() => getResponsiveLineData({ statisticsDates, statisticsLeftValue, statisticsRightValue, currency }), [statisticsDates, statisticsLeftValue, statisticsRightValue, currency])

    const handleChangeFilter = useCallback((field: FiltersKeys, value: string[]) => {
        if (field === FiltersKeys.events) {
            setLandingsPage(INIT_PAGE)
            setCampaignsPage(INIT_PAGE)
        }
        if (field === FiltersKeys.landings) {
            setCampaignsPage(INIT_PAGE)
        }
        if (field === FiltersKeys.events && !value.length) {
            setFilters((prev) => ({ ...prev, [field]: value, partners: [], landings: [], campaigns: [] }))
        } else if (field === FiltersKeys.landings && !value.length) {
            setFilters((prev) => ({ ...prev, landings: [], campaigns: [] }))
        } else {
            setFilters((prev) => ({ ...prev, [field]: value }))
        }
    }, [])

    const maxDate = dayjs().endOf('day');

    const disabledDate = (current: Dayjs) => {
        return current && current > maxDate;
    };

    const handleGapChange = useCallback((value: string) => {
        setFilters((prev) => ({ ...prev, gap: value.toString() }))
    }, [])

    const tickRotation = useMemo(() => ([...leftData, ...rightData]?.[0]?.data?.length > 20 ? -45 : 0), [leftData, rightData])
    if (!isDesktop) {
        return (
            <EventsPageLayout>
                <ContentLayout>
                    <h3 className='flex-center'>Work in Progress...</h3>
                    <h3 className='flex-center'>🚧🚧🚧</h3>
                </ContentLayout>
            </EventsPageLayout>
        )
    }
    return (
        <EventsPageLayout>
            <ContentLayout>
                <div className='flex gap-8 mb-12'>
                    <InfiniteSelect
                        id="partners-page__events"
                        placeholder='select'
                        maxTagCount={1}
                        style={{ width: '200px' }}
                        filterOption={false}
                        notFoundContent={isEventsLoading || isEventsFetching ? <Spin size="small" /> :
                            <div>There is no events</div>}
                        isLoading={isEventsLoading || isEventsFetching}
                        listHeight={160}
                        onChange={(value: string) => handleChangeFilter(FiltersKeys.events, value ? [value] : [])}
                        page={eventsPage}
                        setPage={setEventsPage}
                        data={events}
                        value={filters.events}
                        allowClear
                        showSearch
                        setSearchValue={setEventsSearchValue}
                        optionsMapper={({ name, id }) => ({ value: id.toString(), label: name })}
                        classNameProp="analytics-page__partners__infinite-select__event"
                    />
                    <InfiniteSelect
                        id="partners-page__landings"
                        disabled={!filters.events.length}
                        placeholder='select'
                        style={{ width: '200px' }}
                        maxTagCount="responsive"
                        mode="multiple"
                        allowClear
                        filterOption={false}
                        notFoundContent={isLandingsLoading || isLandingsFetching ? <Spin size="small" /> : <div>There is no landings for selected event</div>}
                        isLoading={isLandingsLoading || isLandingsFetching}
                        value={filters.landings || []}
                        onChange={(value) => handleChangeFilter(FiltersKeys.landings, value)}
                        optionsMapper={({ id, title }) => ({ value: id.toString(), label: title })}
                        data={landingsRes}
                        listHeight={160}
                        page={landingsPage}
                        setPage={setLandingsPage}
                        classNameProp="analytics-page__partners__infinite-select__landing"
                    />
                    <InfiniteSelect
                        id="partners-page__campaigns"
                        disabled={!filters.events.length}
                        placeholder='select'
                        style={{ width: '200px' }}
                        maxTagCount="responsive"
                        mode="multiple"
                        allowClear
                        filterOption={false}
                        notFoundContent={isCampaignsLoading || isCampaignsFetching ? <Spin size="small" /> :
                            <div>There is no campaigns for selected event</div>}
                        isLoading={isCampaignsLoading || isCampaignsFetching}
                        value={filters.campaigns || []}
                        onChange={(value) => handleChangeFilter(FiltersKeys.campaigns, value)}
                        optionsMapper={({ name, id }) => ({ value: id.toString(), label: name })}
                        data={campaignsRes}
                        listHeight={160}
                        page={campaignsPage}
                        setPage={setCampaignsPage}
                        showSearch
                        setSearchValue={setCampaignsSearchValue}
                        classNameProp="analytics-page__partners__infinite-select__campaigns"
                    />
                    <RangePicker
                        disabledDate={disabledDate}
                        className="analytics-page__range-picker"
                        format="YYYY-MM-DD"
                        value={[filters?.dateFrom ? dayjs(filters.dateFrom) : null, filters?.dateTo ? dayjs(filters.dateTo) : null]}
                        onChange={(_, dateStrings) => onDateChange(dateStrings)}
                    />
                    <Button
                        size='large'
                        type="text"
                        onClick={setInitialFilters}
                        className="analytics-page__reset-button"
                    >
                        <ExitCrossIcon />
                        Reset
                    </Button>
                </div>
                <div className="mb-24">
                    <AnalyticsTable
                        data={statistics?.data?.sums}
                        isLoading={isStatisticsLoading || isStatisticsFetching}
                    />
                </div>
                <CustomResponsiveLine
                    leftData={leftData}
                    rightData={rightData}
                    leftMax={leftMax}
                    rightMax={rightMax}
                    leftMin={leftMin}
                    rightMin={rightMin}
                    tickRotation={tickRotation}
                    statisticsRightValue={statisticsRightValue}
                    statisticsLeftValue={statisticsLeftValue}
                    setStatisticsRightValue={setStatisticsRightValue}
                    setStatisticsLeftValue={setStatisticsLeftValue}
                    statisticsMetrics={statisticsMetrics}
                    gap={filters?.gap || GAP_INIT}
                    handleGapChange={handleGapChange}
                    currenciesOptions={currenciesOptions}
                    currency={currency}
                    setCurrency={setCurrency}
                />
            </ContentLayout>
        </EventsPageLayout>
    )
}

export default AnalyticsPageDesktop
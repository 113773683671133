import EventsPageLayout from '@layouts/EventsPageLayout'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import React, { useContext, useEffect, useState, useMemo, useCallback } from 'react';
import CampaignForm from '../components/CampaignForm';
import CustomButton from '@components/CustomButton';
import Loader from '@components/Loader';
import { campaignInitialForm } from './constants';
import { CampaignEditorContext } from '@contexts/CampaignEditorContext';
import './styles.scss'
import { Select, message, Modal } from 'antd';
import { ErrorDataResponse, errorsToFormAdapter } from '@shared/utils';
import HeaderBreadcrumb from '@components/Header/HeaderBreadcrumb';
import getCrumbs, { getCampaignDefaultName } from '@pages/PartnerRolePages/Campaigns/CampaignEditorPage/helpers';
import { useGetEventByIdFromCatalogQuery } from '@store/type-partner/events-catalog/partner-event-catalog.api';
import { useGetPartnerEventQuery } from '@store/type-partner/events/partner-event.api';
import { GetPartnerEventDataItem } from '@store/type-partner/events/models';

import { useGetCampaignEditorDataByIdQuery, usePatchCampaignDataByIdMutation, usePostCreateCampaignByEventIdMutation } from '@store/type-partner/campaigns/campaigns.api';
import { AdAsset, CampaignEditorEnum } from '@store/main/organization/models';
import {
    CampaignDataItem,
    CampaignEditorBody,
    CampaignEditorResponse,
} from '@store/type-partner/campaigns/models';
import { useAppSelector } from '@hooks/redux';
import { partnerCampaignsActions } from '@store/type-partner/campaigns/campaigns.slice';
import { useDispatch } from 'react-redux';
import EyeIcon from '../../../../assets/EyeIcon';
import ErrorStatusModal from '@pages/PartnerRolePages/Campaigns/components/ErrorStatusModal';
import { events } from "@pages/routes";
import { useGetFiltersByEnumQuery } from "@store/main/enums/enums.api";
import { EventCatalogItemStatuses } from "@shared/constants";
import usePageAnalytics from "@hooks/sendAnalytics/usePageAnalytics";
import sendAnalytics from "@hooks/sendAnalytics/sendAnalytics";
import { GTMEventName } from "@hooks/sendAnalytics/constants";
import { enableSubmit } from '@contexts/utils/campaigEditorUtils';
import { useBreakpoints } from '@hooks/browser';
import { ExitPageContext } from "@contexts/ExitPageContext.tsx";
import usePopState from "@hooks/usePopState.ts";
import Avatar from "@components/Avatar";
import ArrowIcon from "@assets/ArrowIcon.tsx";

const filterForValues = (list: { value?: string }[] | AdAsset[]): AdAsset[] => {
    if (!list || !list.length) return []
    return (list as AdAsset[]).filter(item => item.value !== undefined);
};

const CampaignEditorPage = () => {
    usePopState()
    usePageAnalytics('campaign_editor');
    const { eventId: eventIdFromParam, campaignId } = useParams()
    const { data: statuses } = useGetFiltersByEnumQuery({ name: 'event_invite_status' })

    const availableStatusIds = statuses?.data?.filter(({ slug }) => slug === EventCatalogItemStatuses.available || slug === EventCatalogItemStatuses.active)?.map(({ id }) => id) || []
    const {
        formValues,
        setFormValues,
        setFormErrors,
        isLoading,
        setLoading,
        isSaving,
        setSaving,
        updateInitialFormHandler,
    } = useContext(CampaignEditorContext);
    const {
        isExitPageEnabled,
        setIsExitPageEnabled,
        isExitPageModalOpen,
        exitPageLink,
        onNavigate,
        onCancel
    } = useContext(ExitPageContext);

    const isDesktop = useBreakpoints().md
    const navigate = useNavigate();
    const { pathname } = useLocation()
    const dispatch = useDispatch()
    const [onCreateCampaign] = usePostCreateCampaignByEventIdMutation()
    const [onUpdateCampaign] = usePatchCampaignDataByIdMutation()
    const { data: myEventsRes, isLoading: eventsLoading } = useGetPartnerEventQuery({ filters: { statuses: availableStatusIds } })
    const { data: eventData, isLoading: isEventDataLoading } = useGetEventByIdFromCatalogQuery(
        { id: eventIdFromParam?.toString() || '' },
        { skip: !eventIdFromParam }
    );
    const { campaignEditorsData } = useAppSelector(state => state.partnerCampaigns)

    const [eventId, setEventId] = useState(eventIdFromParam ? Number(eventIdFromParam) : '')
    const [errorStatusModalData, setErrorStatusModalData] = useState<{ logs: CampaignDataItem['logs'], campaignTitle: string } | null>(null)
    const [eventsList, setEventsList] = useState<GetPartnerEventDataItem[]>([])

    const [eventsOptions, setEventsOptions] = useState<any[]>([])
    const { isLoading: campaignIsLoading, refetch } = useGetCampaignEditorDataByIdQuery(
        { eventId: eventId as unknown as string, campaignId: campaignId! },
        { skip: !campaignId || !eventId }
    );

    const breadCrumbs = useMemo(() => getCrumbs(pathname, eventData?.data), [pathname, eventData])


    useEffect(() => {
        setEventsList(myEventsRes?.data || [])
        if (myEventsRes?.data) {
            const nextEventsOptions = myEventsRes.data.map(item => {
                return {
                    label: (
                        <div className='campaign-create__form-landing-option'>
                            <Avatar url={item.logo} bordered size="24" />
                            <span>{item.name}</span>
                        </div>
                    ),
                    value: item.id
                }
            })
            setEventsOptions(nextEventsOptions)
        }
    }, [myEventsRes?.data])
    // CHECK IF UPDATE INITIAL FORM IS NECCESSARY
    useEffect(() => {
        if (isEventDataLoading) {
            return
        }
        if (!campaignId) {
            const title = getCampaignDefaultName(eventData?.data)
            setFormValues({ ...campaignInitialForm, title });
            setLoading(false);
        } else {
            if (!campaignEditorsData[campaignId]?.campaignEditor) {
                setLoading(true);
                refetch()
                    .unwrap()
                    .then(response => {
                        if (response) {
                            updateInitialFormHandler(response);
                        }
                    })
                    .catch(error => {
                        console.error('Error refetching campaign:', error);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }
    }, [campaignId, isEventDataLoading]);

    const onErrorStatusModalOpen = useCallback(() => {
        setErrorStatusModalData({ campaignTitle: formValues.title || '', logs: [] })
    }, [formValues?.title])

    const onErrorStatusModalClose = () => {
        setErrorStatusModalData(null)
    }

    const leftSide = useMemo(() => {
        return formValues?.logs ? (
            <div className='flex' onClick={onErrorStatusModalOpen}>
                <div className='vertical-divider' />
                <div className='campaign-create__view-error-button'>
                    <EyeIcon fillColor="#CF1322" withBorders={false} backgroundFillColor="transparent" />
                    View errors
                </div>
            </div>
        ) : null
    }, [formValues?.logs, onErrorStatusModalOpen])

    // FORCE UPDATE THE FORM VALUES

    useEffect(() => {
        const key = campaignId || CampaignEditorEnum.new
        if (campaignEditorsData[key]?.campaignEditor) {
            setFormValues(campaignEditorsData[key].campaignEditor || campaignInitialForm);
            if (!eventId) {
                setEventId(campaignEditorsData[key].selectedEvent || '')
            }
            dispatch(partnerCampaignsActions.setCampaignsEditorData({ campaignEditor: null, selectedEvent: null, campaignId }))
        }
    }, [campaignId, setFormValues, dispatch, eventId])

    useEffect(() => {
        return () => {
            setIsExitPageEnabled(false)
        }
    }, []);

    useEffect(() => {
        if (isExitPageEnabled) {
            window.history.pushState({}, '', `${pathname}`);
        }
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            if (isExitPageEnabled) {
                event.preventDefault();
            }
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [pathname, isExitPageEnabled]);

    const onSubmit = async () => {
        const { ads, ...rest } = formValues

        setSaving(true)

        const assets = {
            title: filterForValues(ads[0]?.assets.title),
            short_title: filterForValues(ads[0].assets.short_title),
            description: filterForValues(ads[0].assets.description),
            image_original: filterForValues(ads[0].assets.image_original),
            logo_original: filterForValues(ads[0].assets.logo_original),
            business_name: filterForValues(ads[0].assets.business_name),
        }

        const payload: CampaignEditorBody = {
            ...rest,
            ads: [{
                landing: ads[0].landing,
                assets,
                id: ads[0]?.id || ''
            }],
            eventId: eventId as unknown as string
        }

        try {
            // const response = await onCreateCampaign(payload);
            const response = campaignId ? await onUpdateCampaign({ eventId: eventId as unknown as string, campaignId, ...formValues }) : await onCreateCampaign(payload);
            if ('error' in response) {
                setSaving(false)
                const errors = errorsToFormAdapter(response as ErrorDataResponse)
                errors && setFormErrors(errors);
                if (!campaignId) {
                    dispatch(partnerCampaignsActions.setCampaignsEditorData({ campaignEditor: formValues, selectedEvent: eventId as unknown as string, campaignId }))
                }
                void message.error('Please check the required fields')

            } else {
                sendAnalytics(GTMEventName.saveCampaign)
                setSaving(false)
                const isEvents = pathname.includes(events)
                const newCampaignId = (response as { data: CampaignEditorResponse }).data.data.id
                const landingUrl = ads[0].landing ? '' : '/landing'
                if (!campaignId) {
                    dispatch(partnerCampaignsActions.setCampaignsEditorData({ campaignEditor: null, selectedEvent: null, campaignId: '' }))
                }
                let url = ''
                if (landingUrl) {
                    url = isEvents ? `/events/edit/${eventId || ''}/campaign/${newCampaignId}${landingUrl}` : `/campaigns/edit/${eventId || ''}/campaign/${newCampaignId}${landingUrl}`
                } else {
                    url = isEvents ? `/events/edit/${eventId || ''}` : `/campaigns`
                }
                if (isExitPageEnabled) {
                    setIsExitPageEnabled(false)
                }
                navigate(url);
                console.log('Success:');
            }
        } catch (error) {
            if (!campaignId) {
                dispatch(partnerCampaignsActions.setCampaignsEditorData({ campaignEditor: formValues, selectedEvent: eventId as unknown as string, campaignId }))
            }
            setSaving(false)
            console.log('SMTH WRONG');
            void message.error('Something gone wrong')

            // Handle the error here
        }
    }
    if (isLoading || campaignIsLoading || eventsLoading) {
        return (
            <EventsPageLayout>
                {isDesktop && <HeaderBreadcrumb
                    crumbs={breadCrumbs}
                    leftSide={leftSide}
                />}
                <Loader />
            </EventsPageLayout>
        )
    }

    return (
        <EventsPageLayout className='campaign-editor-page'>
            {isDesktop && <HeaderBreadcrumb
                crumbs={breadCrumbs}
                leftSide={leftSide}
            />}
            <div className='campaign-create max-w-l'>
                <p className={`text-regular-14 label text-neutral-8`}>
                    Event
                </p>
                <Select
                    style={{width: '100%', height: eventId ? '40px' : 'auto'}}
                    options={eventsOptions}
                    value={eventId}
                    onSelect={setEventId}
                    className="campaign-editor-page__event-select"
                    placeholder='Select event to activate editor'
                    suffixIcon={<ArrowIcon fillColor="#C0C1C3" width="20" height="20" className="common-select-icon" />}
                />
                {isSaving ? <Loader/> : null}
                <div className={`campaign-create__form ${!eventId ? 'blocked' : ''}`}>
                    <CampaignForm
                        eventData={eventData?.data}
                        eventId={eventId as unknown as string}
                    />
                </div>
                <div className='campaign-create__main-cta-wrapper'>
                    <CustomButton
                        size='large'
                        title='Cancel'
                        onClick={() => {
                            if (isExitPageEnabled) {
                                setIsExitPageEnabled(false)
                            }
                            navigate(-1)
                        }}
                    />
                    <CustomButton
                        size='large'
                        type='primary'
                        title='Save and Continue'
                        loading={isLoading}
                        disabled={!eventId || !enableSubmit(formValues)}
                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                        onClick={onSubmit}
                    />
                </div>
            </div>
            <ErrorStatusModal
                isOpen={Boolean(errorStatusModalData)}
                onClose={onErrorStatusModalClose}
                campaignTitle={errorStatusModalData?.campaignTitle || ''}
                logs={errorStatusModalData?.logs}
            />
            <Modal
                title="You have unsaved changes to your campaign"
                open={isExitPageModalOpen}
                onCancel={onCancel}
                onOk={() => {
                    if (exitPageLink) {
                        navigate(exitPageLink)
                    } else {
                        navigate(-2)
                    }
                    onNavigate()
                }}
                okButtonProps={{
                    type: 'default'
                }}
                cancelButtonProps={{
                    type: 'primary'
                }}
                centered
                className="campaign-editor-page__unsaved-changes-modal"
                cancelText="Continue editing"
                okText="Exit without saving"
                width={440}
            >
                Are you sure you want to exit?
            </Modal>
        </EventsPageLayout>
    )
}

export default CampaignEditorPage
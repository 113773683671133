import React, { useEffect, useState } from "react";
import { message, Typography, Upload, UploadFile } from "antd";
import { HeroImageDropDownIcon } from "@icons/HeroImageDropDownIcon.tsx";
import './styles.scss'

type SettingsImageUploaderPropsType = {
    index?: number
    url?: string;
    name?: string;
    action: string;
    onChange: (value: string) => void
    errorCb: () => void
}

const SettingsImageUploader: React.FC<SettingsImageUploaderPropsType> = ({ onChange, action, url, errorCb, index, name = 'Image' }) => {
    const [fileList, setFileList] = useState<UploadFile[]>([])

    useEffect(() => {
        if (url && url !== 'none') {
            setFileList([
                {
                    uid: '-1',
                    name: `${name} ${index || ''}`,
                    status: 'done',
                    url
                }
            ])
        }
    }, [url])

    const [showDropZone, setShowDropZone] = useState(true);

    const beforeUpload = (file: { type: 'image/jpeg' | 'image/jpeg', status: string, size: number, response?: any }) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            file.status = 'error'
            if (errorCb) {
                errorCb()
            }
            void message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 10;
        if (!isLt2M) {
            void message.error('Image must smaller than 10MB!');
            if (errorCb) {
                errorCb()
            }
            file.status = 'error'
        }
        return isJpgOrPng && isLt2M;
    };

    return (
        <Upload
            name="image"
            maxCount={1}
            action={action}
            listType="picture"
            beforeUpload={beforeUpload}
            accept=".jpg,.jpeg,.png,.webp,.svg"
            fileList={fileList}
            onChange={(e) => {
                const fileList = e.fileList?.map((item) => ({...item, name: `${name} ${index || ''}`})) || []
                if (e.file.status === 'done') {
                    setShowDropZone(false)
                    onChange(e.file?.response?.data)
                    setFileList(fileList);
                }
                if (e.file.status === "removed") {
                    onChange('')
                    setShowDropZone(true)
                    return setFileList([]);
                }
                setShowDropZone(false)
                onChange(e.file?.response?.data)
                setFileList(fileList);
            }}
        >
            {showDropZone && !fileList.length ?
                <div className="settings-image-uploader__content">
                    <div className="settings-image-uploader__rulesBlock">
                        <HeroImageDropDownIcon />
                        <div className="settings-image-uploader__textBlock">
                            <Typography.Title className="settings-image-uploader__title" level={4}>
                                Click or drag file to this area to upload {index ? `${name} ${index}` : ''}
                            </Typography.Title>
                            <Typography.Text className="settings-image-uploader__text">
                                Supports: .jpg, .png. File size: 10 MB. {name} size: at least 939 × 500 px.
                            </Typography.Text>
                        </div>
                    </div>
                </div>
                :
                null}
        </Upload>
    )
}

export default SettingsImageUploader;

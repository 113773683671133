import {
    CampaignStatuses,
    CredentialsStatuses,
    EventCatalogItemStatuses,
    ModerationStatuses,
    WithdrawalStatuses
} from "@shared/constants";
import { SetupLandingStatuses } from "@pages/EventRolePages/EventPage/setup/landing-page-templates/constants.tsx";

const statusPlugColor = (status: CampaignStatuses | EventCatalogItemStatuses | ModerationStatuses | string) => {
    switch (status) {
    case CampaignStatuses.paused:
    case CampaignStatuses.review:
    case EventCatalogItemStatuses.not_available:
        return 'grey'
    case CampaignStatuses.active:
    case EventCatalogItemStatuses.available:
    case EventCatalogItemStatuses.enabled:
    case ModerationStatuses.published:
    case WithdrawalStatuses.completed:
    case SetupLandingStatuses.generated:
    case SetupLandingStatuses.enabled:
        return 'green'
    case CampaignStatuses.in_progress:
    case CampaignStatuses.pending:
    case CampaignStatuses.scheduled:
    case EventCatalogItemStatuses.awaiting_invitation:
    case ModerationStatuses.under_appeal:
        return 'blue'
    case CampaignStatuses.remove:
    case CampaignStatuses.activated:
    case ModerationStatuses.rejected:
    case CredentialsStatuses.revoked:
    case SetupLandingStatuses.rejected:
        return 'red'
    case CampaignStatuses.needs_attention:
    case EventCatalogItemStatuses.awaiting_approval:
    case ModerationStatuses.on_review:
        return 'yellow'
    case CampaignStatuses.error:
        return 'danger'
    case WithdrawalStatuses.requested:
        return 'orange'
    default:
        return 'grey'
    }
}

export { statusPlugColor }

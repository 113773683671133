import React, { useState } from "react";
import { Button, Form, Input, Modal, Popover } from "antd";
import styles from "./ChangingPasswordModal.module.css";
import { usePasswordUpdateMutation } from "@store/main/-user/user.api";
import PasswordPopover from '@pages/CommonPages/Authentification/Register/components/PasswordPopover';
import { CheckCircleSuccessIcon } from '@icons/CheckCircleSuccessIcon';
import { ErrorDataResponse, errorsToFormAdapter } from '@shared/utils';

type PasswordType = {
    new_password: string
    current_password: string
    confirm_password: string
}

type ChangingPasswordModalPropsType = {
    isOpen: boolean
    onClose: (isOpen: boolean) => void
    reFetchUser: () => Promise<any>
}
interface ErrorsValues {
    password: string;
    password_confirmation: string;
    old_password: string

}
const errorsInitial = {
    password: '',
    password_confirmation: '',
    old_password: ''
}
const formInitial = {
    new_password: "",
    current_password: "",
    confirm_password: ""
}
const ChangingPasswordModal: React.FC<ChangingPasswordModalPropsType> = (props) => {
    const { isOpen, onClose, reFetchUser } = props;
    const [onPasswordChange] = usePasswordUpdateMutation();
    const [isLoading, setLoading] = useState(false)

    const [responseError, setResponseError] = useState<ErrorsValues>(errorsInitial)
    const [formStatePassword, setFormStatePassword] = useState<PasswordType>(formInitial);


    const onChangeFieldValue = (fieldName: string, value: string) => {
        setResponseError(errorsInitial)
        setFormStatePassword(prevState => ({ ...prevState, [fieldName]: value }))
    }

    const handleOk = async () => {
        // await changeUserPassword({
        //     password: formStatePassword.new_password,
        //     password_confirmation: formStatePassword.confirm_password,
        //     old_password: formStatePassword.current_password
        // })
        // await reFetchUser()
        // setFormStatePassword({
        //     new_password: "",
        //     current_password: "",
        //     confirm_password: ""
        // })
        // onClose(false)

        setLoading(true)
        try {
            const response = await onPasswordChange({
                password: formStatePassword.new_password,
                password_confirmation: formStatePassword.confirm_password,
                old_password: formStatePassword.current_password
            })
            if ('error' in response) {
                setLoading(false)
                const errors = errorsToFormAdapter(response as ErrorDataResponse)
                setResponseError(errors as unknown as ErrorsValues)
            }

            if ('data' in response) {
                onModalClose()
            }
        } catch (error) {
            setLoading(false)
            console.log('GOT ERROR');
        }
    }
    const [isSuccessStatus, setIsSuccessStatus] = useState(false);
    const isSuccessStatusHandler = (statusState: boolean) => {
        setIsSuccessStatus(statusState)
    }
    // const isDisabledOkButton = !formStatePassword.confirm_password || !formStatePassword.current_password || !formStatePassword.new_password || isLoadingChangeUserPassword || !isSuccessStatus;

    const onModalClose = () => {
        onClose(false)
        setFormStatePassword(formInitial)
        setResponseError(errorsInitial)
        setIsSuccessStatus(false)
    }

    return (
        <Modal
            title="Change password"
            open={isOpen}
            onCancel={onModalClose}
            confirmLoading={isLoading}
            className={styles.ChangingPasswordModal}
            footer={
                <>
                    <div className={styles.ChangingPasswordModal__footer}>
                        <Button
                            size={'large'}
                            onClick={onModalClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            size={'large'}
                            type={'primary'}
                            // eslint-disable-next-line @typescript-eslint/no-misused-promises
                            onClick={handleOk}
                            disabled={!isSuccessStatus}
                            loading={isLoading}
                        >
                            Save
                        </Button>
                    </div>
                </>
            }
        >
            <Form layout={'vertical'}>
                <Form.Item
                    className={styles.ChangingPasswordModal__formItem}
                    label={<span style={{ marginTop: '12px' }} className={styles.ChangingPasswordModal__label}>Current password</span>}
                    htmlFor={'currentPasswordModal'}
                    help={<span style={{ color: 'red' }}>{responseError.old_password}</span>}
                    validateStatus={responseError.old_password ? 'error' : ''}
                >
                    <Input.Password
                        id={'currentPasswordModal'}
                        size={'large'}
                        value={formStatePassword.current_password}
                        onChange={(e) => onChangeFieldValue('current_password', e.target.value)}
                    />
                </Form.Item>

                <Popover
                    content={() => (
                        <PasswordPopover
                            password={formStatePassword.new_password}
                            isSuccessStatusHandler={isSuccessStatusHandler}
                        />
                    )}
                    placement="bottomLeft"
                    trigger={'focus'}
                >

                    <Form.Item
                        label={<span className={styles.ChangingPasswordModal__label}>New password</span>}
                        htmlFor={'newPasswordModal'}
                        className={styles.ChangingPasswordModal__formItem}
                    >

                        <Input.Password
                            id={'newPasswordModal'}
                            size={'large'}
                            value={formStatePassword.new_password}
                            onChange={(e) => onChangeFieldValue('new_password', e.target.value)}
                            prefix={isSuccessStatus ? <CheckCircleSuccessIcon /> : null}
                        />
                    </Form.Item>
                </Popover>
                <Form.Item
                    label={<span className={styles.ChangingPasswordModal__label}>Confirm password</span>}
                    htmlFor={'confirmPasswordModal'}
                    className={styles.ChangingPasswordModal__itemConfirm}
                    help={<span style={{ color: 'red' }}>{responseError.password}</span>}
                    validateStatus={responseError.password ? 'error' : ''}
                >
                    <Input.Password
                        id={'confirmPasswordModal'}
                        size={'large'}
                        type={'password'}
                        value={formStatePassword.confirm_password}
                        onChange={(e) => onChangeFieldValue('confirm_password', e.target.value)}
                    />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default ChangingPasswordModal;

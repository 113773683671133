import './styles.scss'
import {menuItemsEvent, menuItemsPartner, userMenuItemsEvent, userMenuItemsPartner} from './constants'
import {useAuth} from '@contexts/AuthContext';
import {Divider, Drawer} from 'antd';
import {Children, MenuItem, MenuPath} from './components/MenuItem';
import {
    descriptionsEventMobile,
    EventsTourStepsMobile,
    infoTourDescription,
    TourStages
} from "@components/TourComponent/constants.tsx";
import NewTourComponent from "@components/TourComponent/NewTourComponent.tsx";
import React, {useContext, useEffect, useRef} from "react";
import {TourContext} from "@contexts/TourContext.tsx";
import {pro} from "@pages/routes.ts";
import {useNavigate} from "react-router-dom";
import {setInfoTooltipCoordinates} from "@components/TourComponent/helpers.ts";

interface SideBarMenuDrawerProps {
    open: boolean;
    onClose: () => void
}
const SideBarMenuDrawer = ({ open, onClose }: SideBarMenuDrawerProps) => {
    const { user, logout } = useAuth()
    const isPartner = user?.organization.type === 'partner'
    const isEvent = user?.organization.type === 'event'
    const navigate = useNavigate()

    const infoTourRef = useRef(null);

    const {
        tourStage,
        onFinishTour,
        tourStep,
        setTourStage,
        setTourStep,
        unblockScroll,
        isInfoTourOpen,
        handleInfoTourClose
    } = useContext(TourContext);

    useEffect(() => {
        if (isInfoTourOpen && open) {
            setInfoTooltipCoordinates("info-mobile-tour-component", true)
        }
    }, [infoTourRef, isInfoTourOpen, open]);

    useEffect(() => {
        if (tourStage === TourStages.event) {
            const element = document.querySelector(`#sidebar-menu-tour-component`)
            if (element) {
                (element as HTMLElement).style.top = '165px';
                (element as HTMLElement).style.left = `19px`;
                (element as HTMLElement).style.bottom = `auto`;
            }
        }
    }, [tourStage, open]);

    return (
        <Drawer
            title={
                <header className='sidebar-menu-drawer__header flex items-center justify-space-between'>
                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_15291_22390)">
                            <path fillRule="evenodd" clipRule="evenodd" d="M28.8091 5.14271C31.0608 5.14271 32.8162 5.63461 34.0752 6.61842C35.3584 7.60223 36 8.99186 36 10.7873C36 12.1892 35.6731 13.4436 35.0194 14.5504C34.3899 15.6326 33.482 16.4811 32.2956 17.096C33.4093 17.4895 34.2568 18.129 34.8378 19.0144C35.4431 19.8752 35.7458 20.9574 35.7458 22.261C35.7458 24.0564 35.3584 25.5936 34.5836 26.8726C33.833 28.1515 32.7677 29.1353 31.3877 29.824C30.0076 30.5127 28.3854 30.857 26.5211 30.857L1.87155 30.857C0.621933 30.857 -0.276224 29.636 0.0778484 28.4187C0.3127 27.6112 1.0427 27.057 1.87155 27.057H17.2413L18.2728 19.6047H7.63048C6.40971 19.6047 5.5097 18.4458 5.79382 17.2398C5.99716 16.3766 6.75679 15.7678 7.63048 15.7678H18.8039L19.7485 8.94266H12.9476C11.7202 8.94266 10.8255 7.76196 11.1395 6.5566C11.3566 5.72317 12.099 5.1427 12.9476 5.1427L20.2745 5.14271H24.5098H24.5418H28.8091ZM26.5211 26.9464H21.473L22.4899 19.494H28.1917C29.3297 19.494 30.1771 19.7523 30.734 20.2688C31.315 20.7607 31.6056 21.4739 31.6056 22.4085C31.6056 23.8351 31.1577 24.9541 30.2618 25.7658C29.3902 26.5528 28.1433 26.9464 26.5211 26.9464ZM27.647 15.6572H23.0346L23.9426 9.05335H28.4823C29.5476 9.05335 30.3587 9.287 30.9155 9.75431C31.4724 10.197 31.7508 10.8365 31.7508 11.6727C31.7508 12.4352 31.5693 13.1238 31.2061 13.7387C30.8671 14.329 30.3829 14.7963 29.7534 15.1407C29.1481 15.485 28.4459 15.6572 27.647 15.6572Z" fill="#1A2440" />
                        </g>
                        <defs>
                            <clipPath id="clip0_15291_22390">
                                <rect width="36" height="36" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>

                    <svg
                        onClick={onClose}
                        width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M16.9074 2.50764C17.2979 2.11712 17.2979 1.48395 16.9074 1.09343C16.5169 0.702906 15.8837 0.702906 15.4932 1.09343L9.00029 7.58632L2.5074 1.09343C2.11688 0.702906 1.48371 0.702906 1.09319 1.09343C0.702662 1.48395 0.702662 2.11712 1.09319 2.50764L7.58608 9.00054L1.09319 15.4934C0.702662 15.884 0.702662 16.5171 1.09319 16.9076C1.48371 17.2982 2.11688 17.2982 2.5074 16.9076L9.00029 10.4148L15.4932 16.9076C15.8837 17.2982 16.5169 17.2982 16.9074 16.9076C17.2979 16.5171 17.2979 15.884 16.9074 15.4934L10.4145 9.00054L16.9074 2.50764Z" fill="#898A8C" />
                    </svg>


                </header>
            }
            className='sidebar-menu-drawer-main'
            placement={'left'}
            onClose={onClose}
            closeIcon={null}
            open={open}
            style={{
                padding: '16px 12px'
            }}
            headerStyle={{
                padding: '0px',
                paddingBottom: '12px'
            }}
            rootStyle={{
                padding: '0px'
            }}
        >
            <main
                className='sidebar-menu-drawer'
            >
                <div
                    className='sidebar-menu-drawer__controls'
                >
                    <div
                        className='sidebar-menu-drawer__links'
                    >
                        {isPartner && Object.entries(menuItemsPartner).map(([key, item]) => (
                            <MenuItem
                                onItemClick={onClose}
                                key={key} path={item.path as MenuPath}
                                title={item.title}
                            />
                        ))}

                        {isEvent && Object.entries(menuItemsEvent).map(([key, item]) => (
                            <MenuItem
                                onItemClick={onClose}
                                key={key} path={item.path as MenuPath}
                                title={item.title}
                            />
                        ))}
                        <Divider />
                        {
                            Object.entries(isEvent ? userMenuItemsEvent : userMenuItemsPartner).map(([key, item]) => (
                                <MenuItem
                                    onItemClick={onClose}
                                    key={key}
                                    title={item.title}
                                    path={item.path as MenuPath}
                                    childrenProp={item.children as Children}
                                />
                            ))
                        }
                        <Divider />
                        <MenuItem
                            onItemClick={() => {
                                logout()
                                onClose()
                            }}
                            path={'/logout'} title={'Sign out'} />
                    </div>
                </div>
            </main>
            {(tourStep > EventsTourStepsMobile.menuItem && tourStep <= EventsTourStepsMobile.menuItemBoostPro) && tourStage === TourStages.event
                ?
                <NewTourComponent
                    id="sidebar-menu-tour-component"
                    isArrowLeftTop
                    isTransition
                    open={(tourStep > EventsTourStepsMobile.menuItem) && tourStage === TourStages.event}
                    onClose={() => {
                        onFinishTour()
                    }}
                    onChange={(step) => {
                        setTourStep(step)
                        if (step === EventsTourStepsMobile.menuItemLeads) {
                            const element = document.querySelector(`#sidebar-menu-tour-component`)
                            if (element) {
                                (element as HTMLElement).style.top = '215px';
                            }
                        }
                        if (step === EventsTourStepsMobile.menuItemAnalytics) {
                            const element = document.querySelector(`#sidebar-menu-tour-component`)
                            if (element) {
                                (element as HTMLElement).style.top = '258px';
                            }
                        }
                        if (step === EventsTourStepsMobile.menuItemBoostPro) {
                            const element = document.querySelector(`#sidebar-menu-tour-component`)
                            if (element) {
                                (element as HTMLElement).style.top = '302px';
                            }
                        }
                        if (step > EventsTourStepsMobile.menuItemBoostPro) {
                            navigate(pro)
                            setTourStage(TourStages.campaign)
                            unblockScroll()
                        }
                    }}
                    current={tourStep}
                    totalCount={8}
                    description={descriptionsEventMobile[tourStep]}
                    buttonText={tourStep === EventsTourStepsMobile.menuItemBoostPro ? 'More about Boost PRO' : 'Next'}
                />
                :
                null}
            {isInfoTourOpen && (
                <NewTourComponent
                    isArrowLeftTop
                    id="info-mobile-tour-component"
                    open={isInfoTourOpen}
                    onClose={() => {
                        handleInfoTourClose()
                        onClose()
                    }}
                    buttonText="Got it!"
                    current={null}
                    onChange={() => {
                        handleInfoTourClose()
                        onClose()
                    }}
                    totalCount={0}
                    description={infoTourDescription}
                />
            )}
        </Drawer>
    )
}

export default SideBarMenuDrawer
import './styles.scss'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { menuItemsEvent } from './constants'
import BoostLogo from '../../icons/BoostLogo';
import {
    home,
    events,
    campaigns,
    leads,
    analytics,
    // partners,
    dashboard,
    audiences,
    moderation,
    partners,
    pro,
    constructor
    // campaigns
} from '../../pages/routes';
import AnalyticsIcon from '@icons/sidebar/AnalyticsIcon';
import CarryOutIcon from '@assets/CarryOutIcon';
import RiseIcon from '@assets/sidemenu/RiseIcon';
import UserAddIcon from '@assets/sidemenu/UserAddIcon';
import { useAuth } from '@contexts/AuthContext';
import DashboardIcon from '@assets/sidemenu/DashboardIcon';
import AudiencesIcon from '@assets/sidemenu/AudiencesIcon';
import ModerationIcon from '@assets/sidemenu/ModerationIcon';
import PartnersIcon from '@assets/sidemenu/PartnersIcon';
import BoostProIcon from '@assets/sidemenu/BoostProIcon';
import React, { ReactNode, RefObject, useContext, useEffect, useRef } from 'react';
import TempIcon from '@icons/sidebar/LPEditorIcon';
import sendAnalytics from "@hooks/sendAnalytics/sendAnalytics";
import { GTMEventName } from "@hooks/sendAnalytics/constants";
import { TourContext } from "@contexts/TourContext.tsx";
import { ExitPageContext } from "@contexts/ExitPageContext.tsx";
import {descriptionsEvent, EventsTourSteps, TourStages} from "@components/TourComponent/constants.tsx";
import NewTourComponent from "@components/TourComponent/NewTourComponent.tsx";
import { setTooltipCoordinatesPlacementRight } from "@components/TourComponent/helpers.ts";

type MenuPath =
    '/' |
    '/events' |
    '/campaigns' |
    '/leads' |
    '/analytics' |
    '/audiences' |
    '/analytics' |
    '/moderation' |
    '/partners' |
    '/dashboard' |
    '/pro' |
    '/constructor'

const MenuItemIcon = ({ path }: { path: MenuPath }) => {
    switch (path) {
        case home:
        case events:
            return (
                <CarryOutIcon
                    isActive={false}
                />
            )

        case campaigns:
            return (
                <RiseIcon
                    isActive={false}
                />
            )
        case leads:
            return (
                <UserAddIcon
                    isActive={false}
                />
            )

        case analytics:
            return (
                <AnalyticsIcon
                    isActive={false}
                />
            )
        case pro:
            return (
                <BoostProIcon
                    isActive={false}
                />
            )
        // TEMPORARY
        case constructor:
            return (
                <TempIcon />
            )

        // EVENTS
        case dashboard:
            return (
                <DashboardIcon
                    isActive={false}
                />
            )
        case audiences:
            return (
                <AudiencesIcon />
            )
        case moderation:
            return (
                <ModerationIcon
                    isActive={false}
                />
            )
        case partners:
            return (
                <PartnersIcon
                    isActive={false}
                />
            )
        default:
            break;
    }
}

type Props = {
    path: MenuPath;
    title: string | ReactNode;
    refProp?: RefObject<HTMLAnchorElement>;
}

const MenuItem = ({ path, title, refProp }: Props) => {
    const { pathname } = useLocation()

    const {
        tourStep,
        setTourStep,
        tourStage
    } = useContext(TourContext);
    const {
        isExitPageEnabled,
        setIsExitPageModalOpen,
        setExitPageLink
    } = useContext(ExitPageContext);

    const formattedPath = path === '/' ? '/' : `${path}`;

    const onClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        if (isExitPageEnabled) {
            e.stopPropagation()
            e.preventDefault()
            setIsExitPageModalOpen(true)
            setExitPageLink(path)
            return
        }
        sendAnalytics(`${GTMEventName.menuItemClick}_${path.replace('/', '')}`)
        if (tourStage === TourStages.event && tourStep > EventsTourSteps.myCampaigns) {
            setTourStep(tourStep + 1)
        }
    }

    return (
        <Link ref={refProp} onClick={onClick} className={`events-sidebar-menu__item ${pathname === formattedPath || formattedPath !== '/' && pathname.includes(formattedPath) ? 'isActive' : ''}`} to={path}>
            <div className='events-sidebar-menu__item-icon'>
                <MenuItemIcon path={path} />
            </div>
            {title}
        </Link>
    );
};

const SideBarMenu = () => {
    const navigate = useNavigate()

    // FOR TESTS ONLY
    // const isEvent = true
    // const isPartner = false

    const { user } = useAuth()
    const isPartner = user?.organization.type === 'partner'
    const isEvent = user?.organization.type === 'event'

    const menuItemCampaignsRef = useRef(null);
    const menuItemLeadsRef = useRef(null);
    const menuItemAnalyticsRef = useRef(null);
    const menuItemBoostProRef = useRef(null);

    const {
        isExitPageEnabled,
        setIsExitPageModalOpen,
        setExitPageLink
    } = useContext(ExitPageContext);

    const onLogoClick = () => {
        if (isExitPageEnabled) {
            setIsExitPageModalOpen(true)
            setExitPageLink('/events')
            return
        }
        navigate('/events')
    }

    const {
        tourStep,
        tourStage,
        setTourStage,
        setTourStep,
        unblockScroll,
        onFinishTour
    } = useContext(TourContext);

    useEffect(() => {
        if (tourStep === EventsTourSteps.menuItemCampaigns) {
            setTooltipCoordinatesPlacementRight({ current: menuItemCampaignsRef.current, id: 'side-bar-menu-tour-component', config: { right: -12 } })
        }
    }, [menuItemCampaignsRef, tourStep]);

    const menuItemsPartner = {
        events: {
            path: events as MenuPath,
            title: 'My events'
        },
        campaigns: {
            path: campaigns as MenuPath,
            title: 'Campaigns',
            refProp: menuItemCampaignsRef
        },
        leads: {
            path: leads as MenuPath,
            title: 'Leads',
            refProp: menuItemLeadsRef

        },
        analytics: {
            path: analytics as MenuPath,
            title: 'Analytics',
            refProp: menuItemAnalyticsRef

        },
        pro: {
            path: pro as MenuPath,
            title: (
                <div className='flex'>
                    <span>Boost </span>
                    <span className='events-sidebar-menu__boost-pro'>PRO</span>
                </div>
            ),
            refProp: menuItemBoostProRef
        },
    }

    return (
        <main
            className='events-sidebar-menu'
        >
            <div
                className='events-sidebar-menu__controls'
            >
                <div
                    onClick={onLogoClick}
                    className='events-sidebar-menu__logo'
                >
                    <BoostLogo />
                </div>
                <div className='events-sidebar-menu__links'>
                    {isPartner && Object.entries(menuItemsPartner).map(([key, item]) => (
                        <MenuItem key={key} {...item} />
                    ))}
                    {isEvent && Object.entries(menuItemsEvent).map(([key, item]) => (
                        <MenuItem key={key} path={item.path as MenuPath} title={item.title} />
                    ))}
                </div>
            </div>
            {(tourStep > EventsTourSteps.myCampaigns) && (tourStep <= EventsTourSteps.menuItemBoostPro) && tourStage === TourStages.event
                ?
                <NewTourComponent
                    isTransition
                    animationAtStart="slide-to-right"
                    id="side-bar-menu-tour-component"
                    open={(tourStep > EventsTourSteps.myCampaigns) && (tourStep <= EventsTourSteps.menuItemBoostPro) && tourStage === TourStages.event}
                    onClose={() => {
                        onFinishTour()
                    }}
                    onChange={(step) => {
                        setTourStep(step)
                        if (step === EventsTourSteps.menuItemLeads) {
                            setTooltipCoordinatesPlacementRight({ current: menuItemLeadsRef.current, id: 'side-bar-menu-tour-component', config: { right: -12 } })
                        }
                        if (step === EventsTourSteps.menuItemAnalytics) {
                            const element = document.querySelector(`#side-bar-menu-tour-component`)
                            if (element) {
                                (element as HTMLElement).style.top = `227px`;
                            }
                        }
                        if (step === EventsTourSteps.menuItemBoostPro) {
                            setTooltipCoordinatesPlacementRight({ current: menuItemBoostProRef.current, id: 'side-bar-menu-tour-component', config: { right: -12 } })
                        }
                        if (step === EventsTourSteps.myCampaigns) {
                            unblockScroll()
                        }
                        if (step > EventsTourSteps.menuItemBoostPro) {
                            unblockScroll()
                            setTourStage(TourStages.campaign)
                            navigate(pro)
                        }
                    }}
                    animationAtEnd="hide-to-left"
                    current={tourStep}
                    totalCount={7}
                    description={descriptionsEvent[tourStep]}
                    buttonText={tourStep === EventsTourSteps.menuItemBoostPro ? 'More about Boost PRO' : 'Next'}
                />
                :
                null
            }
        </main>
    )
}

export default SideBarMenu

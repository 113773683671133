import React, { useCallback, useState, useMemo, useEffect } from "react";
import { Table, Button, notification } from 'antd';
import emptyCredentialsPng from '@assets/png/emptyCredentials.png'
import './styles.scss'
import { useGetOrganizerCredentialsQuery, usePatchOrganizerCredentialsByIdMutation, usePatchOrganizerCredentialsStatusMutation } from '@store/type-event/credentials/credentials.api';
import InfoIcon from "@icons/header/InfoIcon";
import StatusDescription from "@components/StatusDescription";
import { useNavigate, useParams } from "react-router-dom";
import { OrganizerCredential } from "@store/type-event/credentials/models";
import MenuDotsHor from "@assets/billing/MenuDotsHor";
import CredentialDropdown from "@pages/EventRolePages/AdAccountsPage/components/CredentialDropdown";
import SelectModal from "@pages/EventRolePages/AdAccountsPage/components/SelectModal";
import { useGetFiltersByEnumQuery } from "@store/main/enums/enums.api";
import { CredentialsStatuses } from "@shared/constants";
import { settings } from "@pages/routes.ts";
import { SettingsIds } from "@pages/PartnerRolePages/SettingsPage/constants.ts";
import { CredentialsIcons, CredentialsNames } from "@pages/EventRolePages/AdAccountsPage/constants.tsx";
import { useGetCredentialsChannelsQuery } from '@store/type-partner/credentials/credentials.api.ts';
import AddAccountModal from "@pages/EventRolePages/AdAccountsPage/components/AddAccountModal.tsx";
import { useLocation } from 'react-router-dom';

const AdAccountsPage = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const { accountId = '' } = useParams()

    const [currentAccountId, setCurrentAccountId] = useState(accountId)
    const [isLoading, setIsLoading] = useState(false)
    const [isAddAccountModalOpen, setIsAddAccountModalOpen] = useState(false)

    const { data: credentials, isLoading: isCredentialsLoading, isFetching, refetch: refetchCredentials } = useGetOrganizerCredentialsQuery()
    const [patchCredentials] = usePatchOrganizerCredentialsByIdMutation()
    const [patchCredentialsStatus] = usePatchOrganizerCredentialsStatusMutation()
    const { data: statuses } = useGetFiltersByEnumQuery({ name: 'credential_status' })
    const { data: credentialsChannelRes, isLoading: isCredentialsChannelLoading, isFetching: isCredentialsChannelFetching } = useGetCredentialsChannelsQuery()

    const selectedData = useMemo(() => {
        const foundData = credentials?.data?.find(({ id }) => accountId === id.toString())
        let field = ''
        Object.entries(foundData?.data || {}).forEach(([key, value]) => {
            if (value?.list) {
                field = key
            }
        })
        return {
            ...foundData,
            field
        }
    }, [accountId, credentials?.data])

    const onSelectAccount = useCallback(async ({ accountValue, credentialId, name, field }: { accountValue: { id: number }, credentialId: number, name: string | null, field: string }) => {
        try {
            setIsLoading(true)
            const response = await patchCredentials({
                accountValue,
                credentialId,
                field
            });
            if ('error' in response) {
                setIsLoading(false)
                return notification.open({
                    type: 'error',
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                    content: response?.error?.data?.message || 'OOOPS, something is wrong',
                    placement: 'bottomLeft',
                    closeIcon: false
                });
            }
            setCurrentAccountId('')
            setIsLoading(false)
            notification.open({
                message: (
                    <span className='moderation-table__notification__message'>
                        Google Ads
                        {' '}
                        {name ? (
                            <b>
                                Account ID:
                                {name}
                            </b>
                        ) : null
                        }
                        {' '}
                        has been successfully added
                    </span>
                ),
                placement: 'bottomLeft',
                closeIcon: false,
                type: 'success'
            });
            await refetchCredentials()
            return navigate(`${settings}/${SettingsIds.adAccounts}`)
        } catch (error) {
            setIsLoading(false)
            return notification.open({
                type: 'error',
                content: 'OOOPS, something is wrong',
                placement: 'bottomLeft',
                closeIcon: false
            });
        }
    }, [navigate, patchCredentials, refetchCredentials])

    const onRevoke = useCallback(async (credentialId: number) => {
        try {
            setIsLoading(true)
            const statusId = statuses?.data?.find(({ slug }) => slug === 'revoked')?.id
            const response = await patchCredentialsStatus({
                statusId,
                credentialId
            });
            if ('error' in response) {
                setIsLoading(false)
                return notification.open({
                    type: 'error',
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                    content: response?.error?.data?.message || 'OOOPS, something is wrong',
                    placement: 'bottomLeft',
                    closeIcon: false
                });
            }
            setIsLoading(false)
            notification.open({
                message: (
                    <span className='moderation-table__notification__message'>
                        Google Ads
                        {' '}
                        <b>
                            Account ID:
                            {credentialId}
                        </b>
                        {' '}
                        has been successfully revoked
                    </span>
                ),
                placement: 'bottomLeft',
                closeIcon: false,
                type: 'success'
            });
            return refetchCredentials()
        } catch (error) {
            setIsLoading(false)
            return notification.open({
                type: 'error',
                content: 'OOOPS, something is wrong',
                placement: 'bottomLeft',
                closeIcon: false
            });
        }
    }, [patchCredentialsStatus, refetchCredentials, statuses?.data])

    const onCloseSelectModal = useCallback(() => {
        setCurrentAccountId('')
        const queryParams = new URLSearchParams(location.search);
        navigate(`${settings}/${SettingsIds.adAccounts}?${queryParams.toString()}`)
    }, [navigate])

    const columns = [
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: '16%',
            render: (status: OrganizerCredential['status']) => {
                return (
                    <div>
                        <StatusDescription status={status?.name} />
                    </div>
                )
            }
        },
        {
            title: 'Ad Platform',
            dataIndex: 'channel',
            key: 'channel',
            width: '24%',
            render: (channel: OrganizerCredential['channel']) => {
                return (
                    <div className='flex gap-6 ad-accounts-page__avatar-wrapper'>
                        {channel?.name ? CredentialsIcons[channel.name] : null}
                        <span>{channel?.name ? CredentialsNames[channel.name] : null}</span>
                    </div>
                )
            }
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: '30%',
        },
        {
            title: 'Impersonated email',
            dataIndex: 'impersonated_email',
            key: 'impersonated_email',
            width: '25%',
        },
        {
            title: '',
            dataIndex: 'id',
            key: 'dropdown',
            width: '8%',
            fixed: 'right',
            render: (id: number, row: OrganizerCredential) => {
                return (
                    row.status.name === CredentialsStatuses.revoked ? null : (
                        <div>
                            {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
                            <CredentialDropdown onRevoke={() => onRevoke(id)}>
                                <div onClick={(e) => e.preventDefault()}>
                                    <MenuDotsHor />
                                </div>
                            </CredentialDropdown>
                        </div>
                    )
                )
            }
        },
    ];

    const onAddAccountClick = () => {
        if (credentialsChannelRes?.data?.length) {
            setIsAddAccountModalOpen(true)
        } else {
            navigate('/api/credentials/google/redirect?channel=google_ads')
        }
    }

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const message = queryParams.get('message') || '';
        if (message) {
            notification.open({
                key: '1',
                type: 'error',
                message: '',
                description: message,
                placement: 'top',
                onClose: () => {
                    navigate(`${settings}/${SettingsIds.adAccounts}`)
                }
            });
        }
    }, []);

    return (
        <>
            <article className='ad-accounts-page'>
                <div className='ad-accounts-page__header'>
                    <div className='ad-accounts-page__header-title'>
                        <div>Advertising Accounts</div>
                        <div className='cursor-pointer'>
                            <InfoIcon fillColor="#252628" width={18} height={18} />
                        </div>
                    </div>
                    {Boolean(credentials?.data?.length) && (
                        <Button
                            type='primary'
                            onClick={onAddAccountClick}
                        >
                            + Add account
                        </Button>
                    )}
                </div>
                <Table
                    className='ad-accounts-page__credentials-table'
                    pagination={false}
                    loading={isCredentialsLoading}
                    dataSource={credentials?.data}
                    columns={credentials?.data?.length ? columns : []}
                    rowKey="id"
                    scroll={{
                        x: true,
                    }}
                    locale={{
                        emptyText: !isCredentialsLoading ? (
                            <div className='ad-accounts-page__empty-credentials'>
                                <img src={emptyCredentialsPng} alt='emptyCredentialsPng' />
                                <span><b>You have no Ad Accounts</b></span>
                                <span>To add one of them, click on the button below</span>
                                <Button
                                    type='primary'
                                    onClick={onAddAccountClick}
                                >
                                    + Add account
                                </Button>
                            </div>
                        ) : ' '
                    }}
                />
            </article>

            <SelectModal
                isOpen={Boolean(currentAccountId)}
                selectedData={selectedData}
                onClose={onCloseSelectModal}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onSelectAccount={onSelectAccount}
                isLoading={isLoading}
                isCredentialsLoading={isFetching || isCredentialsLoading}
            />
            <AddAccountModal
                isOpen={isAddAccountModalOpen}
                onClose={() => setIsAddAccountModalOpen(false)}
                credentials={credentialsChannelRes?.data || []}
                isCredentialsLoading={isCredentialsChannelLoading || isCredentialsChannelFetching}
            />
        </>
    )
}

export default AdAccountsPage

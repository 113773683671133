import React, { useState } from 'react';
import { Input, DatePicker, Drawer, Badge, Button } from 'antd';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { MyEventsFilters } from '@pages/EventRolePages/EventPage';
import '../styles.scss'
import FilterButtonMobileIcon from '@assets/buttons/mobile/FilterButtonMobileIcon';
import { Link } from 'react-router-dom';
import useSwipeDown from '@hooks/useSwipeDown';
import CustomButton from '@components/CustomButton';
import RadioTable from '../../RadioTable';
import MobileDrawer from '@components/mobile/Drawer';
interface Props {
    filters: MyEventsFilters;
    // statuses: FilterByEnumItem[] | undefined;
    searchValue: string;
    isTableView: boolean;
    setTableView: (isTableView: boolean) => void;
    onFilterUpdate: (updatedFilters: Partial<MyEventsFilters>) => void;
    onResetFilters: () => void;
    debouncedOnSearchUpdate: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const EventsCardsFilterMobile: React.FC<Props> = ({
    filters,
    // statuses,
    searchValue,
    isTableView,
    setTableView,
    onFilterUpdate,
    onResetFilters,
    debouncedOnSearchUpdate,
}) => {

    const [isShowFilters, setShowFilters] = useState(false)
    const [drawerFilters, setDrawerFilters] = useState<Partial<MyEventsFilters>>(filters)

    const onCloseFilters = () => {
        setShowFilters(false)
    }
    const { onTouchStart, onTouchEnd } = useSwipeDown(onCloseFilters);

    const isFilterApplied = searchValue.length > 3 || filters.date.from || filters.date.to;

    const disabledStartDate = (current: dayjs.Dayjs) => {
        return !!drawerFilters?.date?.to && current > dayjs(drawerFilters.date.to);
    };

    const disabledEndDate = (current: dayjs.Dayjs) => {
        return !!drawerFilters?.date?.from && current < dayjs(drawerFilters.date.from);
    };

    const onDrawerFilterChange = () => {
        onFilterUpdate(drawerFilters)
        setShowFilters(false)
    }

    const onDrawerResetClick = () => {
        onResetFilters();
        setDrawerFilters(filters)
        setShowFilters(false)
    }

    return (
        <>
            <div className='flex items-center justify-space-between'>
                <h3>
                    My events
                </h3>
                <div className='flex items-center'>
                    <div className='mx-8'>
                        <RadioTable
                            isTableView={isTableView}
                            setTableView={setTableView}
                        />
                    </div>
                    <Link to='setup' className='no-style'>
                        <CustomButton
                            title='+Add event'
                            type='primary'
                        />
                    </Link>
                </div>
            </div>
            <div className='my-event-page-mobile__filter-container'>
                <Input
                    className='my-event-page-mobile__filter-text'
                    allowClear
                    placeholder="Search by keywords"
                    prefix={<SearchOutlined />}
                    onChange={debouncedOnSearchUpdate}
                />
                <button
                    className='no-style'
                    onClick={() => setShowFilters(true)}
                >
                    <Badge
                        dot={!!isFilterApplied}
                        status="processing"

                    >
                        <FilterButtonMobileIcon
                            isActive={!!isFilterApplied}
                        />
                    </Badge>
                </button>


                <MobileDrawer
                    isOpen={isShowFilters}
                    onClose={onCloseFilters}
                    onCancel={onDrawerResetClick}
                    title="Filters"
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onOk={onDrawerFilterChange}
                    applyButtonText="Enable PRO"
                    resetButtonText="Cancel"
                // isLoading={isLoading}
                >
                    <div
                        onTouchStart={onTouchStart}
                        onTouchEnd={onTouchEnd}
                        className='my-event-page-mobile-drawer__filter-range'
                    >
                        <p>Date</p>
                        <div className='flex'>
                            <DatePicker
                                format="YYYY-MM-DD"
                                value={drawerFilters?.date?.from ? dayjs(drawerFilters.date.from) : null}
                                className='campaign-create__budget-form-date'
                                onChange={(_, i) => setDrawerFilters({ date: { from: i as unknown as string, to: drawerFilters.date?.to || '' } })}
                                placeholder='Start Date'
                                disabledDate={disabledStartDate}
                                allowClear
                            />
                            <DatePicker
                                format="YYYY-MM-DD"
                                value={drawerFilters?.date?.to ? dayjs(drawerFilters.date.to) : null}
                                className='campaign-create__budget-form-date'
                                onChange={(_, i) => setDrawerFilters({ date: { to: i as unknown as string, from: drawerFilters.date?.from || '' } })}
                                placeholder='Finish Date'
                                disabledDate={disabledEndDate}
                                allowClear
                            />
                        </div>
                    </div>
                    {/* {statuses && (
                        <div className='my-event-page-mobile-drawer__filter-status'>
                            <p>Status</p>
                            <Select
                                style={{ width: '100%' }}
                                mode="multiple"
                                allowClear
                                placeholder="All"
                                value={filters.statuses}
                                onChange={(statuses) => onFilterUpdate({ statuses })}
                                maxTagCount={1}
                                options={statusesFormatter(statuses || [])}
                            />
                        </div>
                    )} */}
                </MobileDrawer>
            </div>
        </>
    );
};

export default EventsCardsFilterMobile;

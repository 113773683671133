import { createContext, useState, ReactNode } from 'react';
import { EventFormErrors } from './models';
import { initialEventErrors } from './constants';
import { useNavigate, useParams } from 'react-router-dom';
import { message } from 'antd';
import { ErrorDataResponse, errorsToFormAdapter } from '@shared/utils';
import { usePatchOrganizerEventByIdMutation, usePostCreateOrganizerEventMutation } from '@store/type-event/events/events.api';
import { CreateOrganizerEventRequestBody } from '@store/type-event/events/models';
import sendAnalytics from "@hooks/sendAnalytics/sendAnalytics";
import { GTMEventName } from "@hooks/sendAnalytics/constants";
import { useAuth } from "@contexts/AuthContext.tsx";


interface EventEditorContextType {
    errors: EventFormErrors;
    setErrors: (errors: EventFormErrors) => void;
    resetErrors: () => void;
    resetErrorField: (errorField: Record<string, string>) => void;
    isUpdating: boolean;
    setUpdating: (isUpdating: boolean) => void;
    onSubmit: (eventData: CreateOrganizerEventRequestBody) => void
}

export const EventEditorContext = createContext<EventEditorContextType>({
    errors: initialEventErrors,
    setErrors: () => { initialEventErrors },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    resetErrors: () => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    resetErrorField: () => { },
    isUpdating: false,
    setUpdating: () => { false },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
});

interface EventEditorContextProviderProps {
    children: ReactNode;
}

export const EventEditorContextProvider: React.FC<EventEditorContextProviderProps> = ({ children }) => {
    const { eventId = '' } = useParams()
    const navigate = useNavigate()
    const [patchEvent] = usePatchOrganizerEventByIdMutation()
    const [createEvent] = usePostCreateOrganizerEventMutation()

    const [errors, setErrors] = useState<EventFormErrors>(initialEventErrors);
    const [isUpdating, setUpdating] = useState(false)
    const { reFetchUser } = useAuth();

    const onSubmit = async (eventData: CreateOrganizerEventRequestBody, isEventInfoStep?: boolean) => {
        setUpdating(true)
        try {
            const response = eventId
                ?
                await patchEvent({ eventId, event: eventData })
                :
                await createEvent(eventData)

            if ('error' in response) {
                setUpdating(false)
                const errors = errorsToFormAdapter(response as ErrorDataResponse)
                setErrors(errors as unknown as EventFormErrors)

                if (!Object.values(errors || {})?.length) {
                    const err = response as unknown as ErrorDataResponse
                    message.open({
                        type: 'error',
                        content: err?.error?.data?.message || 'OOOPS, something is wrong',
                    });
                }
            }

            if ('data' in response) {
                if (!eventId) {
                    sendAnalytics(GTMEventName.saveEvent)
                }
                if (isEventInfoStep) {
                    sendAnalytics(GTMEventName.eventOnboardingSecondStep)
                }
                setUpdating(false)
                void message.open({
                    type: 'success',
                    content: eventId ? 'Event Updated' : 'Event Created',
                });
                return await reFetchUser()
                    .then(() => {
                        if (eventId) {
                            return navigate(`/events/info/${eventId}/general`)
                        }
                        return navigate(`/events`)
                    })
                    .catch(() => {
                        return message.open({
                            type: 'error',
                            content: 'Event Update error',
                        });
                    })
            }
        } catch (error) {
            setUpdating(false)
            console.log('SMTH WRONG');
        }
    };

    const resetErrors = () => {
        setErrors(initialEventErrors)
    }

    const resetErrorField = (errorField: Record<string, string>) => {
        setErrors((prev) => ({ ...prev, ...errorField }))
    }


    return (
        <EventEditorContext.Provider value={{
            errors,
            setErrors,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-misused-promises
            onSubmit,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            isUpdating,
            setUpdating,
            resetErrors,
            resetErrorField
        }}>
            {children}
        </EventEditorContext.Provider>
    );
};

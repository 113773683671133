import React, { useContext, useState } from 'react'
import { Input, Segmented, Select, Tooltip } from 'antd';
import './styles.scss'
import {
    ButtonType,
    CardType,
} from '@pages/PartnerRolePages/LandingPageConstructor/models';
import { LandingEditorContext } from '@contexts/LandingEditorContext';
import { OptionName, imageAction, OptionIds } from '@pages/PartnerRolePages/LandingPageConstructor/constants';
import { PartnerLandingSectionLayout } from '@store/type-partner/landings/models';
import SettingsImageUploader from "@pages/PartnerRolePages/LandingPageConstructor/components/SettingsImageUploader";
import CustomColorPicker
    from "@pages/PartnerRolePages/LandingPageConstructor/components/CustomColorPicker/CustomColorPicker.tsx";
import InfoIcon from "@icons/header/InfoIcon.tsx";
import CornerIcon from "@assets/lpEditor/CornerIcon.tsx";
import {
    opacityOptions, segmentedOptions,
    SegmentedValues
} from "@pages/PartnerRolePages/LandingPageConstructor/components/drawers/settings/constants.tsx";
import ArrowIcon from "@assets/ArrowIcon.tsx";
import CardsOption from "@pages/PartnerRolePages/LandingPageConstructor/components/CardsOption/CardsOption.tsx";

type Props = {
    id: OptionIds;
    layout: PartnerLandingSectionLayout;
}

const SettingsItem: React.FC<Props> = ({
    id,
    layout,
}) => {
    const { updateSectionOptionById } = useContext(LandingEditorContext);

    const [imageError, setImageError] = useState(false)
    const [backgroundSegmentedValue, setBackgroundSegmentedValue] = useState(layout.options[id]?.backgroundColor ? SegmentedValues.color : SegmentedValues.image)

    const onOptionValueChange = (value: string | string[] | { card: CardType[] } | { button: ButtonType[] } | { image: string; }[], key?: string) => {
        updateSectionOptionById({
            name: id,
            value,
            key,
        })
        setImageError(false)
    }

    const onChangeImagesListUrl = ({ url, idx }: { url: string, idx: number }) => {
        if (!layout?.options?.[id]) return null
        const newImages = layout.options.CornerImagesComponent?.image.map((item, index) => {
            return index === idx ? { image: url } : item
        }) || []
        onOptionValueChange(newImages, 'image')
    };

    const onButtonsValueChange = (value: string, idx: number, key: string) => {
        const newButtons = layout.options.ButtonsComponent?.button.map((item, index) => {
            return index === idx ? { ...item, [key]: value } : item
        }) || []
        onOptionValueChange({ button: newButtons }, key)
    }

    return (
        <>
        <div className='setting-drawer__type-label'>{OptionName[id] || ''}</div>
        <div className="mb-20">
            {id === OptionIds.ButtonComponent && (
                <div className="flex-col gap-10">
                    <div>
                        <div className='setting-drawer__type-subtitle'>Link</div>
                        <Input
                            className="setting-drawer__text-input"
                            placeholder='https://www.thesummit2024.com'
                            value={layout.options.ButtonComponent?.link}
                            onChange={e => onOptionValueChange(e.target.value, 'link')}
                        />
                    </div>
                    <div>
                        <div className='setting-drawer__type-subtitle'>Text color</div>
                        <CustomColorPicker
                            value={layout?.options?.[id]?.textColor}
                            defaultValue={layout?.options?.[id]?.textColor || 'rgb(0, 0, 0, 100%)'}
                            showText
                            onChangeComplete={(color) => {
                                onOptionValueChange(color, 'textColor')
                            }}
                        />
                    </div>
                    <div className="flex gap-10">
                        <div className="w50p">
                            <div className='setting-drawer__type-subtitle'>Background color</div>
                            <CustomColorPicker
                                value={layout?.options?.[id]?.backgroundColor}
                                defaultValue={layout?.options?.[id]?.backgroundColor || 'rgb(0, 0, 0, 100%)'}
                                showText
                                onChangeComplete={(color) => {
                                    onOptionValueChange(color, 'backgroundColor')
                                }}
                            />
                        </div>
                        <div className="w50p">
                            <div className='setting-drawer__type-subtitle'>Stroke color</div>
                            <CustomColorPicker
                                value={layout?.options?.[id]?.strokeColor}
                                defaultValue={layout?.options?.[id]?.strokeColor || 'rgb(0, 0, 0, 100%)'}
                                showText
                                onChangeComplete={(color) => {
                                    onOptionValueChange(color, 'strokeColor')
                                }}
                            />
                        </div>
                    </div>
                    <div>
                        <div className='setting-drawer__type-subtitle'>Text color on hover</div>
                        <CustomColorPicker
                            value={layout?.options?.[id]?.textColorOnHover}
                            defaultValue={layout?.options?.[id]?.textColorOnHover || 'rgb(0, 0, 0, 100%)'}
                            showText
                            onChangeComplete={(color) => {
                                onOptionValueChange(color, 'textColorOnHover')
                            }}
                        />
                    </div>
                    <div className="flex gap-10">
                        <div className="w50p">
                            <div className='setting-drawer__type-subtitle'>Background color on hover</div>
                            <CustomColorPicker
                                value={layout?.options?.[id]?.backgroundColorOnHover}
                                defaultValue={layout?.options?.[id]?.backgroundColorOnHover || 'rgb(0, 0, 0, 100%)'}
                                showText
                                onChangeComplete={(color) => {
                                    onOptionValueChange(color, 'backgroundColorOnHover')
                                }}
                            />
                        </div>
                        <div className="w50p">
                            <div className='setting-drawer__type-subtitle'>Stroke color on hover</div>
                            <CustomColorPicker
                                value={layout?.options?.[id]?.strokeColorOnHover}
                                defaultValue={layout?.options?.[id]?.strokeColorOnHover || 'rgb(0, 0, 0, 100%)'}
                                showText
                                onChangeComplete={(color) => {
                                    onOptionValueChange(color, 'strokeColorOnHover')
                                }}
                            />
                        </div>
                    </div>
                    <div>
                        <div className="flex">
                            <div className='setting-drawer__type-subtitle'>Corner radius</div>
                            <Tooltip
                                overlayClassName='tooltip-general tooltip-small'
                                title="Button corner radius is automatically applied to buttons in other sections for consistency"
                                placement="bottom"
                            >
                                <div className='cursor-pointer ml-4'>
                                    <InfoIcon fillColor="#57585A" width={18} height={18}/>
                                </div>
                            </Tooltip>
                        </div>
                        <Input
                            prefix={<CornerIcon/>}
                            className="setting-drawer__text-input"
                            placeholder='0 px'
                            value={layout.options.ButtonComponent?.cornerRadius || ''}
                            onChange={e => {
                                const inputValue = e.target.value
                                onOptionValueChange(inputValue, 'cornerRadius')
                            }}
                        />
                    </div>
                </div>
            )}
            {id === OptionIds.TextComponent && (
                <div className="flex-col gap-10">
                    <div>
                        <div className='setting-drawer__type-subtitle'>Base color</div>
                        <CustomColorPicker
                            value={layout?.options?.TextComponent?.textColor}
                            defaultValue={layout?.options?.TextComponent?.textColor || 'rgb(0, 0, 0, 100%)'}
                            showText
                            onChangeComplete={(color) => {
                                onOptionValueChange(color, 'textColor')
                            }}
                        />
                    </div>
                    <div>
                        <div className='setting-drawer__type-subtitle'>Corner radius</div>
                        <Input
                            prefix={<CornerIcon/>}
                            className="setting-drawer__text-input"
                            placeholder='0 px'
                            value={layout.options.TextComponent?.cornerRadius || ''}
                            onChange={e => {
                                const inputValue = e.target.value
                                onOptionValueChange(inputValue, 'cornerRadius')
                            }}
                        />
                    </div>
                </div>

            )}
            {id === OptionIds.BackgroundColorComponent && (
                <CustomColorPicker
                    value={layout?.options?.BackgroundColorComponent?.backgroundColor}
                    defaultValue={layout?.options?.BackgroundColorComponent?.backgroundColor || 'rgb(0, 0, 0, 100%)'}
                    showText
                    onChangeComplete={(color) => {
                        onOptionValueChange(color, 'backgroundColor')
                    }}
                />
            )}
            {id === OptionIds.BackgroundImageComponent && (
                <div
                    className={`setting-drawer__background-type__image ${imageError ? 'setting-drawer__background-type__image-error' : ''}`}
                >
                    <SettingsImageUploader
                        action={imageAction}
                        onChange={onOptionValueChange}
                        url={layout.options.BackgroundImageComponent?.value || ''}
                        errorCb={() => {
                            setImageError(true)
                        }}
                    />
                </div>
            )}
            {id === OptionIds.BackgroundComponent && (
                <>
                    <Segmented
                        defaultValue={layout.options.BackgroundComponent?.backgroundColor ? SegmentedValues.color : SegmentedValues.image}
                        value={backgroundSegmentedValue}
                        onChange={setBackgroundSegmentedValue}
                        options={segmentedOptions}
                    />
                    {backgroundSegmentedValue === SegmentedValues.color ? (
                        <>
                            <div className='setting-drawer__type-subtitle'>Background color</div>
                            <CustomColorPicker
                                value={layout?.options?.BackgroundComponent?.backgroundColor}
                                defaultValue={layout?.options?.BackgroundComponent?.backgroundColor || 'rgb(0, 0, 0, 100%)'}
                                showText
                                onChangeComplete={(color) => {
                                    onOptionValueChange(color, 'backgroundColor')
                                }}
                            />
                        </>
                    ) : (
                        <>
                            <div
                                className={`setting-drawer__background-type__image ${imageError ? 'setting-drawer__background-type__image-error' : ''}`}
                            >
                                <SettingsImageUploader
                                    action={imageAction}
                                    onChange={(newValue) => {
                                        onOptionValueChange(newValue, 'backgroundImage')
                                    }}
                                    url={layout.options.BackgroundComponent?.backgroundImage || ''}
                                    errorCb={() => {
                                        setImageError(true)
                                    }}
                                />
                            </div>
                            {layout.options?.[id]?.overlay && (
                                <div className="flex gap-10">
                                    <div className="w50p">
                                        <div className='setting-drawer__type-subtitle'>Overlay</div>
                                        <CustomColorPicker
                                            value={layout.options?.[id]?.overlay}
                                            defaultValue={layout.options?.[id]?.overlay || 'rgb(0, 0, 0, 100%)'}
                                            showText
                                            onChangeComplete={(color) => {
                                                onOptionValueChange(color, 'overlay')
                                            }}
                                        />
                                    </div>
                                    <div className="w50p">
                                        <div className='setting-drawer__type-subtitle'>Opacity</div>
                                        <Select
                                            suffixIcon={(
                                                <ArrowIcon
                                                    fillColor="#C0C1C3"
                                                    width="20"
                                                    height="20"
                                                    className="common-select-icon"
                                                />
                                            )}
                                            className="setting-drawer__type-select"
                                            value={layout?.options?.[id]?.opacity || opacityOptions[0]}
                                            options={opacityOptions}
                                            onChange={(item) => {
                                                onOptionValueChange(typeof item === 'string' ? item : item?.value, 'opacity')
                                            }}
                                        />
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </>
            )}
            {id === OptionIds.ImageComponent && (
                <div className="flex-col gap-10">
                    <div
                        className={`setting-drawer__background-type__image ${imageError ? 'setting-drawer__background-type__image-error' : ''}`}
                    >
                        <SettingsImageUploader
                            action={imageAction}
                            onChange={(newValue) => onOptionValueChange(newValue, 'image')}
                            url={layout.options.ImageComponent?.image || ''}
                            errorCb={() => {
                                setImageError(true)
                            }}
                        />
                    </div>
                        <div className="flex gap-10">
                            <div className="w50p">
                                <div className='setting-drawer__type-subtitle'>Overlay</div>
                                <CustomColorPicker
                                    value={layout?.options?.ImageComponent?.overlay}
                                    defaultValue={layout?.options?.ImageComponent?.overlay || 'rgb(0, 0, 0, 100%)'}
                                    showText
                                    onChangeComplete={(color) => {
                                        onOptionValueChange(color, 'overlay')
                                    }}
                                />
                            </div>
                            <div className="w50p">
                                <div className='setting-drawer__type-subtitle'>Opacity</div>
                                <Select
                                    suffixIcon={(
                                        <ArrowIcon
                                            fillColor="#C0C1C3"
                                            width="20"
                                            height="20"
                                            className="common-select-icon"
                                        />
                                    )}
                                    className="setting-drawer__type-select"
                                    value={layout?.options?.ImageComponent?.opacity || opacityOptions[0]}
                                    options={opacityOptions}
                                    onChange={(item) => {
                                        onOptionValueChange(typeof item === 'string' ? item : item?.value, 'opacity')
                                    }}
                                />
                            </div>
                        </div>
                        <div>
                            <div className='setting-drawer__type-subtitle'>Corner radius</div>
                            <Input
                                prefix={<CornerIcon/>}
                                className="setting-drawer__text-input"
                                placeholder='0 px'
                                value={layout.options?.[id]?.cornerRadius || ''}
                                onChange={e => {
                                    const inputValue = e.target.value
                                    onOptionValueChange(inputValue, 'cornerRadius')
                                }}
                            />
                        </div>
                </div>
            )}
            {id === OptionIds.FullBackgroundImageComponent && (
                <div className="flex-col gap-10">
                    <div
                        className={`setting-drawer__background-type__image ${imageError ? 'setting-drawer__background-type__image-error' : ''}`}
                    >
                        <SettingsImageUploader
                            action={imageAction}
                            onChange={(newValue) => onOptionValueChange(newValue, 'backgroundImage')}
                            url={layout.options.FullBackgroundImageComponent?.backgroundImage || ''}
                            errorCb={() => {
                                setImageError(true)
                            }}
                        />
                    </div>
                    <div className="flex gap-10">
                        <div className="w50p">
                            <div className='setting-drawer__type-subtitle'>Overlay</div>
                            <CustomColorPicker
                                value={layout?.options?.FullBackgroundImageComponent?.overlay}
                                defaultValue={layout?.options?.FullBackgroundImageComponent?.overlay || 'rgb(0, 0, 0, 100%)'}
                                showText
                                onChangeComplete={(color) => {
                                    onOptionValueChange(color, 'overlay')
                                }}
                            />
                        </div>
                        <div className="w50p">
                            <div className='setting-drawer__type-subtitle'>Opacity</div>
                            <Select
                                suffixIcon={(
                                    <ArrowIcon
                                        fillColor="#C0C1C3"
                                        width="20"
                                        height="20"
                                        className="common-select-icon"
                                    />
                                )}
                                className="setting-drawer__type-select"
                                value={layout?.options?.FullBackgroundImageComponent?.backgroundOpacity || opacityOptions[0]}
                                options={opacityOptions}
                                onChange={(item) => {
                                    onOptionValueChange(typeof item === 'string' ? item : item?.value, 'backgroundOpacity')
                                }}
                            />
                        </div>
                    </div>
                </div>
            )}
            {id === OptionIds.CornerImageComponent && (
                <div className="flex-col gap-10">
                    <div
                        className={`setting-drawer__background-type__image ${imageError ? 'setting-drawer__background-type__image-error' : ''}`}
                    >
                        <SettingsImageUploader
                            action={imageAction}
                            onChange={(newValue) => onOptionValueChange(newValue, 'image')}
                            url={layout.options.CornerImageComponent?.image || ''}
                            errorCb={() => {
                                setImageError(true)
                            }}
                        />
                    </div>
                    <div>
                        <div className='setting-drawer__type-subtitle'>Corner radius</div>
                        <Input
                            prefix={<CornerIcon/>}
                            className="setting-drawer__text-input"
                            placeholder='0 px'
                            value={layout.options?.[id]?.cornerRadius || ''}
                            onChange={e => {
                                const inputValue = e.target.value
                                onOptionValueChange(inputValue, 'cornerRadius')
                            }}
                        />
                    </div>
                </div>
            )}
            {id === OptionIds.ImagesComponent && (
                <div className="flex-col gap-10">
                    <div className="flex-col">
                        {layout.options.ImagesComponent && layout.options.ImagesComponent.map(({image}, idx) => {
                            return (
                                <div
                                    key={idx}
                                    className={`setting-drawer__background-type__image ${imageError ? 'setting-drawer__background-type__image-error' : ''}`}
                                >
                                    <SettingsImageUploader
                                        index={idx + 1}
                                        action={imageAction}
                                        onChange={(url) => onChangeImagesListUrl({url, idx})}
                                        url={image || ''}
                                        errorCb={() => {
                                            setImageError(true)
                                        }}
                                    />
                                </div>
                            )
                        })}
                    </div>
                        <div>
                            <div className="flex">
                                <div className='setting-drawer__type-subtitle'>Corner radius</div>
                                <Tooltip
                                    overlayClassName='tooltip-general tooltip-small'
                                    title="Button corner radius is automatically applied to buttons in other sections for consistency"
                                    placement="bottom"
                                >
                                    <div className='cursor-pointer ml-4'>
                                        <InfoIcon fillColor="#57585A" width={18} height={18}/>
                                    </div>
                                </Tooltip>
                            </div>
                            <Input
                                prefix={<CornerIcon/>}
                                className="setting-drawer__text-input"
                                placeholder='0 px'
                                value={layout.options.ImagesComponent?.[0].cornerRadius || ''}
                                onChange={e => {
                                    const inputValue = e.target.value
                                    onOptionValueChange(inputValue, 'cornerRadius')
                                }}
                            />
                        </div>
                </div>
            )}
            {Boolean(id === OptionIds.IconsComponent && layout.options.IconsComponent?.value?.length) && (
                <div className="flex-col">
                    {layout.options.IconsComponent?.value.map((url, idx) => {
                        return (
                            <div
                                key={idx}
                                className={`setting-drawer__background-type__image ${imageError ? 'setting-drawer__background-type__image-error' : ''}`}
                            >
                                <SettingsImageUploader
                                    index={idx + 1}
                                    name="icon"
                                    action={imageAction}
                                    onChange={(url) => onChangeImagesListUrl({url, idx})}
                                    url={url || ''}
                                    errorCb={() => {
                                        setImageError(true)
                                    }}
                                />
                            </div>
                        )
                    })}
                </div>
            )}
            {id === OptionIds.VideoComponent && (
                <div className="flex-col gap-10">
                    <div>
                        <div className='setting-drawer__type-subtitle'>Link to youtube video</div>
                        <Input
                            placeholder='Video URL'
                            value={layout.options.VideoComponent?.video || ''}
                            onChange={e => onOptionValueChange(e.target.value, 'video')}
                        />
                    </div>

                    <div className='setting-drawer__type-subtitle'>Video cover (optional)</div>
                    <div
                        className={`setting-drawer__background-type__image ${imageError ? 'setting-drawer__background-type__image-error' : ''}`}
                    >
                        <SettingsImageUploader
                            action={imageAction}
                            onChange={(newValue) => onOptionValueChange(newValue, 'videoCover')}
                            url={layout.options.VideoComponent?.videoCover || ''}
                            errorCb={() => {
                                setImageError(true)
                            }}
                        />
                    </div>

                    <div className="flex gap-10">
                        <div className="w50p">
                            <div className='setting-drawer__type-subtitle'>Overlay</div>
                            <CustomColorPicker
                                value={layout?.options?.VideoComponent?.videoOverlay}
                                defaultValue={layout?.options?.VideoComponent?.videoOverlay || 'rgb(0, 0, 0, 100%)'}
                                showText
                                onChangeComplete={(color) => {
                                    onOptionValueChange(color, 'videoOverlay')
                                }}
                            />
                        </div>
                        <div className="w50p">
                            <div className='setting-drawer__type-subtitle'>Opacity</div>
                            <Select
                                suffixIcon={(
                                    <ArrowIcon
                                        fillColor="#C0C1C3"
                                        width="20"
                                        height="20"
                                        className="common-select-icon"
                                    />
                                )}
                                className="setting-drawer__type-select"
                                value={layout?.options?.VideoComponent?.videoOpacity || opacityOptions[0]}
                                options={opacityOptions}
                                onChange={(item) => {
                                    onOptionValueChange(typeof item === 'string' ? item : item?.value, 'videoOpacity')
                                }}
                            />
                        </div>
                    </div>

                        <div>
                            <div className='setting-drawer__type-subtitle'>Corner radius</div>
                            <Input
                                prefix={<CornerIcon/>}
                                className="setting-drawer__text-input"
                                placeholder='0 px'
                                value={layout.options?.[id]?.cornerRadius || ''}
                                onChange={e => {
                                    const inputValue = e.target.value
                                    onOptionValueChange(inputValue, 'cornerRadius')
                                }}
                            />
                        </div>
                </div>
            )}

            {id === OptionIds.PlayButtonComponent && (
                <div className="flex-col gap-10">
                    <div className="flex gap-10">
                        <div className="w50p">
                            <div className='setting-drawer__type-subtitle'>Background color</div>
                            <CustomColorPicker
                                value={layout?.options?.PlayButtonComponent?.iconBackgroundColor}
                                defaultValue={layout?.options?.PlayButtonComponent?.iconBackgroundColor || 'rgb(0, 0, 0, 100%)'}
                                showText
                                onChangeComplete={(color) => {
                                    onOptionValueChange(color, 'iconBackgroundColor')
                                }}
                            />
                        </div>
                        <div className="w50p">
                            <div className='setting-drawer__type-subtitle'>Icon color</div>
                            <CustomColorPicker
                                value={layout?.options?.PlayButtonComponent?.iconColor}
                                defaultValue={layout?.options?.PlayButtonComponent?.iconColor || 'rgb(0, 0, 0, 100%)'}
                                showText
                                onChangeComplete={(color) => {
                                    onOptionValueChange(color, 'iconColor')
                                }}
                            />
                        </div>
                    </div>
                    <div className="flex gap-10">
                        <div className="w50p">
                            <div className='setting-drawer__type-subtitle'>Background color on hover</div>
                            <CustomColorPicker
                                value={layout?.options?.PlayButtonComponent?.iconBackgroundColorOnHover}
                                defaultValue={layout?.options?.PlayButtonComponent?.iconBackgroundColorOnHover || 'rgb(0, 0, 0, 100%)'}
                                showText
                                onChangeComplete={(color) => {
                                    onOptionValueChange(color, 'iconBackgroundColorOnHover')
                                }}
                            />
                        </div>
                        <div className="w50p">
                            <div className='setting-drawer__type-subtitle'>Icon color on hover</div>
                            <CustomColorPicker
                                value={layout?.options?.PlayButtonComponent?.iconColorOnHover}
                                defaultValue={layout?.options?.PlayButtonComponent?.iconColorOnHover || 'rgb(0, 0, 0, 100%)'}
                                showText
                                onChangeComplete={(color) => {
                                    onOptionValueChange(color, 'iconColorOnHover')
                                }}
                            />
                        </div>
                    </div>
                </div>
            )}
            {
                id === OptionIds.PdfComponent && (
                    <Input
                        placeholder='PDF URL'
                        value={layout.options.PdfComponent?.value}
                        onChange={e => onOptionValueChange(e.target.value)}
                    />
                )
            }
            {
                id === OptionIds.CardsComponent && (
                    <CardsOption
                        cards={layout.options.CardsComponent}
                        onOptionValueChange={onOptionValueChange}
                    />
                )
            }
            {id === OptionIds.CornerImagesComponent && (
                <div className="flex-col gap-10">
                    <div className="flex-col">
                        {!!layout.options.CornerImagesComponent?.image.length && layout.options.CornerImagesComponent.image.map(({image}, idx) => {
                            return (
                                <div
                                    key={idx}
                                    className={`setting-drawer__background-type__image ${imageError ? 'setting-drawer__background-type__image-error' : ''}`}
                                >
                                    <SettingsImageUploader
                                        index={idx + 1}
                                        action={imageAction}
                                        onChange={(url) => onChangeImagesListUrl({url, idx})}
                                        url={image || ''}
                                        errorCb={() => {
                                            setImageError(true)
                                        }}
                                    />
                                </div>
                            )
                        })}
                    </div>
                        <div>
                            <div className="flex">
                                <div className='setting-drawer__type-subtitle'>Corner radius</div>
                                <Tooltip
                                    overlayClassName='tooltip-general tooltip-small'
                                    title="Button corner radius is automatically applied to buttons in other sections for consistency"
                                    placement="bottom"
                                >
                                    <div className='cursor-pointer ml-4'>
                                        <InfoIcon fillColor="#57585A" width={18} height={18}/>
                                    </div>
                                </Tooltip>
                            </div>
                            <Input
                                prefix={<CornerIcon/>}
                                className="setting-drawer__text-input"
                                placeholder='0 px'
                                value={layout.options.CornerImagesComponent?.cornerRadius || ''}
                                onChange={e => {
                                    const inputValue = e.target.value
                                    onOptionValueChange(inputValue, 'cornerRadius')
                                }}
                            />
                        </div>
                </div>
            )}
            {id === OptionIds.ButtonsComponent && !!layout.options.ButtonsComponent?.button?.length && layout.options.ButtonsComponent.button.map((button, index) => {
                return (
                    <>
                        <div className='setting-drawer__type-label'>
                            Button
                            {' '}
                            {index + 1}
                        </div>
                        <div className="flex-col gap-10">
                            <div>
                                <div className='setting-drawer__type-subtitle'>Link</div>
                                <Input
                                    className="setting-drawer__text-input"
                                    placeholder='https://www.thesummit2024.com'
                                    value={button?.link}
                                    onChange={e => onButtonsValueChange(e.target.value, index,'link')}
                                />
                            </div>
                            <div>
                                <div className='setting-drawer__type-subtitle'>Text color</div>
                                <CustomColorPicker
                                    value={button?.textColor}
                                    defaultValue={button?.textColor || 'rgb(0, 0, 0, 100%)'}
                                    showText
                                    onChangeComplete={(color) => {
                                        onButtonsValueChange(color, index, 'textColor')
                                    }}
                                />
                            </div>
                            <div className="flex gap-10">
                                <div className="w50p">
                                    <div className='setting-drawer__type-subtitle'>Background color</div>
                                    <CustomColorPicker
                                        value={button?.backgroundColor}
                                        defaultValue={button?.backgroundColor || 'rgb(0, 0, 0, 100%)'}
                                        showText
                                        onChangeComplete={(color) => {
                                            onButtonsValueChange(color, index, 'backgroundColor')
                                        }}
                                    />
                                </div>
                                <div className="w50p">
                                    <div className='setting-drawer__type-subtitle'>Stroke color</div>
                                    <CustomColorPicker
                                        value={button?.strokeColor}
                                        defaultValue={button?.strokeColor || 'rgb(0, 0, 0, 100%)'}
                                        showText
                                        onChangeComplete={(color) => {
                                            onButtonsValueChange(color, index, 'strokeColor')
                                        }}
                                    />
                                </div>
                            </div>
                            <div>
                                <div className='setting-drawer__type-subtitle'>Text color on hover</div>
                                <CustomColorPicker
                                    value={button?.textColorOnHover}
                                    defaultValue={button?.textColorOnHover || 'rgb(0, 0, 0, 100%)'}
                                    showText
                                    onChangeComplete={(color) => {
                                        onButtonsValueChange(color, index, 'textColorOnHover')
                                    }}
                                />
                            </div>
                            <div className="flex gap-10">
                                <div className="w50p">
                                    <div className='setting-drawer__type-subtitle'>Background color on hover</div>
                                    <CustomColorPicker
                                        value={button?.backgroundColorOnHover}
                                        defaultValue={button?.backgroundColorOnHover || 'rgb(0, 0, 0, 100%)'}
                                        showText
                                        onChangeComplete={(color) => {
                                            onButtonsValueChange(color, index, 'backgroundColorOnHover')
                                        }}
                                    />
                                </div>
                                <div className="w50p">
                                    <div className='setting-drawer__type-subtitle'>Stroke color on hover</div>
                                    <CustomColorPicker
                                        value={button?.strokeColorOnHover}
                                        defaultValue={button?.strokeColorOnHover || 'rgb(0, 0, 0, 100%)'}
                                        showText
                                        onChangeComplete={(color) => {
                                            onButtonsValueChange(color, index, 'strokeColorOnHover')
                                        }}
                                    />
                                </div>
                            </div>
                            <div>
                                <div className="flex">
                                    <div className='setting-drawer__type-subtitle'>Corner radius</div>
                                    <Tooltip
                                        overlayClassName='tooltip-general tooltip-small'
                                        title="Button corner radius is automatically applied to buttons in other sections for consistency"
                                        placement="bottom"
                                    >
                                        <div className='cursor-pointer ml-4'>
                                            <InfoIcon fillColor="#57585A" width={18} height={18}/>
                                        </div>
                                    </Tooltip>
                                </div>
                                <Input
                                    prefix={<CornerIcon/>}
                                    className="setting-drawer__text-input"
                                    placeholder='0 px'
                                    value={button?.cornerRadius || ''}
                                    onChange={e => {
                                        const inputValue = e.target.value
                                        onButtonsValueChange(inputValue, index, 'cornerRadius')
                                    }}
                                />
                            </div>
                        </div>

                    </>
                )
            })}
        </div>
        </>
    )
}

export default SettingsItem

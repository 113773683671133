import React, {FC, useContext, useEffect} from 'react';
import { Button, Modal, } from 'antd';
import TourModalImage from "@assets/pro/TourModalImage.tsx";
import TourModalImageMobile from "@assets/pro/TourModalImageMobile.tsx";
import { useBreakpoints } from "@hooks/browser";
import {TourStages} from "@components/TourComponent/constants.tsx";
import {TourContext} from "@contexts/TourContext.tsx";

type Props = {
    isOpen: boolean
    onClose: () => void
    onOpen: () => void
    onCreateCampaign: () => void
    onEnablePro: () => void
}

const TourModal: FC<Props> = ({ isOpen, onClose, onOpen, onCreateCampaign, onEnablePro }) => {
    const isDesktop = useBreakpoints().md

    const {
        tourStage,
    } = useContext(TourContext);

    useEffect(() => {
        const handleBlur = () => {
            if (tourStage === TourStages.campaign && !isDesktop) {
                onOpen()
            }
        };

        const handleFocus = () => {
            if (tourStage === TourStages.campaign && !isDesktop) {
                onOpen()
            }
        };

        if (!isDesktop) {
            window.addEventListener('blur', handleBlur);
            window.addEventListener('focus', handleFocus);
        }

        return () => {
            window.removeEventListener('blur', handleBlur);
            window.removeEventListener('focus', handleFocus);
        };
    }, [isDesktop, tourStage]);

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.hidden && tourStage === TourStages.campaign && !isDesktop) {
                onOpen()
            }
        };

        if (!isDesktop) {
            document.addEventListener('visibilitychange', handleVisibilityChange);
        }

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [isDesktop, tourStage]);

    return (
        <Modal
            className="tour-modal"
            open={isOpen}
            onCancel={onClose}
            centered
            width={600}
            footer={null}
        >
            <div>
                {isDesktop ? <TourModalImage /> : <TourModalImageMobile />}
                <div className="tour-modal__title">Try all the benefits of Boost Express</div>
                <div className="tour-modal__subtitle">
                    Get full campaign assistance from our team
                    <br />
                    of experts with Boost PRO service
                </div>
                <div
                    className="flex justify-center tour-modal__footer"
                >
                    <Button
                        className="gradient-pro"
                        size="large"
                        onClick={onEnablePro}
                    >
                        Enable PRO
                    </Button>
                    <Button
                        size="large"
                        onClick={onCreateCampaign}
                    >
                        Create campaign from scratch
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default TourModal;

import { Button, message, notification, Table } from "antd";
import type { ColumnsType } from 'antd/es/table';
import React, { useState } from "react";
import { ErrorDataResponse, formatDateToCommonFormat } from "@shared/utils.ts";
import { useGetOrganizerAudienceCatalogsQuery, usePatchAudienceCatalogStatusByIdMutation } from '@store/type-event/audiences/audiences.api.ts';
import { TabNames } from "../constants.ts";
import emptyAudienceCatalog from "@assets/png/emptyAudienceCatalog.png";
import {PlusOutlined} from "@ant-design/icons";
import {AudienceCatalogItem} from "@store/type-event/audiences/models.ts";
import MenuDotsHor from "@assets/billing/MenuDotsHor.tsx";
import NameColumn from "@pages/EventRolePages/AudiencesEventPage/components/NameColumn.tsx";
import { StatusAction } from "@shared/types.ts";
import EditCatalogModal from "@pages/EventRolePages/AudiencesEventPage/components/EditCatalogModal.tsx";
import AudiencesColumn from "@pages/EventRolePages/AudiencesEventPage/components/AudiencesColumn.tsx";
import EventsColumn from "@pages/EventRolePages/AudiencesEventPage/components/EventsColumn.tsx";
import CatalogDropdown from "@pages/EventRolePages/AudiencesEventPage/components/CatalogDropdown.tsx";

// todo добавить лого для edit
// todo добавить page
const AudienceCatalogsTable = () => {
    const [isEditAudienceModalOpen, setIsEditAudienceModalOpen] = useState(false)
    const [selectedAudience, setSelectedAudienceCatalog] = useState<AudienceCatalogItem | null>(null)

    const { data: audienceCatalogsRes, isLoading: isAudienceCatalogsLoading, isFetching: isAudienceCatalogsFetching, refetch } = useGetOrganizerAudienceCatalogsQuery({})

    const [patchAudienceStatus, { isLoading: isPatchStatusLoading }] = usePatchAudienceCatalogStatusByIdMutation()

    const onCreateAudienceCatalog = () => {
        setIsEditAudienceModalOpen(true)
        setSelectedAudienceCatalog(null)
    }

    const onChangeStatus = async (action: StatusAction, item: AudienceCatalogItem) => {
        try {
            const response = await patchAudienceStatus({ statusId: action.id, id: item.id })
            if ('data' in response) {
                refetch()
                return notification.open({
                    message: `The audience catalog status has been successfully changed to "${action.transit}"`,
                    placement: 'bottomLeft',
                    closeIcon: false,
                    type: 'success'
                });
            }
            if ((response as ErrorDataResponse)?.error?.data?.errors) {
                return  message.open({
                    type: 'error',
                    content: (response as ErrorDataResponse)?.error?.data?.message as string || 'Error updating audience catalog status',
                });
            }
            return message.open({
                type: 'error',
                content: `Error updating audience catalog status`,
            });
        } catch (error) {
            return message.open({
                type: 'error',
                content: `Error updating audience catalog status`,
            });
        }
    }

    const onEdit = (item: AudienceCatalogItem) => {
        setSelectedAudienceCatalog(item)
        setIsEditAudienceModalOpen(true)
    }

    const handleEditModalClose = () => {
        setSelectedAudienceCatalog(null)
        setIsEditAudienceModalOpen(false)
    }

    const columns: ColumnsType = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            width: 200,
            render: (date) => {
                return (
                    <span
                        className="organizer-billing-page__reports__date"
                    >
                        {formatDateToCommonFormat(date)}
                    </span>
                )
            }
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: 400,
            render: (name: AudienceCatalogItem['name']) => <NameColumn title={name?.title} description={name?.description} />
        },
        {
            title: 'Audiences',
            dataIndex: 'audiences',
            key: 'audiences',
            width: 238,
            render: (audiences: AudienceCatalogItem['audiences']) => <AudiencesColumn audiences={audiences} />
        },
        {
            title: 'Events',
            dataIndex: 'events',
            key: 'events',
            width: 238,
            render: (events: AudienceCatalogItem['events']) => <EventsColumn events={events} />
        },
        {
            title: '',
            dataIndex: 'id',
            key: 'dropdown',
            width: '8%',
            fixed: 'right',
            render: (_, item: AudienceCatalogItem) => {
                return (
                        <div>
                            <CatalogDropdown item={item} onChangeStatus={onChangeStatus} onEdit={() => onEdit(item)}>
                                <div onClick={(e) => e.preventDefault()}>
                                    <MenuDotsHor />
                                </div>
                            </CatalogDropdown>
                        </div>
                    )
            }
        },
    ];

    return (
        <div
            className={`organizer-billing-page__tab-inner ${!audienceCatalogsRes?.data?.length ? 'organizer-billing-page__tab-inner-empty' : ''}`}>
            {audienceCatalogsRes?.data?.length ? (
                <div className="organizer-billing-page__revenue-reports-title">
                    <div className="organizer-billing-page__title">{TabNames.AudienceCatalogs}</div>
                    <Button
                        className="organizer-billing-page__create-request-btn"
                        type="primary"
                        onClick={onCreateAudienceCatalog}
                    >
                        <PlusOutlined />
                        Create catalog
                    </Button>
                </div>
            ) : (
                <div className="organizer-billing-page__title">{TabNames.AudienceCatalogs}</div>
            )}
            {!(isAudienceCatalogsLoading || isAudienceCatalogsFetching || isPatchStatusLoading) && !audienceCatalogsRes?.data.length ? (
                <div className='ad-accounts-page__empty-credentials'>
                    <img src={emptyAudienceCatalog} alt='emptyAudienceCatalog'/>
                    <span><b>You have no audience catalogs</b></span>
                    <span>Catalogs are an easy way to group your audiences. To add a new catalog, click the button below</span>
                    <Button
                        type='primary'
                        className="mt-16"
                        onClick={onCreateAudienceCatalog}
                    >
                        <PlusOutlined />
                        Create catalog
                    </Button>
                </div>
            ) : (
                <div className="mb-24">
                    <Table
                        bordered
                        dataSource={audienceCatalogsRes?.data}
                        columns={columns}
                        rowKey="id"
                        loading={isAudienceCatalogsLoading || isAudienceCatalogsFetching || isPatchStatusLoading}
                        pagination={false}
                        scroll={{x: true}}
                    />
                </div>
            )}
            <EditCatalogModal
                isOpen={isEditAudienceModalOpen}
                selectedAudience={selectedAudience}
                onClose={handleEditModalClose}
                refetch={refetch}
            />
        </div>
    )
}

export default AudienceCatalogsTable

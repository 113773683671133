import React, { useContext, useEffect, useRef, useState } from 'react';
import JoditEditor from 'jodit-react';
import './styles.scss'
import UpArrowIcon from './assets/UpArrowIcon';
import DownArrowIcon from './assets/DownArrowIcon';
import ThreeDotsIcon from "@assets/pro/ThreeDotsIcon";
import DeleteRedIcon from './assets/DeleteRedIcon';
import SettingsIcon from './assets/SettingsIcon';
import { JoditOptionName } from './assets/models';
import { LandingEditorContext } from '@contexts/LandingEditorContext';
import { config, COPIED_LANDING_SECTION } from './constants';
import { PartnerLandingSectionLayout } from "@store/type-partner/landings/models.ts";
import CloneIcon
    from "@pages/PartnerRolePages/LandingPageConstructor/components/LandingPageWYSIWYGconstructor/assets/CloneIcon.tsx";
import ChangeSectionPopover
    from "@pages/PartnerRolePages/LandingPageConstructor/components/ChangeSectionPopover/ChangeSectionPopover.tsx";
import { Dropdown, notification, Tooltip } from "antd";
import HideEyeIcon from "@assets/lpEditor/HideEyeIcon.tsx";

interface HtmlEditorProps {
    id: string;
    idx: number;
    onCustomOptionClick: ({ option, id }: { option: JoditOptionName, id: string, selectedLayout?: PartnerLandingSectionLayout }) => void;
    layout: PartnerLandingSectionLayout
}

const HtmlEditor: React.FC<HtmlEditorProps> = ({
    id,
    onCustomOptionClick,
    layout,
    idx
}) => {
    const {
        updateSectionHtmlById,
    } = useContext(LandingEditorContext);
    const editor = useRef(null);

    return (
        <div className="jodit-editor-wrapper">
            <ChangeSectionPopover
                id={id}
                layoutName={layout.name}
                onOptionClick={onCustomOptionClick}
            />
            <JoditEditor
                ref={editor}
                value={layout.html}
                config={config}
                onBlur={(newContent) => {
                    const isSame = newContent.replace('<body>', '').replace('</body>', '') === layout.html
                    if (!isSame) {
                        updateSectionHtmlById({ id, html: newContent })
                    }
                }}
            />

            <CustomOptions
                id={id}
                onOptionClick={onCustomOptionClick}
                layout={layout}
                idx={idx}
            />
        </div>
    );
};

export default HtmlEditor;

const CustomOptions = (
    {
        id,
        onOptionClick,
        layout,
        idx
    }
        :
        {
            id: string;
            idx: number;
            onOptionClick: ({ option, id }: { option: JoditOptionName, id: string }) => void;
            layout: PartnerLandingSectionLayout
        }
) => {
    const [isPasteDisabled, setIsPasteDisabled] = useState(true)
    const [isOtherDropdownOpen, setIsOtherDropdownOpen] = useState(false)
    const {
        setLayoutSettingsData,
        onSectionAddToList
    } = useContext(LandingEditorContext);

    useEffect(() => {
        const copiedSection = localStorage.getItem(COPIED_LANDING_SECTION);
        if (copiedSection) {
            setIsPasteDisabled(false)
        }
    }, []);

    const onCopyClick = () => {
        localStorage.setItem(COPIED_LANDING_SECTION, JSON.stringify(layout));
        setIsPasteDisabled(false)
        notification.open({
            message: 'Section has been successfully copied to the clipboard',
            placement: 'bottomLeft',
            closeIcon: false,
            type: 'success'
        });
    }

    const onPasteClick = () => {
        const copiedSection = localStorage.getItem(COPIED_LANDING_SECTION);
        if (copiedSection) {
            onSectionAddToList(JSON.parse(copiedSection), idx + 1)
        }
    }

    const dropdownItems = [
        {
            label: (
                <div onClick={onCopyClick}>
                    Copy
                </div>
            ),
            key: 'Copy',
        },
        {
            label: (
                <div onClick={onPasteClick}>
                    Paste
                </div>
            ),
            key: 'Paste',
            disabled: isPasteDisabled
        },
    ];

    return (
        <aside className={`jodit-toolbar__box-aside ${isOtherDropdownOpen ? 'jodit-toolbar__box-aside-open' : ""}`}>
            <article
                className='jodit-toolbar__box-aside__settings'
            >
                {/* not active for the moment */}
                {/* <button
        onClick={() => onCustomOptionClick({ option: JoditOptionName.Clone, id })}
        className='jodit-toolbar__box-aside__settings__cta'>
        <CloneIcon />
    </button> */}

                <Tooltip
                    mouseLeaveDelay={0}
                    overlayClassName='tooltip-general tooltip-small'
                    title="Duplicate"
                    placement="bottom"
                >
                    <button
                        onClick={() => {
                            onOptionClick({ option: JoditOptionName.Clone, id })
                        }}
                        className='jodit-toolbar__box-aside__settings__cta jodit-toolbar__box-aside__settings__cta__clone'
                    >
                        <CloneIcon width={20} height={20} />
                    </button>
                </Tooltip>
                <Tooltip
                    mouseLeaveDelay={0}
                    overlayClassName='tooltip-general tooltip-small'
                    title="Hide"
                    placement="bottom"
                >
                    <button
                        onClick={() => onOptionClick({ option: JoditOptionName.Delete, id })}
                        className='jodit-toolbar__box-aside__settings__cta jodit-toolbar__box-aside__settings__cta__hide'
                    >
                        <HideEyeIcon />
                    </button>
                </Tooltip>
                <Tooltip
                    mouseLeaveDelay={0}
                    overlayClassName='tooltip-general tooltip-small'
                    title="Settings"
                    placement="bottom"
                >
                    <button
                        onClick={() => {
                            setLayoutSettingsData(layout)
                        }}
                        className='jodit-toolbar__box-aside__settings__cta'
                    >
                        <SettingsIcon width={20} height={20} />
                    </button>
                </Tooltip>

                <Tooltip
                    mouseLeaveDelay={0}
                    overlayClassName='tooltip-general tooltip-small'
                    title="Delete"
                    placement="bottom"
                >
                    <button
                        onClick={() => onOptionClick({ option: JoditOptionName.Delete, id })}
                        className='jodit-toolbar__box-aside__settings__cta'
                    >
                        <DeleteRedIcon width={20} height={20} fillColor="#57585A" />
                    </button>
                </Tooltip>
                <Tooltip
                    mouseLeaveDelay={0}
                    overlayClassName='tooltip-general tooltip-small'
                    title="Other"
                    placement="bottom"
                >
                    <Dropdown
                        open={isOtherDropdownOpen}
                        onOpenChange={setIsOtherDropdownOpen}
                        className="dropdown"
                        menu={{ items: dropdownItems }}
                        trigger={['hover']}
                        placement="bottomRight"
                    >
                        <button
                            className='jodit-toolbar__box-aside__settings__cta jodit-toolbar__box-aside__settings__cta__dropdown'
                        >
                            <ThreeDotsIcon fillColor="#57585A" />
                        </button>
                    </Dropdown>
                </Tooltip>

                {/* not active for the moment */}
                {/* <button
                    onClick={() => onOptionClick({ option: JoditOptionName.Menu, id })}
                    className='jodit-toolbar__box-aside__settings__cta'>
                    <MenuHorIcon />
                </button> */}
            </article>

            <article
                className='jodit-toolbar__box-aside__order-updater'
            >
                <Tooltip
                    mouseLeaveDelay={0}
                    overlayClassName='tooltip-general tooltip-small'
                    title="Move up"
                    placement="bottom"
                >
                    <button
                        onClick={() => onOptionClick({ option: JoditOptionName.Up, id })}
                        className='jodit-toolbar__box-aside__order-updater__cta jodit-toolbar__box-aside__order-updater__up-arrow'
                    >
                        <UpArrowIcon width={20} height={20} />
                    </button>
                </Tooltip>
                <Tooltip
                    mouseLeaveDelay={0}
                    overlayClassName='tooltip-general tooltip-small'
                    title="Move down"
                    placement="bottom"
                >
                    <button
                        onClick={() => onOptionClick({ option: JoditOptionName.Down, id })}
                        className='jodit-toolbar__box-aside__order-updater__cta'
                    >
                        <DownArrowIcon width={20} height={20} />
                    </button>
                </Tooltip>
            </article>
        </aside>
    )
}

import React, {FC, useCallback, useEffect, useState} from 'react';
import { Button, Form, Input, message, Modal, notification, Select, type SelectProps, Spin, Tag } from 'antd';
import styles
    from "@pages/Onboarding/EventOnboarding/components/EventOnboardingViewManager/components/CompanyInfoForm/CompanyInfoForm.module.css";
import {
    AudienceCatalogItem,
    PostAudienceCatalog,
} from "@store/type-event/audiences/models.ts";
import {debounce, ErrorDataResponse, getSearchValue} from "@shared/utils.ts";
import { usePostAudienceCatalogMutation, usePatchAudienceCatalogByIdMutation, useGetOrganizerAudiencesFilterOptionsQuery } from '@store/type-event/audiences/audiences.api.ts';
import {useGetOrganizerEventsFilterOptionsQuery} from "@store/type-event/events/events.api.ts";
import ArrowIcon from "@assets/ArrowIcon.tsx";
import Avatar from "@components/Avatar";
import { OrganizerEventDataFullItem } from "@store/type-event/events/models.ts";

type Props = {
    isOpen: boolean
    onClose: () => void
    selectedAudience: AudienceCatalogItem | null
    refetch: () => void
}

const tagRender = (props: {label: string, closable: boolean; onClose: () => void; value: string}, options: OrganizerEventDataFullItem[]) => {
    const { label, closable, onClose, value } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const logo = options.find(({ id }) => id === value)?.logo || ''

    return (
        <Tag
            className="select-with-all-option__tag"
            onMouseDown={onPreventMouseDown}
            closable={closable}
            onClose={onClose}
        >
            <Avatar url={logo} bordered />
            {label}
        </Tag>
    );
};

const EditCatalogModal: FC<Props> = ({
    isOpen,
    selectedAudience,
    onClose,
    refetch
}) => {
    const [eventsSearchValue, setEventsSearchValue] = useState<string>('')
    const [audiencesSearchValue, setAudiencesSearchValue] = useState<string>('')
    const [isFormChanged, setIsFormChanged] = useState(false)

    const { data: events, isLoading: isEventsLoading } = useGetOrganizerEventsFilterOptionsQuery({ search: eventsSearchValue })
    const { data: audiences, isLoading: isAudiencesLoading } = useGetOrganizerAudiencesFilterOptionsQuery({ search: audiencesSearchValue })

    const [postCatalog , { isLoading: isPostLoading }] = usePostAudienceCatalogMutation()
    const [patchCatalog, { isLoading: isPatchLoading }] = usePatchAudienceCatalogByIdMutation()

    const [formState, setFormState] = useState<PostAudienceCatalog>({
        title: selectedAudience?.name?.title || '',
        description: selectedAudience?.name?.description || '',
        events: selectedAudience?.events?.map(({id}) => id) || [],
        audiences: selectedAudience?.audiences?.map(({id}) => id) || []
    });
    const [errors, setErrors] = useState<Record<string, string>>({title: '', description: ''})

    const isSaveDisabled = !formState.description || !formState.title || !formState.events.length || !formState.audiences.length
    useEffect(() => {
        setFormState({
            title: selectedAudience?.name?.title || '',
            description: selectedAudience?.name?.description || '',
            events: selectedAudience?.events?.map(({id}) => id) || [],
            audiences: selectedAudience?.audiences?.map(({id}) => id) || []
        })
    }, [selectedAudience]);

    const handleClose = () => {
        setErrors({})
        onClose()
    }

    const debouncedEventsUpdate = useCallback(
        debounce((search: string) => {
            const searchValue = getSearchValue(search)
            setEventsSearchValue(searchValue || '');
        }, 500),
        [setEventsSearchValue]
    );

    const debouncedAudienceUpdate = useCallback(
        debounce((search: string) => {
            const searchValue = getSearchValue(search)
            setAudiencesSearchValue(searchValue || '');
        }, 500),
        [setAudiencesSearchValue]
    );

    const handleOk = async () => {
            try {
                const response = selectedAudience ? await patchCatalog({ data: formState, id: selectedAudience.id }) : await postCatalog(formState)
                if ('data' in response) {
                    onClose()
                    refetch()
                    return notification.open({
                        message: <div>The audience catalog <b>{formState.title}</b> has been successfully {selectedAudience ? 'saved' : 'created'}</div>,
                        placement: 'bottomLeft',
                        closeIcon: false,
                        type: 'success'
                    });
                }
                if ((response as ErrorDataResponse)?.error?.data?.errors) {
                    void message.open({
                        type: 'error',
                        content: (response as ErrorDataResponse)?.error?.data?.message as string || `Error ${selectedAudience ? 'updating' : 'creating'} Catalog`,
                    });
                    return setErrors((response as ErrorDataResponse).error.data.errors)
                }
                return message.open({
                    type: 'error',
                    content: `Error ${selectedAudience ? 'updating' : 'creating'} Catalog`,
                });
            } catch (error) {
                return message.open({
                    type: 'error',
                    content: `Error ${selectedAudience ? 'updating' : 'creating'} Catalog`,
                });
            }
    }

    const onFieldChange = (fieldName:string, value: string | number[]) => {
        setFormState(prev => ({ ...prev, [fieldName]: value }));
        setErrors(prev => ({ ...prev, [fieldName]: '' }));
        if (!isFormChanged) {
            setIsFormChanged(true)
        }
    };

    const onEventsDropdownVisibleChange = useCallback((isOpen: boolean) => {
        if (!isOpen) {
            setEventsSearchValue('');
        }
    }, [])

    const onAudiencesDropdownVisibleChange = useCallback((isOpen: boolean) => {
        if (!isOpen) {
            setAudiencesSearchValue('');
        }
    }, [])

    return (
        <Modal
            destroyOnClose
            title={selectedAudience ? 'Edit Catalog' : "Create Catalog"}
            open={isOpen}
            onCancel={handleClose}
            className="organizer-billing-page__withdrawal__modal"
            centered
            width={500}
            zIndex={1001}
            footer={(
                <div
                    className={'organizer-billing-page__withdrawal__modal__footer'}
                >
                    <Button
                        type="default"
                        size="large"
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        size="large"
                        disabled={isPatchLoading || isPostLoading || isSaveDisabled || (!isFormChanged && !!selectedAudience)}
                        type="primary"
                        loading={isPatchLoading || isPostLoading}
                        onClick={handleOk}
                    >
                        {selectedAudience ? 'Save' : 'Create'}
                    </Button>
                </div>
            )}
        >
            <Form
                layout={'vertical'}
            >
                <Form.Item
                    name={'title'}
                    label={<span>Catalog name</span>}
                    htmlFor={'title'}
                    help={<span className="color-danger">{errors?.title ? errors.title : ''}</span>}
                    validateStatus={errors?.title ? 'error' : ''}
                >
                    <Input
                        id={"title"}
                        size={'large'}
                        defaultValue={formState.title}
                        placeholder="IT Sector"
                        onChange={(e) => onFieldChange("title",e.target.value)}
                    />
                </Form.Item>
                <Form.Item
                    htmlFor={'audiences'}
                    label="Audiences"
                    help={<span className="color-danger">{errors?.audiences ? errors.audiences : ''}</span>}
                    validateStatus={errors?.audiences ? 'error' : ''}
                >
                    <Select
                        className="audiences-event-page__audiences__audiences-select"
                        placeholder='Select audiences'
                        mode="tags"
                        maxTagCount="responsive"
                        style={{ width: '100%' }}
                        allowClear
                        showSearch
                        filterOption={false}
                        onSearch={debouncedAudienceUpdate}
                        onDropdownVisibleChange={onAudiencesDropdownVisibleChange}
                        notFoundContent={isAudiencesLoading ? <Spin size="small" /> : null}
                        loading={isAudiencesLoading}
                        value={formState.audiences}
                        onChange={(value) => onFieldChange('audiences', value)}
                        fieldNames={{ label: 'slug', value: 'id' }}
                        options={audiences?.data || []}
                        suffixIcon={<ArrowIcon fillColor="#C0C1C3" width="20" height="20" className="common-select-icon" />}
                    />
                </Form.Item>
                <Form.Item
                    htmlFor={'events'}
                    label="Events"
                    help={<span className="color-danger">{errors?.events ? errors.events : ''}</span>}
                    validateStatus={errors?.events ? 'error' : ''}
                >
                    <Select
                        className="audiences-event-page__audiences__event-select"
                        placeholder='Select events'
                        mode="tags"
                        maxTagCount="responsive"
                        style={{ width: '100%' }}
                        allowClear
                        showSearch
                        filterOption={false}
                        notFoundContent={isEventsLoading ? <Spin size="small" /> : null}
                        onSearch={debouncedEventsUpdate}
                        onDropdownVisibleChange={onEventsDropdownVisibleChange}
                        loading={isEventsLoading}
                        value={formState.events}
                        onChange={(value) => onFieldChange('events', value)}
                        fieldNames={{ label: 'slug', value: 'id' }}
                        options={events?.data || []}
                        suffixIcon={<ArrowIcon fillColor="#C0C1C3" width="20" height="20" className="common-select-icon" />}
                        tagRender={(props) => tagRender(props, events?.data || [])}
                    />
                </Form.Item>
                <Form.Item
                    name={'description'}
                    label={<span className={styles.CompanyInfoForm__inputName}>Add description</span>}
                    htmlFor={'description'}
                    help={<span className="color-danger">{errors?.description ? errors.description : ''}</span>}
                    validateStatus={errors?.description ? 'error' : ''}
                >
                    <Input.TextArea
                        id={"description"}
                        rows={4}
                        defaultValue={formState.description}
                        placeholder="This audience includes executives and managers who drive innovation within their organizations."
                        className={styles.CompanyInfoForm__overview}
                        onChange={(e)=> onFieldChange("description", e.target.value)}
                    />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default EditCatalogModal;

import { FormCompanyValues } from './models';

export const PRIVACY_LINK = "/privacy-policy";
export const TERMS_LINK = "https://docs.google.com/document/d/17yH1MVS3R4QaE-aUY7hEealGo5eArmUtpwxtBlXDTeY/edit?pli=1";
export const SPONSOR_SERVICE_AGREEMENT_LINK = "https://docs.google.com/document/d/1YBVyts46L1915wRZp-NYN3WqgAJy5X9B-PeQa3Hz9Nc/edit?pli=1";
export const EVENT_SERVICE_AGREEMENT_LINK = "https://docs.google.com/document/d/1EUj_MwkmNczVW4AySF4zbKW8ybpmcyBOgc0guagyBME/edit?pli=1";

export const FORM_ITEM_EMAIL = 'email';
export const FORM_ITEM_PASSWORD = 'password';
export const FORM_ITEM_PRIVACY = 'privacy';

export const VALIDATION_PASSWORD_LENGTH = 8;
export const REG_BY_GOOGLE_FLAG = 'isViaGoogle';

export const formAccountInitial = {
    email: '',
    username: '',
    password: '',
    remember: '',
    privacy: !!sessionStorage.getItem(REG_BY_GOOGLE_FLAG)
}
export const formCompanyInitial: FormCompanyValues = {
    name: '',
    company_name: '',
    site: '',
    phone: '',
    // privacy: !!sessionStorage.getItem(REG_BY_GOOGLE_FLAG),
    organization_type: 'none'

}
export const errorFormInitial = {
    email: '',
    password: '',
    username: '',
    name: '',
    company_name: '',
    site: '',
    phone: '',
}

export const GOOGLE_PROVIDER = 'google'

const TopIcon = () => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.9122 9.16246C11.14 8.93465 11.14 8.56531 10.9122 8.3375L7.41215 4.8375C7.18435 4.6097 6.815 4.6097 6.5872 4.8375L3.0872 8.3375C2.85939 8.56531 2.85939 8.93465 3.0872 9.16246C3.315 9.39026 3.68435 9.39026 3.91215 9.16246L6.99967 6.07494L10.0872 9.16246C10.315 9.39026 10.6843 9.39026 10.9122 9.16246Z"
                fill="white"
            />
        </svg>
    )
}

export default TopIcon

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import EventsPageLayout from '@layouts/EventsPageLayout';

import './styles.scss'

import { Button, Tabs, type TabsProps } from 'antd';
import { TabKeys, TabNames } from "@pages/EventRolePages/Billing/constants.ts";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import RevenueReportsTable from "@pages/EventRolePages/Billing/components/RevenueReportsTable.tsx";
import TransactionsTable from "@pages/EventRolePages/Billing/components/TransactionsTable.tsx";
import WithdrawalTable from "@pages/EventRolePages/Billing/components/WithdrawalTable.tsx";
import EventBillingDrawer from "@pages/EventRolePages/Billing/components/EventBillingDrawer.tsx";
import BillingDetails from "@pages/EventRolePages/Billing/components/BillingDetails.tsx";
import { useBreakpoints } from "@hooks/browser";
import EventBillingHeader from "@components/Header/EventBillingHeader.tsx";
import RevShareCommissionsTable from "@pages/EventRolePages/Billing/components/RevShareCommissions.tsx";
import { PlusOutlined } from "@ant-design/icons";
import EmptyBilling from '../EventPage/setup/billing/EmptyBilling';
import { useAuth } from "@contexts/AuthContext.tsx";
import { events } from "@pages/routes.ts";

const BillingEvent = () => {
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const { billingId } = useParams()
    const { user } = useAuth();

    const isDesktop = useBreakpoints().md;

    const [isEventBillingDrawerOpen, setIsEventBillingDrawerOpen] = useState(false)

    const onCreateBilling = () => {
        setIsEventBillingDrawerOpen(true)
    }

    useEffect(() => {
        const isUserAdmin = user?.roles?.includes('admin')
        if (!isUserAdmin) {
            navigate(events)
        }
    }, [user]);

    const items: TabsProps['items'] = useMemo(() => [
        {
            key: TabKeys.RevenueReports,
            label: TabNames.RevenueReports,
            children: (<RevenueReportsTable />),
        },
        {
            key: TabKeys.RevShareCommissions,
            label: TabNames.RevShareCommissions,
            children: (<RevShareCommissionsTable />),
        },
        {
            key: TabKeys.Transactions,
            label: TabNames.Transactions,
            children: (<TransactionsTable />),
        },
        {
            key: TabKeys.WithdrawalRequests,
            label: TabNames.WithdrawalRequests,
            children: (<WithdrawalTable />),
        },
        {
            key: TabKeys.BillingDetails,
            label: TabNames.BillingDetails,
            children: (<BillingDetails />),
        },
        // {
        //     key: TabKeys.Invoices,
        //     label: TabNames.Invoices,
        //     children: (<div>Invoices</div>),
        // },
    ], []);

    const activeKey = useMemo(() => {
        if (pathname.includes(`/${TabKeys.RevenueReports}`)) {
            return TabKeys.RevenueReports;
        }
        if (pathname.includes(`/${TabKeys.Transactions}`)) {
            return TabKeys.Transactions;
        }
        if (pathname.includes(`/${TabKeys.WithdrawalRequests}`)) {
            return TabKeys.WithdrawalRequests;
        }
        if (pathname.includes(`/${TabKeys.BillingDetails}`)) {
            return TabKeys.BillingDetails;
        }
        if (pathname.includes(`/${TabKeys.Invoices}`)) {
            return TabKeys.Invoices;
        }
        if (pathname.includes(`/${TabKeys.RevShareCommissions}`)) {
            return TabKeys.RevShareCommissions;
        }
        return TabKeys.RevenueReports;
    }, [pathname]);

    const onChange = useCallback((value: string) => {
        if (billingId) {
            navigate(`/billing/${billingId}/${value}`);
        }
    }, [navigate, billingId]);

    const isEmpty = !billingId || billingId === 'undefined'

    return (
        <EventsPageLayout
            mobileHeaderSideBar={(
                <>
                    <Button
                        type='primary'
                        className="ml-auto event-billing-header__create-button"
                        onClick={onCreateBilling}
                    >
                        <PlusOutlined />
                        Create billing
                    </Button>
                    <div className='vertical-divider' />
                </>
            )}
            className="event-billing-header"
        >
            <div className={`organizer-billing-page ${isEmpty ? 'organizer-billing-page__empty' : ''}`}>
                {!isDesktop && (
                    <EventBillingHeader />
                )}
                {isEmpty ? (
                    <EmptyBilling onCreateBilling={onCreateBilling} />
                ) : (
                    <Tabs
                        rootClassName="organizer-billing-page__tab"
                        activeKey={activeKey}
                        onChange={onChange}
                        items={items}
                    />
                )}
                {isEventBillingDrawerOpen
                    ?
                    <EventBillingDrawer
                        isOpen={isEventBillingDrawerOpen}
                        onClose={() => setIsEventBillingDrawerOpen(false)}
                    />
                    :
                    null}
            </div>
        </EventsPageLayout>
    )
}

export default BillingEvent

import { EventChecklistName } from '../setup/constants';

export const checklistNamesHandler = (checklist: { id: string, name: EventChecklistName }[]): string[] => {
    return checklist.map(item => item.name)
}
export const checklistLinkHandler = (name: EventChecklistName): string => {
    switch (name) {
        case 'subdomain_verified':
            return 'subdomain';
        case 'credential_granted':
            return 'ad-accounts';
        case 'audience_configured':
            return 'audience';
        case 'landing_template_configured':
            return 'landing_page';
        case 'creative_template_configured':
            return 'creative_templates';
        case 'track_installed':
            return 'track';
        case 'billing_defined':
            return 'billing';
        default:
            return 'default';  // Fallback link if none match
    }
}
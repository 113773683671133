import React, { FC, useState, useMemo } from 'react';
import { Button, Checkbox, Form, Input, Modal, Popover, Select } from 'antd';
import { UserEditErrorsType, UserEditType } from "@pages/PartnerRolePages/SettingsPage/types.ts";
import { CheckCircleSuccessIcon } from "@icons/CheckCircleSuccessIcon.tsx";
import { FilterByEnumItem } from "@store/main/enums/models.ts";
import { useBreakpoints } from "@hooks/browser";
import PasswordPopover from "@pages/CommonPages/Authentification/Register/components/PasswordPopover";

type Props = {
    isOpen: boolean
    formState: UserEditType
    errors: UserEditErrorsType
    isLoading: boolean
    onClose: () => void;
    onChangeFieldValue: (fieldName: keyof UserEditType, value: string | boolean | string[]) => void
    onSubmitForm: (isChangePassword: boolean) => void
    roles: FilterByEnumItem[]
}

const UserModal: FC<Props> = ({ isOpen, isLoading, onClose, formState, errors, onChangeFieldValue, onSubmitForm, roles }) => {
    const isCreate = !formState.id
    const isDesktop = useBreakpoints().md;

    const [isChangePassword, setIsChangePassword] = useState(false)
    const [isSuccessPassStatus, setIsSuccessPassStatus] = useState(false);
    const [isSuccessNewPassStatus, setIsSuccessNewPassStatus] = useState(false);

    const isSaveEnabled = useMemo(() => {
        const isPasswordLengthValid = formState.isGeneratePassword || (!formState.isGeneratePassword && isSuccessPassStatus)
        const isNewPasswordLengthValid = !formState.isChangePassword || (!formState.isGeneratePassword && formState.isChangePassword && isSuccessPassStatus) || (formState.isGeneratePassword && formState.isChangePassword)
        const isPasswordValid = (!formState.id && isPasswordLengthValid) || (formState.id && isNewPasswordLengthValid)
        return formState.name && formState.email && formState.roles.length && isPasswordValid
    }, [formState, isSuccessPassStatus])

    const passwordError = !formState.isGeneratePassword && formState.password?.length > 0 && formState.password?.length < 8 ? 'Password must be at least 8 characters' : ''
    const newPasswordError = !formState.isGeneratePassword && Number(formState.new_password?.length) > 0 && Number(formState.new_password?.length) < 8 ? 'Password must be at least 8 characters' : ''

    return (
        <Modal
            title={isCreate ? 'New user' : 'User'}
            open={isOpen}
            onCancel={() => {
                onClose()
                setIsChangePassword(false)
            }}
            centered
            width={isDesktop ? 440 : 'calc(100vw - 40px)'}
            maskClosable={false}
            className="user-edit-modal"
            footer={null}
        >
            <div>
                <Form
                    layout="vertical"
                >
                    <Form.Item
                        label={(
                            <span>
                                Name
                            </span>
                        )}
                        htmlFor="userEditModalName"
                        help={<span className="color-danger">{errors?.name ? errors?.name : ''}</span>}
                        validateStatus={errors?.name ? 'error' : ''}
                    >
                        <Input
                            id="userEditModalName"
                            value={formState.name}
                            size="large"
                            onChange={(e) => onChangeFieldValue('name', e.target.value)}
                        />
                    </Form.Item>
                    <Form.Item
                        label={(
                            <span>
                                E-mail
                            </span>
                        )}
                        htmlFor="userEditModalEmail"
                        help={<span className="color-danger">{errors?.email ? errors?.email : ''}</span>}
                        validateStatus={errors?.email ? 'error' : ''}
                    >
                        <Input
                            disabled={!isCreate}
                            id="userEditModalEmail"
                            type="email"
                            size="large"
                            value={formState.email}
                            onChange={(e) => onChangeFieldValue('email', e.target.value)}
                        />
                    </Form.Item>
                    <Form.Item
                        label={(
                            <span>
                                Role
                            </span>
                        )}
                        htmlFor="userEditModalRole"
                        help={<span className="color-danger">{errors?.roles ? errors?.roles : ''}</span>}
                        validateStatus={errors?.roles ? 'error' : ''}
                    >
                        <Select
                            id="userEditModalRole"
                            style={{ width: '100%' }}
                            options={roles}
                            value={formState.roles}
                            onSelect={(value) => {
                                onChangeFieldValue('roles', [value])
                            }}
                            placeholder='Select role'
                            fieldNames={{
                                label: 'translate',
                                value: 'id'
                            }}
                        />
                    </Form.Item>
                    {isCreate && (
                        <Checkbox
                            className="user-edit-modal__checkbox"
                            checked={formState.isGeneratePassword}
                            onChange={(e) => {
                                onChangeFieldValue('isGeneratePassword', e.target.checked)
                            }}
                            id="userEditModalCheckbox"
                        >
                            Generate password
                        </Checkbox>
                    )}
                    {(isCreate && !formState.isGeneratePassword) && (
                        <>
                            <span>
                                {formState.id ? 'Your password' : 'Create your password'}
                            </span>
                            <Popover
                                content={() => (
                                    <PasswordPopover
                                        password={formState.password}
                                        isSuccessStatusHandler={setIsSuccessPassStatus}
                                    />
                                )}
                                placement="bottomLeft"
                                trigger="focus"
                            >
                                <Input.Password
                                    prefix={isSuccessPassStatus ? <CheckCircleSuccessIcon /> : null}
                                    className="user-edit-modal__password-input"
                                    id="userEditModalPassword"
                                    value={formState.password}
                                    onChange={(e) => {
                                        onChangeFieldValue('password', e.target.value)
                                    }}
                                />
                                {(errors.password || passwordError) && <span className="color-danger">{errors?.password || passwordError ? errors?.password || passwordError : ''}</span>}
                            </Popover>

                        </>
                    )}
                    {!isCreate && (
                        <Form.Item>
                            <div className="text-link cursor-pointer" onClick={() => setIsChangePassword((prev) => !prev)}>
                                {isChangePassword ? 'Don\'t change password' : 'Change password'}
                            </div>
                        </Form.Item>
                    )}
                    {!isCreate && isChangePassword && (
                        <Checkbox
                            className="user-edit-modal__checkbox user-edit-modal__checkbox-new-password"
                            checked={formState.isGeneratePassword}
                            onChange={(e) => {
                                onChangeFieldValue('isGeneratePassword', e.target.checked)
                            }}
                            id="userEditModalCheckbox"
                        >
                            Generate password
                        </Checkbox>
                    )}
                    {!isCreate && isChangePassword && !formState.isGeneratePassword && (
                        <>
                            <span>
                                New password
                            </span>
                            <Popover
                                content={() => (
                                    <PasswordPopover
                                        password={formState.password}
                                        isSuccessStatusHandler={setIsSuccessNewPassStatus}
                                    />
                                )}
                                placement="bottomLeft"
                                trigger="focus"
                            >
                                <Input.Password
                                    prefix={isSuccessNewPassStatus ? <CheckCircleSuccessIcon /> : null}
                                    className="user-edit-modal__password-input user-edit-modal__new-password-input"
                                    id="userEditModalNewPassword"
                                    value={formState.new_password || ''}
                                    onChange={(e) => {
                                        onChangeFieldValue('new_password', e.target.value)
                                    }}
                                />
                                {(errors.new_password || newPasswordError) && (
                                    <span className="color-danger">
                                        {errors?.new_password || newPasswordError ? errors?.new_password || newPasswordError : ''}
                                    </span>
                                )}
                            </Popover>
                        </>
                    )}
                    <div
                        className="flex justify-end mt-16 ad-accounts-page__modal__footer"
                    >
                        <Button
                            size="large"
                            onClick={() => {
                                onClose()
                                setIsChangePassword(false)
                            }}
                            className='mr-6'
                        >
                            Cancel
                        </Button>
                        <Button
                            type="primary"
                            size="large"
                            onClick={() => {
                                onSubmitForm(isCreate || isChangePassword)
                                setIsChangePassword(false)
                            }}
                            disabled={isLoading || !isSaveEnabled}
                            loading={isLoading}
                        >
                            {formState.id ? 'Save' : 'Create'}
                        </Button>
                    </div>
                </Form>
            </div>
        </Modal>
    )
}

export default UserModal;

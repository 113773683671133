import React from 'react';
import { Input, Select, DatePicker } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import CustomButton from '@components/CustomButton';
import ExitCrossIcon from '@assets/ExitCrossIcon';
import { MyEventsFilters } from '@pages/EventRolePages/EventPage';
import { FilterByEnumItem } from '@store/main/enums/models';
import { statusesFormatted } from '@shared/constants';

const { RangePicker } = DatePicker;

interface Props {
    filters: MyEventsFilters;
    statuses: FilterByEnumItem[] | undefined;
    searchValue: string;
    onFilterUpdate: (updatedFilters: Partial<MyEventsFilters>) => void;
    onResetFilters: () => void;
    debouncedOnSearchUpdate: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const EventsCardsFilter: React.FC<Props> = ({
    filters,
    statuses,
    searchValue,
    onFilterUpdate,
    onResetFilters,
    debouncedOnSearchUpdate,
}) => {
    const disabledDate = (current: dayjs.Dayjs) => {
        return !!filters.date.to && current > dayjs(filters.date.to);
    };

    return (
        <div className='my-event-page__filter-container m-0'>
            <div className='my-event-page__filter-container-text'>
                <Input
                    allowClear
                    placeholder="Search by keywords"
                    prefix={<SearchOutlined />}
                    onChange={debouncedOnSearchUpdate}
                />
            </div>
            <div className='my-event-page__filter-container-range'>
                <RangePicker
                    format="YYYY-MM-DD"
                    disabledDate={disabledDate}
                    value={[filters.date.from ? dayjs(filters.date.from) : null, filters.date.to ? dayjs(filters.date.to) : null]}
                    onChange={(_, dateStrings) => onFilterUpdate({ date: { from: dateStrings[0], to: dateStrings[1] } })}
                />
            </div>
            {/* {statuses && (
                <div className='my-event-page__filter-container-status'>
                    <Select
                        style={{ width: '100%' }}
                        mode="multiple"
                        allowClear
                        value={filters.statuses}
                        onChange={(statuses) => onFilterUpdate({ statuses })}
                        maxTagCount={1}
                        options={statusesFormatter(statuses || [])}
                    />
                </div>
            )} */}
            {(filters.statuses.length || searchValue.length > 3) && (
                <div className='my-event-page__filter-container-filters-close-btn'>
                    <CustomButton
                        type='text'
                        classOptional='no-background'
                        title='Reset'
                        prefix={<ExitCrossIcon />}
                        onClick={onResetFilters}
                    />
                </div>
            )}
        </div>
    );
};

export default EventsCardsFilter;

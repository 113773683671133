import React, { useEffect, useState, useMemo } from "react";
import { Button, notification, Tooltip, message, Spin, Divider, Typography } from 'antd';
import '../styles.scss'
import InfoIcon from "@icons/header/InfoIcon";
import { PlusOutlined } from '@ant-design/icons';
import { HELP_CENTER_LINK, INIT_CURRENT_PAGE } from "@shared/constants.ts";
import { useBreakpoints } from "@hooks/browser";
import './styles.scss'
import { useGetUserOrganizerBillingByEventIdQuery, useGetUserOrganizerBillingQuery, usePostChangeBillingMutation } from '@store/type-event/billing/billing.api.ts';
import { Link, useNavigate, useParams } from "react-router-dom";
import InfiniteSelect from "@components/InfiniteSelect/InfiniteSelect.tsx";
import ArrowIcon from "@assets/ArrowIcon.tsx";
import StyledAlert from "@components/StyledAlert/StyledAlert.tsx";
import EventBillingDrawer from "@pages/EventRolePages/Billing/components/EventBillingDrawer.tsx";
import EmptyBilling from './EmptyBilling';
import ChangeBillingModal from './ChangeBillingModal';
import { billing } from '@pages/routes';
import TotalNumbers from '@pages/EventRolePages/Billing/components/TotalNumbers';
import { formatByCurrency } from '@shared/utils';
import { formatNumberWithThousandsSeparator } from '@components/Analytics/helpers';

const EventBilling = () => {
    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(false)
    const [billingsPage, setBillingsPage] = useState(INIT_CURRENT_PAGE)
    const [changedBilling, setChangedBilling] = useState<{ label: string; value: number } | null>(null)
    const [selectedBilling, setSelectedBilling] = useState<{ value: number, label: string } | null>(null)
    const [isEventBillingDrawerOpen, setIsEventBillingDrawerOpen] = useState(false)
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const [postChangeBilling] = usePostChangeBillingMutation()

    const isDesktop = useBreakpoints().md;
    const { eventId } = useParams()
    // todo add state when back is ready
    const isDisabled = false

    const { data: billingByIdRes, isLoading: isBillingByIdLoading, isFetching: isBillingByIdFetching } = useGetUserOrganizerBillingByEventIdQuery({ eventId: eventId || '' }, { skip: !eventId })
    const { data: billingsRes, isLoading: isBillingsResLoading, isFetching: isBillingsResFetching } = useGetUserOrganizerBillingQuery({ page: billingsPage })

    useEffect(() => {
        if (billingByIdRes?.data?.id) {
            setSelectedBilling(billingByIdRes?.data?.id
                ?
                {
                    value: billingByIdRes.data.id,
                    label: billingByIdRes?.data?.name
                }
                :
                null
            )
        }
    }, [billingByIdRes]);

    const handleChangeBilling = async () => {
        if (!changedBilling?.value || !eventId) {
            return
        }
        setIsLoading(true)
        try {
            const response = await postChangeBilling({ billingId: changedBilling?.value, eventId })

            if ('error' in response) {
                void message.open({
                    type: 'error',
                    content: (response as { error: { data: { message: string } } })?.error?.data?.message || 'Billing error',
                });
            }

            if ('data' in response) {
                notification.open({
                    message: `Billing Account has been successfully changed to ${changedBilling?.label}`,
                    placement: 'bottomLeft',
                    closeIcon: false,
                    type: 'success'
                });
                setChangedBilling(null)
                setSelectedBilling(changedBilling)
            }
        } catch (e) {
            notification.open({
                message: 'Billing Account has not been changed',
                placement: 'bottomLeft',
                closeIcon: false,
                type: 'warning'
            });
        }
        setChangedBilling(null)
        setIsLoading(false)
    }

    const onCloseChangeBillingModal = () => {
        setChangedBilling(null)
    }

    const onCreateBilling = () => {
        setIsEventBillingDrawerOpen(true)
    }

    const isDataLoading = isBillingsResLoading || isBillingsResFetching || isBillingByIdLoading || isBillingByIdFetching
    return (
        <>
            <article className="event-ad-billing">
                <div className='flex w-full items-baseline justify-space-between'>
                    <div className='flex items-baseline gap-6'>
                        <Typography.Title level={4}>
                            Billing
                        </Typography.Title>
                        <Tooltip
                            overlayClassName='tooltip-general event-billing-content-tooltip'
                            title={(
                                <div>
                                    The same billing account can be used for multiple events
                                </div>
                            )}
                            placement={isDesktop ? "bottom" : "bottomRight"}
                            overlayStyle={{
                                width: '230px'
                            }}
                        >
                            <div className='cursor-pointer mt-3'>
                                <InfoIcon fillColor="#252628" width={18} height={18} />
                            </div>
                        </Tooltip>
                    </div>

                    {selectedBilling && (
                        <Button
                            type="default"
                            onClick={() => {
                                navigate(`${billing}/${selectedBilling?.value}/billing-details`)

                            }}
                        >
                            Billing settings
                        </Button>
                    )}
                </div>

                {/* USE <TotalNumbers/>?  */}
                <div className='flex items-center justify-space-between gap-16 mt-16 mb-24'>

                    <div className="revenue-reports-numbers__item">
                        <div className="revenue-reports-numbers__title-wrapper">
                            <div className="revenue-reports-numbers__title">Revenue</div>
                            <InfoIcon fillColor="#57585A" width={14} height={14} className='info-icon' />
                        </div>

                        <div className="revenue-reports-numbers__amount">
                            {formatByCurrency(0, '')}
                        </div>
                        <div className="revenue-reports-numbers__date">
                            Last 30 days
                        </div>
                    </div>

                    <div className="revenue-reports-numbers__item">
                        <div className="revenue-reports-numbers__title-wrapper">
                            <div className="revenue-reports-numbers__title">Revshare</div>
                            <InfoIcon fillColor="#57585A" width={14} height={14} className="info-icon" />
                        </div>

                        <div className="revenue-reports-numbers__amount">
                            {formatNumberWithThousandsSeparator(0)}
                        </div>
                        <div className="revenue-reports-numbers__date">
                            Fixed
                        </div>
                    </div>

                    <div className="revenue-reports-numbers__item">
                        <div className="revenue-reports-numbers__title-wrapper">
                            <div className="revenue-reports-numbers__title">Commission</div>
                            <InfoIcon fillColor="#57585A" width={14} height={14} className="info-icon" />
                        </div>

                        <div className="revenue-reports-numbers__amount">
                            {formatByCurrency(0, '')}
                        </div>
                        <div className="revenue-reports-numbers__date">
                            Last 30 days
                        </div>
                    </div>
                </div>

                {/* //  */}



                {isDataLoading && (
                    <div className='flex flex-center ad-accounts-page__select-spin'>
                        <Spin size="small" />
                    </div>
                )}
                {!isDataLoading && Boolean(billingsRes?.data?.length) && (
                    <div className='event-ad-billing__billing-select'>
                        {isDisabled ? (
                            <div >
                                <Tooltip
                                    overlayClassName='tooltip-general event-billing-content-tooltip'
                                    title={(
                                        <div>
                                            You can’t change Billing Account due to sponsors have already launched ad campaigns
                                        </div>
                                    )}
                                    placement={isDesktop ? "bottom" : "bottomRight"}
                                    overlayStyle={{
                                        width: '230px'
                                    }}
                                >
                                    <span className='text-regular-14 text-neutral-8 mb-4'>Connect billing to the event</span>
                                    <InfiniteSelect
                                        disabled
                                        id="event-billing-content__billing"
                                        placeholder='Select from the existing billings'
                                        style={{ width: '100%' }}
                                        filterOption={false}
                                        notFoundContent={isBillingsResLoading || isBillingsResFetching ? <Spin size="small" /> :
                                            <div>There is no Billing Accounts</div>}
                                        isLoading={isBillingsResLoading || isBillingsResFetching}
                                        listHeight={160}
                                        onChange={(_, option) => {
                                            setChangedBilling(option as { label: string; value: number })
                                        }}
                                        page={billingsPage}
                                        setPage={setBillingsPage}
                                        data={billingsRes}
                                        value={selectedBilling}
                                        allowClear
                                        showSearch={false}
                                        optionsMapper={({ name, id }) => ({ value: id.toString(), label: name })}
                                        dropdownRender={(menu) => (
                                            <>
                                                {menu}
                                                {Boolean(billingsRes?.data?.length) && (
                                                    <>
                                                        <Divider style={{ margin: '8px 0' }} />
                                                        <div className='p-4 flex justify-end'>
                                                            <Button
                                                                type="text"
                                                                icon={<PlusOutlined />}
                                                                onClick={() => {
                                                                    onCreateBilling()
                                                                    setIsDropdownOpen(false)
                                                                }}
                                                            >
                                                                Create Billing Account
                                                            </Button>
                                                        </div>
                                                    </>
                                                )}
                                            </>
                                        )}
                                        open={isDropdownOpen} // Controls dropdown visibility
                                        onDropdownVisibleChange={(open) => setIsDropdownOpen(open)}
                                    />
                                </Tooltip>
                            </div>
                        ) : (
                            <div>
                                <span className='text-regular-14 text-neutral-8 mb-4'>Connect billing to the event</span>
                                <InfiniteSelect
                                    id="event-billing-content__billing"
                                    placeholder="Select from the existing billings"
                                    style={{ width: '100%' }}
                                    filterOption={false}
                                    notFoundContent={
                                        isBillingsResLoading || isBillingsResFetching ? (
                                            <Spin size="small" />
                                        ) : (
                                            <div>There is no Billing Accounts</div>
                                        )
                                    }
                                    isLoading={isBillingsResLoading || isBillingsResFetching}
                                    listHeight={160}
                                    onChange={(_, option) => {
                                        setChangedBilling(option as { label: string; value: number });
                                    }}
                                    page={billingsPage}
                                    setPage={setBillingsPage}
                                    data={billingsRes}
                                    value={selectedBilling}
                                    allowClear
                                    showSearch={false}
                                    optionsMapper={({ name, id }) => ({ value: id.toString(), label: name })}
                                    dropdownRender={(menu) => (
                                        <>
                                            {menu}
                                            {Boolean(billingsRes?.data?.length) && (
                                                <>
                                                    <Divider style={{ margin: '8px 0' }} />
                                                    <div className='flex'>
                                                        <Button
                                                            type="text"
                                                            icon={<PlusOutlined />}
                                                            onClick={() => {
                                                                onCreateBilling()
                                                                setIsDropdownOpen(false)
                                                            }}
                                                        >
                                                            Create Billing Account
                                                        </Button>
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    )}
                                    open={isDropdownOpen} // Controls dropdown visibility
                                    onDropdownVisibleChange={(open) => setIsDropdownOpen(open)}
                                />
                            </div>

                        )}
                        <StyledAlert
                            className="event-billing-content__alert"
                            message={(
                                <div>
                                    Billing Account cannot be changed after ad campaigns have been launched.
                                    <br />
                                    Learn more
                                    {' '}
                                    <Link
                                        to={HELP_CENTER_LINK} target="_blank">
                                        <span className='text-link'>in our Help Center</span>
                                        <ArrowIcon />
                                    </Link>
                                </div>
                            )}
                            type="info"
                            showIcon
                        />

                    </div>
                )}
                {!isDataLoading && !billingsRes?.data?.length && (
                    <EmptyBilling onCreateBilling={onCreateBilling} />
                )}
            </article>
            <ChangeBillingModal
                isOpen={Boolean(changedBilling)}
                onClose={onCloseChangeBillingModal}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onChange={handleChangeBilling}
                billing={changedBilling}
                isLoading={isLoading}
            />
            {isEventBillingDrawerOpen
                ?
                <EventBillingDrawer
                    isOpen={isEventBillingDrawerOpen}
                    onClose={() => setIsEventBillingDrawerOpen(false)}
                />
                :
                null}
        </>
    )
}

export default EventBilling

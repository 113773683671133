import { Badge, Button, DatePicker, Spin } from "antd";
import { FiltersKeys, WithdrawalFiltersType } from "@pages/EventRolePages/Billing/types.ts";
import FilterButtonMobileIcon from "@assets/buttons/mobile/FilterButtonMobileIcon.tsx";
import dayjs from "dayjs";
import ExitCrossIcon from "@assets/ExitCrossIcon.tsx";
import React, { useCallback } from "react";
import { useBreakpoints } from "@hooks/browser";
import { useGetFiltersByEnumQuery } from "@store/main/enums/enums.api.ts";
import { PlusOutlined } from "@ant-design/icons";
import { menuItemSelectedIcon } from "@shared/menuItemSelectedIcon.tsx";
import SelectWithAllOption from "@components/SelectWithAllOption/SelectWithAllOption.tsx";

type Props = {
    filters: WithdrawalFiltersType
    setFilters: (filters: (prev: WithdrawalFiltersType) => WithdrawalFiltersType) => void | WithdrawalFiltersType
    setOpenFilter: (isOpen: boolean) => void
    setInitialFilters: () => void
    isDrawer?: boolean
    onModalOpen: () => void
}

const { RangePicker } = DatePicker;

const WithdrawalFilters = ({
    isDrawer,
    filters,
    setFilters,
    setOpenFilter,
    setInitialFilters,
    onModalOpen
}: Props) => {
    const isMd = useBreakpoints().md
    const isLg = useBreakpoints().lg

    const { data: statusRes, isLoading: isStatusLoading } = useGetFiltersByEnumQuery({ name: 'withdrawal_status' })

    const isFilterApplied =
        filters?.status?.length ||
        (filters?.dateFrom && filters.dateTo)

    const onDateChange = (dateStrings: string[]) => {
        setFilters((prev) => ({
            ...prev,
            dateFrom: dateStrings[0],
            dateTo: dateStrings[1]
        }))
    }

    const handleChangeFilter = useCallback((field: FiltersKeys, value: string[] | boolean) => {
        setFilters((prev) => ({ ...prev, [field]: value }))
    }, [])

    const disabledDate = (current: dayjs.Dayjs) => {
        return !!filters?.dateTo && current > dayjs(filters.dateTo);
    };

    return (
        <div className={`flex gap-8 mb-24 organizer-billing-page__reports__filters ${isDrawer ? 'organizer-billing-page__reports__filters-drawer' : 'organizer-billing-page__withdrawal__filters-panel'}`}>
            <div className='flex gap-8 mr-auto'>
                {isDrawer && (
                    <div className="organizer-billing-page__reports__filters-label">
                        Status
                    </div>
                )}
                {(isDrawer || (isMd && !isDrawer)) && (
                    <SelectWithAllOption
                        id="organizer-billing-page__partners"
                        style={isDrawer ? { width: '100%', marginBottom: '16px' } : { width: '200px' }}
                        placeholder='All'
                        maxTagCount="responsive"
                        mode="multiple"
                        showSearch={false}
                        allowClear
                        filterOption={false}
                        notFoundContent={isStatusLoading ? <Spin size="small" /> : <div>There is no types</div>}
                        loading={isStatusLoading}
                        value={filters.status}
                        onChange={(value: string[]) => {
                            handleChangeFilter(FiltersKeys.status, value)
                        }}
                        fieldNames={{ label: 'translate', value: 'id' }}
                        options={statusRes?.data}
                        className="organizer-billing-page__infinite-select__status infinite-select"
                        popupClassName='organizer-billing-page__select-with-icon'
                        menuItemSelectedIcon={menuItemSelectedIcon}
                    />
                )}
                {!isMd && !isDrawer && (
                    <button
                        className='no-style organizer-billing-page__reports__filter-button'
                        onClick={() => setOpenFilter(true)}
                    >
                        <Badge
                            dot={!!isFilterApplied}
                            status="processing"

                        >
                            <FilterButtonMobileIcon />
                        </Badge>
                    </button>
                )}
                {isDrawer && (
                    <div className="organizer-billing-page__reports__filters-label">
                        Time range
                    </div>
                )}

                <RangePicker
                    style={isDrawer ? { width: '100%', marginBottom: '16px' } : {}}
                    className="analytics-page__range-picker"
                    format="YYYY-MM-DD"
                    value={[filters?.dateFrom ? dayjs(filters.dateFrom) : null, filters?.dateTo ? dayjs(filters.dateTo) : null]}
                    onChange={(_, dateStrings) => onDateChange(dateStrings)}
                    disabledDate={disabledDate}
                />

                {isMd && (
                    <Button
                        size='small'
                        type="text"
                        onClick={setInitialFilters}
                        className="analytics-page__reset-button"
                    >
                        <ExitCrossIcon fill="#898A8C" />
                        Reset
                    </Button>
                )}
            </div>
            {isLg && (
                <Button
                    type="primary"
                    onClick={onModalOpen}
                >
                    <PlusOutlined />
                    Create request
                </Button>
            )}
        </div>
    )
}

export default WithdrawalFilters

import { CloseOutlined } from '@ant-design/icons';
import { Button, Drawer, Modal } from 'antd';
import React, { useCallback, useContext, useState } from 'react'
import './styles.scss'
import SettingsItem from './SettingsItem';
import { LandingEditorContext } from '@contexts/LandingEditorContext';
import { PartnerLandingSectionLayout } from '@store/type-partner/landings/models';
import { OptionIds } from "@pages/PartnerRolePages/LandingPageConstructor/constants.ts";

interface Props {
    isOpen: boolean;
    layoutData: PartnerLandingSectionLayout
}

const SectionSettingsDrawer: React.FC<Props> = ({
    isOpen,
    layoutData
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const {
        isLoading,
        setLayoutSettingsData,
        onSaveSectionSettingsClick,
        landingUrl,
        setIsPublishDropdownOpen,
        isSaveDisabled
    } = useContext(LandingEditorContext);

    const handleSaveClick = useCallback(() => {
        if (!landingUrl) {
            setIsPublishDropdownOpen(true)
        } else {
            onSaveSectionSettingsClick()
        }
    }, [landingUrl, onSaveSectionSettingsClick, setIsPublishDropdownOpen])

    const optionsList = Object.entries(layoutData.options)
        .map(([key]) => (key as OptionIds));

    const onClose = () => {
        if (isSaveDisabled) {
            setLayoutSettingsData(null)
        } else {
            setIsModalOpen(true)
        }
    }

    return (
        <>
            <Drawer
                title="Section settings"
                closeIcon={false}
                placement="right"
                open={isOpen}
                autoFocus={false}
                mask
                maskClosable
                width={450}
                className='editor-drawer'
                onClose={onClose}
                bodyStyle={{
                    'display': 'flex',
                    'flexDirection': 'column',
                    'justifyContent': 'space-between',
                }}
                maskStyle={{ 'opacity': 0, 'top': '62px' }}
                rootStyle={{ 'top': '62px' }}
                contentWrapperStyle={{ boxShadow: 'none', borderLeft: '2px solid #f7f7f7' }}
                extra={(
                    <CloseOutlined
                        onClick={onClose}
                    />
                )}
                footer={(
                    <>
                        <Button
                            onClick={onClose}
                            size="large"
                            loading={isLoading}
                            disabled={isLoading}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleSaveClick}
                            type="primary"
                            size="large"
                            loading={isLoading}
                            disabled={isLoading || isSaveDisabled}
                        >
                            Save
                        </Button>
                    </>
                )}
            >
                <div className='editor-book__content'>
                    {optionsList.map(((id) => {
                        return (
                            <SettingsItem
                                id={id}
                                layout={layoutData}
                            />
                        )
                    }))}
                </div>
            </Drawer>
            <Modal
                className="section-settings__confirm-modal"
                centered
                title="Unsaved сhanges"
                open={isModalOpen}
                okText="Exit without saving"
                okButtonProps={{
                    size: 'large'
                }}
                cancelButtonProps={{
                    size: 'large'
                }}
                onOk={() => setLayoutSettingsData(null)}
                onCancel={() => setIsModalOpen(false)}
                width={440}
            >
                You have unsaved changes. Are you sure you want to close this panel?
            </Modal>
        </>

    )
}

export default SectionSettingsDrawer

import IslandLayout from '@layouts/IslandLayout';
import { Button, Checkbox, DatePicker, Form, Input, message, Select, Tooltip } from 'antd'
import React, { useContext, useEffect, useRef, useState, useMemo } from 'react'
import './styles.scss'
import RadioGoal from '../RadioGoal';
import AssertForm from './AssetForm';
import RadioChannel from '../RadioChannel';
import dayjs from 'dayjs';
import { RangePickerProps } from 'antd/es/date-picker';
import { CampaignEditorContext } from '@contexts/CampaignEditorContext';
import TagInput from '../TagInput';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CustomButton from '@components/CustomButton';
import BoostProBanner from '@components/BoostProBanner/BoostProBanner';
import { useAuth } from '@contexts/AuthContext';
import EnableBoostProDrawer from '@components/Subscriptions/BoostPro/drawers/EnableBoostProDrawer';
import SuccessModal from '@components/Subscriptions/BoostPro/SuccessModal';
import { StripeContext } from '@contexts/StripeContext';
import { useGetPartnerLandingsByEventIdQuery } from '@store/type-partner/landings/landings.api';
import { usePostDefaultBillingMethodIdMutation } from '@store/main/payment/payment.api';
import { usePostSubscriptionMutation } from '@store/main/subscription/subscription.api';
import { Campaign } from '@store/main/organization/models';
import { useDispatch } from 'react-redux';
import { partnerCampaignsActions } from '@store/type-partner/campaigns/campaigns.slice';
import { LANDINGS_PLACEHOLDER } from '@pages/PartnerRolePages/Campaigns/constants';
import LandingFAQ from '@pages/PartnerRolePages/Campaigns/components/LandingFAQ/LandingFAQ';
import { useGetParnerAudiencesQuery } from '@store/type-partner/events/partner-event.api';
import { INIT_PAGE } from "@components/InfiniteScrollTable/constants";
import sendAnalytics from "@hooks/sendAnalytics/sendAnalytics";
import { GTMEventName } from "@hooks/sendAnalytics/constants";
import EnableBoostProDrawerMobile from '@components/Subscriptions/BoostPro/drawers/EnableBoostProDrawerMobile';
import { useBreakpoints } from '@hooks/browser';
import { TourContext } from "@contexts/TourContext.tsx";
import { CampaignsTourSteps, descriptionCampaign, TourStages, } from "@components/TourComponent/constants.tsx";
import {
    scrollToElement,
    scrollToElementCenter,
    setTooltipCoordinatesPlacementTop
} from "@components/TourComponent/helpers.ts";
import NewTourComponent from "@components/TourComponent/NewTourComponent.tsx";
import { GetEventByIdFromCatalogDataItem } from '@store/type-partner/events-catalog/models';
import { getCampaignDefaultName } from "@pages/PartnerRolePages/Campaigns/CampaignEditorPage/helpers.ts";
import { SelectOption } from "@shared/types.ts";

const CampaignForm = ({
    eventId,
    eventData
}: {
    eventId: string;
    eventData?: GetEventByIdFromCatalogDataItem
}) => {
    const { user, reFetchUser } = useAuth()
    const isDesktop = useBreakpoints().md
    const [onSubscribe] = usePostSubscriptionMutation()
    const { data: audiencesRes, isLoading: isAudiencesLoading, refetch: getAudiences } = useGetParnerAudiencesQuery({ eventId }, { skip: !eventId })
    const { data: fetchedLandings, isLoading: landingsLoading } = useGetPartnerLandingsByEventIdQuery({ id: +eventId, page: INIT_PAGE }, { skip: !eventId })

    const [postDefault] = usePostDefaultBillingMethodIdMutation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { pathname } = useLocation()
    const { campaignId } = useParams()
    const isPro = user && user?.organization?.subscriptions && user.organization.subscriptions.some(item => Number(item.status?.id) === 1 && item.status.name === 'active') || false

    const [isEnableProModalOpen, setIsEnableProModalOpen] = useState(false)
    const [landingOptions, setLandingOptions] = useState<{ value: string, label: string }[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const [successModalDate, setSuccessModalDate] = useState<string | boolean>('')
    const [isTimerPassed, setIsTimerPassed] = useState(false)
    // TOFIX: check if needed
    const [isDisabledFinishDate, setDisabledFinishDate] = useState(false)
    const [isDatesOpen, setDatesOpen] = useState(false)
    const [isScrolled, setIsScrolled] = useState(false)
    const [isBoostProBannerOpen, setIsBoostProBannerOpen] = useState(!isPro && !campaignId)
    const [landingPreview, setLandingPreview] = useState<Record<string, string> | null>(null)

    const today = dayjs().format('YYYY-MM-DD');
    const date = eventData?.date || { started_at: today, finished_at: today };

    const campaignGoalRef = useRef(null);
    const audienceRef = useRef(null);
    const scheduleRef = useRef(null);
    const adPlatformRef = useRef(null);
    const adCreativesRef = useRef(null);
    const landingPageRef = useRef(null);

    const {
        formValues,
        formErrors,
        handleFormChange,
        campaignStatus
    } = useContext(CampaignEditorContext);
    const { selectedCardId } = useContext(StripeContext);

    const {
        campaignTourStep,
        setCampaignTourStep,
        isInfoTourWasOpened,
        setIsInfoTourOpen,
        blockScroll,
        tourStage,
        onFinishTour,
        setTourStage
    } = useContext(TourContext);

    useEffect(() => {
        if (tourStage === TourStages.campaign) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            if (isDesktop) {
                blockScroll()
            }
            setTimeout(() => {
                scrollToElementCenter(campaignGoalRef.current, 200)
                setIsScrolled(true)
            }, 900)
            setTimeout(() => {
                setTooltipCoordinatesPlacementTop({ current: campaignGoalRef.current, id: 'campaign-form-tour-component', isCampaign: true, isDesktop, isMobileCampaign: !isDesktop })
                setIsTimerPassed(true)
            }, 1500)
        }
    }, [tourStage]);

    const audiencesList = audiencesRes?.data || []

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        eventId && void getAudiences()
    }, [])

    const selectedLanding = useMemo(() => {
        const emptyLanding = {} as SelectOption
        const landingOption = formValues.ads[0]?.landing ? landingOptions.find(item => item.value === formValues.ads[0]?.landing.toString()) || emptyLanding : emptyLanding
        return {
            ...landingOption,
            placeholder: landingOption?.label || LANDINGS_PLACEHOLDER
        }
    }, [formValues, landingOptions])

    useEffect(() => {
        setLandingPreview(selectedLanding)
    }, [selectedLanding]);

    useEffect(() => {
        if (fetchedLandings?.data) {
            const options = fetchedLandings?.data.map(landing => {
                return {
                    value: (landing.id).toString(),
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    label: landing?.title || '',
                    page: landing?.page || ''
                }
            })
            const urlValue = `${pathname}/landing`
            const newOptions = options?.length ? [...options, { value: urlValue, label: '+ Add landing', type: 'link' }] : options
            setLandingOptions(newOptions)
        }
    }, [fetchedLandings, eventId, pathname])

    const disabledStartDate: RangePickerProps['disabledDate'] = (current) => {
        // Can not select days before today and today
        return Boolean(formValues?.schedule?.started_at && current < dayjs().endOf('day'))
    };

    const disabledEndDate: RangePickerProps['disabledDate'] = (current) => {
        // Can not select days before the start date
        return Boolean(formValues?.schedule?.started_at && current < dayjs(formValues.schedule.started_at));
    };

    const onLandingUpdate = (id: string, option?: { value: string, label: string, type: string }) => {
        if (option?.type === 'link') {
            dispatch(partnerCampaignsActions.setCampaignsEditorData({ campaignEditor: formValues, selectedEvent: eventId, campaignId }))
            navigate(option.value)
            return
        }
        const next = {
            ...formValues,
            ads: [
                {
                    ...formValues.ads[0],
                    landing: +id
                }
            ]
        };
        //TOFIX: ts errors
        handleFormChange(next);
    }

    const onCloseEnableModal = () => {
        setIsEnableProModalOpen(false)
    }

    const onEnablePro = async () => {
        setIsLoading(true)
        await postDefault({ id: selectedCardId })
        try {
            const response = await onSubscribe()
            if ('error' in response) {
                return void message.open({
                    type: 'error',
                    content: (response as { error: { data: { message: string } } })?.error?.data?.message || 'Subscription error',
                });
            }
            if (response) {
                sendAnalytics(GTMEventName.enableProSubscription)
                setSuccessModalDate(response?.data?.data?.date || true)
            }
        } catch (error) {
            return void message.open({
                type: 'error',
                content: 'Subscription error',
            });
        }
        return setIsLoading(false)
    }

    const onAddLandings = () => {
        dispatch(partnerCampaignsActions.setCampaignsEditorData({ campaignEditor: formValues, selectedEvent: eventId, campaignId }))
        const urlValue = `${pathname}/landing`
        navigate(urlValue)
    }

    const onAddSubscription = (id: string | number) => {
        switch (id) {
            case 1:
                return setIsEnableProModalOpen(true)
            default:
                break;
        }
    }

    const onSuggestScheduleClick = (type: string) => {
        if (!eventData) {
            return null;
        }
        const { started_at: start, finished_at: finish } = date || {}; // Handle potential undefined date
        const started_at = dayjs(start).format('YYYY-MM-DD')
        const finished_at = dayjs(finish).format('YYYY-MM-DD')
        setDisabledFinishDate(false)

        switch (type) {
            case 'pre':
                handleFormChange({ ...formValues, schedule: { started_at: today, finished_at: start } })
                break;
            case 'on':
                handleFormChange({ ...formValues, schedule: { started_at, finished_at } })
                break;
            case 'post':
                handleFormChange({ ...formValues, schedule: { started_at: finished_at, finished_at: null } })
                setDisabledFinishDate(true)
                break;

            default:
                break;
        }
        return null; // Return null if no case is matched
    }

    return (
        <>
            <Form
                onValuesChange={(_, fields: Campaign) => {
                    //TOFIX: ts errors
                    handleFormChange(fields)
                }}
                initialValues={formValues}
            >
                <div className={`max-w-l ${tourStage === TourStages.campaign ? 'campaign-create__page' : ''}`}>
                    <p className={`text-regular-14 label text-neutral-8 ${formErrors['title'] ? 'error' : ''}`}>
                        Campaign name
                    </p>
                    <Input
                        count={{
                            show: true,
                            max: 50,
                        }}
                        onChange={(event) => handleFormChange({ ...formValues, title: event?.target?.value || '' })}
                        value={formValues.title || ''}
                        placeholder={getCampaignDefaultName(eventData)}
                        status={formErrors['title'] ? 'error' : ''}
                    />
                    {isBoostProBannerOpen && (
                        // {!isPro && !campaignId && (
                        <BoostProBanner
                            onClose={() => setIsBoostProBannerOpen(false)}
                            onEnablePro={() => setIsEnableProModalOpen(true)}
                        />
                    )}

                    <div className='campaign-create__settings'>
                        <p className='text-title-30 campaign-create__title'>
                            Campaign Settings
                        </p>

                        <IslandLayout refProp={campaignGoalRef}>
                            <p className='island-title'>
                                Campaign Goals
                            </p>
                            <article className='create-campaign-checkboxex'>
                                <RadioGoal
                                    goalValue={formValues.goal!}
                                    onClick={(goal) => handleFormChange({ ...formValues, goal })}
                                    onAddSubscription={onAddSubscription}
                                />
                            </article>
                        </IslandLayout>

                        <IslandLayout refProp={audienceRef}>
                            {!isAudiencesLoading && !audiencesList.length
                                ? (
                                    <div className='campaign-create__audience'>
                                        <div>
                                            <p className='island-title'>
                                                Audience
                                            </p>
                                            <article className='flex items-center'>
                                                <p className='text-regular-14 label text-subdued'>
                                                    This event does not have any audience yet
                                                    {' '}
                                                </p>
                                            </article>
                                        </div>
                                    </div>
                                )
                                : (
                                    <div className='campaign-create__audience'>
                                        <div>
                                            <p className='island-title'>
                                                Audience
                                            </p>
                                            <article className='flex items-center'>
                                                <p className='text-regular-14 label text-subdued'>
                                                    Select from an existing audience to determine the types of people that can see your ads.
                                                </p>
                                            </article>
                                        </div>
                                    </div>
                                )}
                            {audiencesList.length
                                ? (
                                    <div className='campaign-create__audience-container'>
                                        <p className='text-regular-14 label'>
                                            Segment Attributes
                                        </p>
                                        <TagInput
                                            options={audiencesList as { id: number, name: string }[]}
                                            onChange={(ids) => handleFormChange({ ...formValues, audiences: ids as unknown as number[] })}
                                            formIdsValues={formValues?.audiences || []}
                                        />
                                        <div className='divider' />
                                        <Checkbox
                                            className="mb-12 campaign-create__audience__checkbox"
                                            checked={formValues?.audience_extend}
                                            onChange={(e) => handleFormChange({ ...formValues, audience_extend: e.target.checked })}
                                            id="audience_extend"
                                        >
                                            Enable Audience Expansion
                                        </Checkbox>
                                        <p className='text-small text-subdued'>
                                            Reach people who are similar to your target audience, based on attributes like job titles, companies, or skills. Learn more
                                        </p>

                                    </div>
                                )
                                :
                                null}
                        </IslandLayout>

                        <IslandLayout refProp={scheduleRef}>
                            <p className='island-title'>
                                Schedule
                            </p>
                            <p className='text-regular-14 text-neutral-8 campaign-create__disclaimer'>
                                Your ads will only run during the specified period. You will not be able to change the start date once the company is launched.
                                {' '}
                            </p>

                            <div className='campaign-create__budget-form'>
                                <div
                                    className='campaign-create__budget-form-date'
                                >
                                    <p className='text-regular-14 text-neutral-8'>
                                        Start Date
                                    </p>
                                    {campaignStatus?.name === 'active'
                                        ? (
                                            <Tooltip
                                                title="You can't change the start date once the campaign is launched"
                                                color="white"
                                                placement='bottom'
                                                showArrow={false}
                                                trigger='click'
                                                overlayClassName='tooltip-general billing-tab-tooltip'
                                                overlayStyle={{ color: 'black !important', width: '220px', padding: '12px 12px 12px 6px' }} // To change the text color inside the tooltip
                                            >
                                                <div>
                                                    <DatePicker
                                                        value={formValues.schedule.started_at ? dayjs(formValues.schedule.started_at) : ''}
                                                        className='campaign-create__budget-form-date'
                                                        onChange={(_, i) => handleFormChange({
                                                            ...formValues,
                                                            schedule: {
                                                                ...formValues.schedule,
                                                                started_at: i as string
                                                            }
                                                        })}
                                                        placeholder='Start Date'
                                                        allowClear
                                                        disabledDate={disabledStartDate}
                                                        disabled={campaignStatus?.name === 'active'}
                                                        status={formErrors['schedule.started_at'] ? 'error' : ''}
                                                    />
                                                </div>
                                            </Tooltip>
                                        )
                                        : (
                                            <DatePicker
                                                value={formValues.schedule.started_at ? dayjs(formValues.schedule.started_at) : ''}
                                                className='campaign-create__budget-form-date'
                                                onChange={(_, i) => handleFormChange({
                                                    ...formValues,
                                                    schedule: {
                                                        ...formValues.schedule,
                                                        started_at: i as string
                                                    }
                                                })}
                                                placeholder={today}
                                                allowClear
                                                disabledDate={disabledStartDate}
                                                status={formErrors['schedule.started_at'] ? 'error' : ''}
                                            />
                                        )}

                                    {formErrors['schedule.started_at'] && (
                                        <p className={`text-regular-14 label ${formErrors['schedule.started_at'] ? 'error' : ''}`}>
                                            Invalid date.
                                        </p>
                                    )}
                                </div>
                                <div
                                    className='campaign-create__budget-form-date'
                                >
                                    <p className='text-regular-14 text-neutral-8'>
                                        Finish Date
                                    </p>
                                    <DatePicker
                                        value={formValues.schedule.finished_at ? dayjs(formValues.schedule.finished_at) : ''}
                                        className='campaign-create__budget-form-date'
                                        onChange={(_, i) => handleFormChange({
                                            ...formValues,
                                            schedule: {
                                                ...formValues.schedule,
                                                finished_at: i as string
                                            }
                                        })}
                                        placeholder={formValues.schedule.finished_at === null ? 'No limit' : today}
                                        disabledDate={disabledEndDate}
                                        // placeholder={isDisabledFinishDate ? 'No Finish Date' : 'Finish Date'}
                                        allowClear
                                        showNow={false}

                                        open={isDatesOpen}
                                        onOpenChange={setDatesOpen}
                                        status={formErrors['schedule.started_at'] ? 'error' : ''}
                                        renderExtraFooter={() => (
                                            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '8px 12px' }}>
                                                <Button
                                                    type="link"
                                                    onClick={() => {
                                                        handleFormChange({
                                                            ...formValues,
                                                            schedule: {
                                                                ...formValues.schedule,
                                                                finished_at: today // Or handle it as needed when no finish date is selected
                                                            }
                                                        })
                                                        setDisabledFinishDate(true)
                                                        setTimeout(() => setDatesOpen(false));
                                                    }}
                                                >
                                                    Today
                                                </Button>
                                                <Button
                                                    // type="link"
                                                    onClick={() => {
                                                        handleFormChange({
                                                            ...formValues,
                                                            schedule: {
                                                                ...formValues.schedule,
                                                                finished_at: null as unknown as string // Or handle it as needed when no finish date is selected
                                                            }
                                                        })
                                                        setTimeout(() => setDatesOpen(false));
                                                    }}
                                                >
                                                    No finish date
                                                </Button>
                                            </div>
                                        )}
                                    />
                                    {formErrors['schedule.finished_at'] && (
                                        <p className={`text-regular-14 label ${formErrors['schedule.finished_at'] ? 'error' : ''}`}>
                                            Invalid date.
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div className='flex items-center mt-8'>
                                <p className='text-regular-14 text-neutral-8 '>
                                    Suggestions:
                                </p>
                                {date.started_at > today ?
                                    (
                                        <span
                                            onClick={() => onSuggestScheduleClick('pre')}
                                            className='cursor-pointer text-link mx-4'
                                        >
                                            Pre-event,
                                        </span>
                                    )
                                    :
                                    null}
                                <span
                                    onClick={() => onSuggestScheduleClick('on')}
                                    className='cursor-pointer text-link mx-4'
                                >
                                    On-event,
                                </span>
                                <span
                                    onClick={() => onSuggestScheduleClick('post')}
                                    className='cursor-pointer text-link mx-4'
                                >
                                    Post-event
                                </span>
                            </div>

                        </IslandLayout>
                        <IslandLayout refProp={adPlatformRef}>
                            <p className='island-title'>
                                Bidding
                            </p>
                            <p className='text-regular-14 label text-neutral-8'>
                                Set your bidding strategy to match your budget and goals.
                            </p>
                            <RadioChannel />
                        </IslandLayout>

                    </div>

                    <p className='text-title-30'>
                        Creative Assets
                    </p>
                    <div>
                        <AssertForm adCreativesRef={adCreativesRef} />
                    </div>

                    <p className='text-title-30'>
                        Landing page
                    </p>

                    <IslandLayout refProp={landingPageRef}>
                        <p className={`campaign-create__landing-title text-regular-14 label ${formErrors['ads.0.landing'] ? 'error' : ''}`}>
                            Landing page
                        </p>
                        <article className='campaign-create__landing-select'>
                            <div className="campaign-create__landing-select__left">
                                <Select
                                    loading={landingsLoading}
                                    style={{ width: '100%', maxWidth: '608px' }}
                                    placeholder={<span
                                        className='text-regular-14'>{selectedLanding.placeholder}</span>}
                                    options={landingOptions}
                                    onChange={(id: string, option) => {
                                        onLandingUpdate(id, option)
                                    }}
                                    popupClassName='campaign-create__landing-select__popup'
                                    notFoundContent={(
                                        <div className='campaign-create__landing-select__empty-content'>
                                            <b>You haven't added any landing page</b>
                                            <span>Click on the button to create your first landing page</span>
                                            <CustomButton
                                                type='primary'
                                                title='+ Add landing'
                                                onClick={onAddLandings}
                                            />
                                        </div>
                                    )}
                                    onBlur={() => {
                                        setLandingPreview(selectedLanding?.value ? selectedLanding : null)
                                    }}
                                    optionRender={({ key, label, data }) => {
                                        const { page, label: dataLabel, type } = data
                                        return (
                                            <div
                                                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                                                onMouseEnter={() => setLandingPreview(type === 'link' ? null : { page, label: dataLabel })}
                                                key={key}
                                            >{label}
                                            </div>
                                        )
                                    }}
                                />
                                {formErrors['ads.0.landing'] && (
                                    <p className='text-regular-14 label error'>
                                        Select an existing landing page or add a new one
                                    </p>
                                )}
                                <div className='mt-16'>
                                    <CustomButton
                                        onClick={onAddLandings}
                                        title='+ Add landing'
                                    />
                                </div>
                                <LandingFAQ />
                            </div>
                            <div className="campaign-create__landing-select__right">
                                {landingPreview && (
                                    <div
                                        className='moderation-table__landing-page campaign-create__landing-select__preview'
                                        onClick={(e) => {
                                            e.stopPropagation()
                                        }}
                                    >
                                        <iframe
                                            title={`moderation-table_${landingPreview.page}`}
                                            className='moderation-table__landing-page__iframe'
                                            src={landingPreview.page}
                                        />
                                        <div className="campaign-create__landing-select__label">
                                            {landingPreview.label}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </article>
                    </IslandLayout>
                </div>
            </Form>
            {isEnableProModalOpen && isDesktop
                ? (
                    <EnableBoostProDrawer
                        isOpen={isEnableProModalOpen}
                        onClose={onCloseEnableModal}
                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                        onConfirm={onEnablePro}
                        isLoading={isLoading}
                    />
                )
                :
                null}
            {isEnableProModalOpen && !isDesktop
                ? (
                    <EnableBoostProDrawerMobile
                        isOpen={isEnableProModalOpen}
                        onClose={onCloseEnableModal}
                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                        onConfirm={onEnablePro}
                        isLoading={isLoading}
                    />
                )
                :
                null}
            <SuccessModal
                isOpen={Boolean(successModalDate)}
                onClose={() => {
                    setSuccessModalDate('')
                    void reFetchUser()
                }}
                date={successModalDate}
            />
            <NewTourComponent
                isCampaign
                isTransition
                isArrowBottom
                mask
                animationAtEnd="hide"
                id="campaign-form-tour-component"
                open={isTimerPassed && tourStage === TourStages.campaign && isScrolled}
                onClose={() => {
                    onFinishTour()
                    window.scrollTo({ top: 0, behavior: 'smooth' })
                }}
                onChange={(step) => {
                    if (step > CampaignsTourSteps.landingPage) {
                        setTourStage(TourStages.video)
                        window.scrollTo({ top: 0, behavior: 'smooth' })
                        if (!isInfoTourWasOpened) {
                            setIsInfoTourOpen(true)
                        }
                        return
                    }
                    if (step === CampaignsTourSteps.audience) {
                        const elementMask = document.querySelector(`#campaign-form-tour-component__mask`)
                        if (elementMask) {
                            elementMask.classList.remove('tour-component-wrapper__mask__hidden')
                        }
                        scrollToElement({ current: audienceRef.current, prev: campaignGoalRef.current, id: 'campaign-form-tour-component' })
                    }
                    if (step === CampaignsTourSteps.schedule) {
                        scrollToElement({ current: scheduleRef.current, prev: audienceRef.current, id: 'campaign-form-tour-component' })
                    }
                    if (step === CampaignsTourSteps.adPlatform) {
                        scrollToElement({ current: adPlatformRef.current, prev: scheduleRef.current, id: 'campaign-form-tour-component' })
                    }
                    if (step === CampaignsTourSteps.adCreatives) {
                        scrollToElement({ current: adCreativesRef.current, prev: adPlatformRef.current, id: 'campaign-form-tour-component', propTop: 82, isBottomHidden: true })
                    }
                    if (step === CampaignsTourSteps.landingPage) {
                        const elementMask = document.querySelector(`#campaign-form-tour-component__mask`)
                        if (elementMask) {
                            elementMask.classList.remove('tour-component-wrapper__mask__hidden')
                        }
                        scrollToElement({ current: landingPageRef.current, prev: adCreativesRef.current, propTop: 82, id: 'campaign-form-tour-component' })
                    }
                    setCampaignTourStep(step)
                }}
                current={campaignTourStep}
                totalCount={6}
                description={descriptionCampaign[campaignTourStep]}
                buttonText={campaignTourStep === CampaignsTourSteps.landingPage ? 'Got it' : 'Next'}
            />
        </>
    )
}

export default CampaignForm

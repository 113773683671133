import { imageAction } from "@pages/PartnerRolePages/LandingPageConstructor/constants.ts";
import SettingsImageUploader from "@pages/PartnerRolePages/LandingPageConstructor/components/SettingsImageUploader";
import { Input } from "antd";
import CornerIcon from "@assets/lpEditor/CornerIcon.tsx";
import SocialLinksItem
    from "@pages/PartnerRolePages/LandingPageConstructor/components/SocialLinksItem/SocialLinksItem.tsx";
import React, { useState } from "react";
import {
    CardType,
} from "@pages/PartnerRolePages/LandingPageConstructor/models.ts";

type Props = {
    cards?: { card: CardType[] }
    onOptionValueChange: (value: string | string[] | { card: CardType[] }, key?: string) => void
}

const CardsOption = ({ cards, onOptionValueChange }: Props) => {
    const [imageError, setImageError] = useState(false)
    const [cornerRadiusValue, setCornerRadiusValue] = useState(cards?.card?.[0]?.cornerRadius)

    const onCardValueChange = (value: { value: string | string[] } | Record<string, string> | string, key: string, index: number) => {
        const newCards = cards?.card?.map((item, idx) => {
            return (idx === index || key === 'cornerRadius') ? {
                ...item,
                [key]: value
            } : item
        }) || []
        onOptionValueChange({ card: newCards })
    }

    return cards?.card?.length && cards?.card?.map((card, index) => {
        const { image, cornerRadius } = card
        return (
            <React.Fragment key={index}>
                <div className='setting-drawer__type-label'>
                    Card
                    {' '}
                    {index + 1}
                </div>
                <div className="flex-col gap-10">
                    <div
                        className={`setting-drawer__background-type__image ${imageError ? 'setting-drawer__background-type__image-error' : ''}`}
                    >
                        <SettingsImageUploader
                            action={imageAction}
                            onChange={(optionValue) => onCardValueChange(optionValue, 'image', index)}
                            url={image || ''}
                            errorCb={() => {
                                setImageError(true)
                            }}
                        />
                    </div>
                    <div>
                        <div className='setting-drawer__type-subtitle'>Corner radius</div>
                        <Input
                            value={cornerRadiusValue}
                            onChange={(e) => {
                                const inputValue = e.target.value
                                setCornerRadiusValue(inputValue)
                            }}
                            prefix={<CornerIcon/>}
                            className="setting-drawer__text-input"
                            placeholder='0 px'
                            defaultValue={cornerRadius || ''}
                            onBlur={(e) => {
                                const inputValue = e.target.value
                                onCardValueChange(inputValue, 'cornerRadius', index)
                            }}
                        />
                    </div>
                    <div className="mb-20">
                        <div className='setting-drawer__type-subtitle'>Social links</div>
                        <SocialLinksItem
                            socialLinks={card?.socialLinks}
                            onCardValueChange={onCardValueChange}
                            index={index}
                        />
                    </div>
                </div>
            </React.Fragment>
        )
    })
}

export default CardsOption

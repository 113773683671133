import { createContext, useState, ReactNode, FC, useCallback, useEffect } from 'react';
import {
    PartnerLandingSectionItem,
    PartnerLandingSectionLayout
} from '@store/type-partner/landings/models';
import {
    ButtonType,
    CardType, PartnerLandingSectionOptions,
} from '@pages/PartnerRolePages/LandingPageConstructor/models';
import { ErrorDataResponse, generateUniqueStringId, removeImageStylesFromHtml } from '@shared/utils';
import { message, notification } from 'antd';
import {
    usePatchPartnerLandingByIdMutation,
    usePostPartnerCreateLandingMutation,
    usePublishLandingMutation,
    useGetPartnerLandingByIdQuery, useGetLandingsLayoutsSectionsQuery
} from '@store/type-partner/landings/landings.api';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { changeVideoUrl } from "@pages/PartnerRolePages/LandingPageConstructor/helpers.ts";
import { OptionIds } from "@pages/PartnerRolePages/LandingPageConstructor/constants.ts";

interface Option {
    name: OptionIds;
    value: string | string[] | null | { card: CardType[] } | { image: string; }[] | { button: ButtonType[] };
    key?: string
}

interface LandingEditorContextType {
    selectedSectionIndex: number | null;
    setSelectedSectionIndex: (idx: number | null) => void;

    editorSections: { id?: string; layout: PartnerLandingSectionLayout }[];
    setEditorSections: (sections: { id?: string; layout: PartnerLandingSectionLayout }[]) => void;

    layoutSettingsData: PartnerLandingSectionLayout | null;
    setLayoutSettingsData: (layout: PartnerLandingSectionLayout | null) => void;

    clickedSectionId: string | null | undefined;
    setClickedSectionId: (id: string | null | undefined) => void;

    updateSectionOptionById: (data: Option) => void;
    updateSectionHtmlById: (data: { id: string, html: string }) => void;
    onSaveSectionSettingsClick: () => void;

    onSectionAddToList: (layout: PartnerLandingSectionLayout, idx?: number) => void;
    onDeleteSectionFromList: (id: string) => void;

    fetchedSections: PartnerLandingSectionItem[];
    setFetchedSections: (sections: PartnerLandingSectionItem[]) => void;

    isAddSectionModalOpen: boolean;
    setIsAddSectionModalOpen: (isOpen: boolean) => void;

    onPublishClick: (statusId: number, transit?: string) => void;

    landingTitle: string;
    setLandingTitle: (title: string) => void;
    landingUrl: string;
    setLandingUrl: (url: string) => void;

    updateLandingHandler: () => void;
    createLandingHandler: () => void;
    isLoading: boolean;
    isPublishedModalOpen: boolean;
    onClosePublishedModal: () => void;
    isPublishLoading: boolean;
    landingErrors: { [key: string]: string[] };
    setLandingErrors: (errors: { [key: string]: string[] }) => void;
    onPreviewClick: () => void;

    // AI EDITOR
    isAiLoading: boolean;
    setAiLoading: (isOpen: boolean) => void;

    isPublishDropdownOpen: boolean;
    setIsPublishDropdownOpen: (isOpen: boolean) => void;

    getLayoutsByLayoutName: (layoutName: string) => { layouts: PartnerLandingSectionLayout[], selectedLayout: PartnerLandingSectionLayout };

    isSaveDisabled: boolean
}

export const LandingEditorContext = createContext<LandingEditorContextType>({
    // EDITOR
    landingTitle: '',
    setLandingTitle: () => { }, // eslint-disable-line @typescript-eslint/no-empty-function
    setLandingErrors: () => { }, // eslint-disable-line @typescript-eslint/no-empty-function
    landingErrors: {},
    landingUrl: '',
    setLandingUrl: () => { }, // eslint-disable-line @typescript-eslint/no-empty-function
    fetchedSections: [],
    setFetchedSections: () => { }, // eslint-disable-line @typescript-eslint/no-empty-function

    // ACTIONS TO STATE
    editorSections: [],
    setEditorSections: () => { }, // eslint-disable-line @typescript-eslint/no-empty-function
    layoutSettingsData: null,
    setLayoutSettingsData: () => { }, // eslint-disable-line @typescript-eslint/no-empty-function

    selectedSectionIndex: null,
    setSelectedSectionIndex: () => { }, // eslint-disable-line @typescript-eslint/no-empty-function
    clickedSectionId: null,
    setClickedSectionId: () => { }, // eslint-disable-line @typescript-eslint/no-empty-function

    onSectionAddToList: () => { }, // eslint-disable-line @typescript-eslint/no-empty-function
    onDeleteSectionFromList: () => { }, // eslint-disable-line @typescript-eslint/no-empty-function

    // ACTIONS TO BE
    onPublishClick: () => { }, // eslint-disable-line @typescript-eslint/no-empty-function
    updateLandingHandler: () => { }, // eslint-disable-line @typescript-eslint/no-empty-function
    createLandingHandler: () => { }, // eslint-disable-line @typescript-eslint/no-empty-function
    updateSectionOptionById: () => { }, // eslint-disable-line @typescript-eslint/no-empty-function
    updateSectionHtmlById: () => { }, // eslint-disable-line @typescript-eslint/no-empty-function
    onSaveSectionSettingsClick: () => { }, // eslint-disable-line @typescript-eslint/no-empty-function
    onPreviewClick: () => { }, // eslint-disable-line @typescript-eslint/no-empty-function

    // LOADERS
    isAiLoading: false,
    setAiLoading: () => { }, // eslint-disable-line @typescript-eslint/no-empty-function
    isLoading: false,
    isPublishLoading: false,

    // MODALS DRAWERS
    isPublishedModalOpen: false,
    onClosePublishedModal: () => { }, // eslint-disable-line @typescript-eslint/no-empty-function
    isAddSectionModalOpen: false,
    setIsAddSectionModalOpen: () => { }, // eslint-disable-line @typescript-eslint/no-empty-function
    isPublishDropdownOpen: false,
    setIsPublishDropdownOpen: () => { }, // eslint-disable-line @typescript-eslint/no-empty-function

    getLayoutsByLayoutName: () => [], // eslint-disable-line @typescript-eslint/no-empty-function

    isSaveDisabled: true,
});

interface LandingEditorContextProviderProps {
    children: ReactNode;
}

export const LandingEditorContextProvider: FC<LandingEditorContextProviderProps> = ({ children }) => {
    const { eventId = '', landingId = '', type = '' } = useParams()
    const { pathname } = useLocation()
    const navigate = useNavigate()

    const [clickedSectionId, setClickedSectionId] = useState<string | null | undefined>(null);
    const [fetchedSections, setFetchedSections] = useState<PartnerLandingSectionItem[]>([]);
    const [editorSections, setEditorSections] = useState<{ id?: string; layout: PartnerLandingSectionLayout }[]>([]);
    const [layoutSettingsData, setLayoutSettingsData] = useState<PartnerLandingSectionLayout | null>(null);
    const [selectedSectionIndex, setSelectedSectionIndex] = useState<number | null>(null);
    const [isAddSectionModalOpen, setIsAddSectionModalOpen] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [landingErrors, setLandingErrors] = useState<{ [key: string]: string[] }>({});
    const [landingTitle, setLandingTitle] = useState('Project title')
    const [landingUrl, setLandingUrl] = useState('')
    const [isPublishLoading, setPublishLoading] = useState(false)
    const [isPublishedModalOpen, setIsPublishedModalOpen] = useState(false)
    const [isPublishDropdownOpen, setIsPublishDropdownOpen] = useState(false)
    const [isSaveDisabled, setIsSaveDisabled] = useState(true)

    const [isAiLoading, setAiLoading] = useState(false)

    const [publishLanding] = usePublishLandingMutation();
    const [updateLanding] = usePatchPartnerLandingByIdMutation()
    const [createLanding] = usePostPartnerCreateLandingMutation()
    const { data: fetchedLandingData } = useGetPartnerLandingByIdQuery({ landingId, eventId }, { skip: !landingId })
    const { data: layoutsResponse } = useGetLandingsLayoutsSectionsQuery({ id: eventId }, { skip: !eventId })

    useEffect(() => {
        if (isAddSectionModalOpen) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }
    }, [isAddSectionModalOpen]);

    useEffect(() => {
        if (landingErrors?.['url.path']) {
            setIsPublishDropdownOpen(true)
        }
    }, [landingErrors]);

    useEffect(() => {
        if (!layoutSettingsData) {
            setIsSaveDisabled(true)
        }
    }, [layoutSettingsData]);

    const updateSectionOptionById = (option: Option) => {
        if (isSaveDisabled) {
            setIsSaveDisabled(false)
        }
        const prevOptions = layoutSettingsData?.options?.[option.name] || {}
        let newOptionValue
        if (option.name === OptionIds.CardsComponent || option.name === OptionIds.ButtonsComponent) {
            newOptionValue = option.value
        } else {
            newOptionValue = {
                ...prevOptions,
                [option.key || 'value']: option.value
            }
        }
        if (option.name === OptionIds.BackgroundComponent) {
            if (option.key === 'backgroundColor') {
                newOptionValue = {
                    ...prevOptions,
                    backgroundImage: 'none',
                    [option.key]: option.value
                }
            }
            if (option.key === 'backgroundImage') {
                newOptionValue = {
                    ...prevOptions,
                    backgroundColor: '',
                    [option.key]: option.value
                }
            }
        }
        const updatedOptions = {
            ...layoutSettingsData?.options || {},
            [option.name]: newOptionValue
        };
        const updatedLayoutSettingsData = {
            ...layoutSettingsData,
            options: updatedOptions
        }
        setLayoutSettingsData(updatedLayoutSettingsData as PartnerLandingSectionLayout)
    };

    const updateSectionHtmlById = useCallback(({ id, html }: { id: string, html: string }) => {
        const updatedSections = editorSections.map(section => {
            if (section.id === id) {
                return {
                    ...section,
                    layout: {
                        ...section.layout,
                        html
                    }
                };
            }
            return section;
        });

        setEditorSections(updatedSections);
    }, [editorSections])

    const onSectionAddToList = (layout: PartnerLandingSectionLayout, idx?: number) => {
        const newItem = { id: generateUniqueStringId(), layout };

        const sectionIndex = selectedSectionIndex || idx

        let next;
        if (sectionIndex !== null && sectionIndex !== undefined) {
            next = [
                ...editorSections.slice(0, sectionIndex),
                newItem,
                ...editorSections.slice(sectionIndex)
            ];
        } else {
            next = [...editorSections, newItem];
        }

        setEditorSections(next);
        setIsAddSectionModalOpen(false);
        setSelectedSectionIndex(null);
    };

    const onDeleteSectionFromList = useCallback((id: string) => {
        const updatedSections = editorSections.filter(section => section.id !== id);
        setEditorSections(updatedSections);
    }, [editorSections])

    const publishHandler = async (statusId: number, transit?: string) => {
        setPublishLoading(true)
        try {
            const response = await publishLanding({ eventId, landingId, status: statusId })
            if ('data' in response) {
                // void refetchLandingData();
                setPublishLoading(false)
                if (transit?.toLowerCase()?.includes('publish')) {
                    setIsPublishedModalOpen(true)
                }
                return notification.open({
                    message: 'Page uploaded',
                    placement: 'bottomLeft',
                    closeIcon: false,
                    type: 'success'
                });
            }
            if ('error' in response) {
                setPublishLoading(false)
                return message.open({
                    type: 'error',
                    content: 'Page uploading error',
                });
            }
        } catch (error) {
            setPublishLoading(false)
            return message.open({
                type: 'error',
                content: 'Page uploading error',
            });
        }
    }

    const updateLandingHandler = async (sections?: { id?: string; layout: PartnerLandingSectionLayout }[]) => {
        const content = (sections || editorSections).map(section => (section.layout));
        const mappedContent = content.map((contentItem) => ({ ...contentItem || {}, html: removeImageStylesFromHtml(contentItem.html) }))
        const payload = {
            landingId,
            eventId,
            body: {
                title: landingTitle,
                url: {
                    path: landingUrl
                },
                content: mappedContent
            }
        }
        setLoading(true)
        try {
            // todo change response when it s ready
            const response = await updateLanding(payload)
            if ('data' in response) {
                setLoading(false);
                if (response?.data?.data?.id) {
                    // return refetchLandingData()
                }
                setIsPublishDropdownOpen(false)
                setLayoutSettingsData(null)
                return notification.open({
                    message: 'Changes saved',
                    placement: 'bottomLeft',
                    closeIcon: false,
                    type: 'success'
                });
            }
            setLoading(false);
            if ((response as ErrorDataResponse)?.error?.data?.errors) {
                void message.open({
                    type: 'error',
                    content: (response as ErrorDataResponse)?.error?.data?.message as string || 'Landing updating error',
                });
                return setLandingErrors((response as ErrorDataResponse).error.data.errors)
            }
            return message.open({
                type: 'error',
                content: `Error ${landingId ? 'updating' : 'creating'} landing page`,
            });
        } catch (error) {
            setLoading(false)
        }
    }

    const createLandingHandler = async (sections?: { id?: string; layout: PartnerLandingSectionLayout }[]) => {
        setLoading(true);
        const content = (sections || editorSections).map(section => (section.layout));
        const payload = {
            eventId,
            body: {
                title: landingTitle,
                url: {
                    path: landingUrl
                },
                content
            }
        }
        try {
            const response = await createLanding(payload);
            if ('data' in response) {
                setLoading(false);
                if (response?.data?.data?.id) {
                    const newLpId = response.data.data.id || ''
                    let newUrl = `${pathname}/${newLpId}/edit`
                    if (type) {
                        newUrl = pathname.replace(`create/${type}`, `${newLpId}/edit`)
                    }
                    setIsPublishDropdownOpen(false)
                    setLayoutSettingsData(null)
                    navigate(newUrl);
                }
            } else {
                setLoading(false);
                // if (!(response as ErrorDataResponse)?.error?.data?.errors) {
                //     return message.open({
                //         type: 'error',
                //         content: (response as ErrorDataResponse)?.error?.data?.message as string || 'OOOPS, something is wrong',
                //     });
                // }
                if ((response as ErrorDataResponse)?.error?.data?.errors) {
                    void message.open({
                        type: 'error',
                        content: (response as ErrorDataResponse)?.error?.data?.message as string || 'Creating landing error',
                    });
                    setLandingErrors((response as ErrorDataResponse).error.data.errors)
                }
                if (!(response as ErrorDataResponse)?.error?.data?.errors) {
                    return message.open({
                        type: 'error',
                        content: (response as ErrorDataResponse)?.error?.data?.message as string || 'OOOPS, something is wrong',
                    });
                }
            }
        } catch (error) {
            setLoading(false);
            // Handle error
            console.error('Error:', error);
        }
    }

    const onPreviewClick = () => {
        const page = fetchedLandingData?.data?.page
        if (landingId && page) {
            window.open(page, '_blank')
        }
    };

    const onSaveSectionSettingsClick = async () => {
        setLoading(true)
        let newLayoutSettingsData = layoutSettingsData
        const hasVideoUrl = layoutSettingsData?.options?.VideoComponent?.video
        if (hasVideoUrl) {
            const fixedVideoUrl = changeVideoUrl(layoutSettingsData?.options?.VideoComponent?.video)
            if (fixedVideoUrl) {
                newLayoutSettingsData = {
                    ...layoutSettingsData,
                    options: {
                        ...layoutSettingsData?.options || {},
                        VideoComponent: {
                            ...layoutSettingsData?.options?.VideoComponent,
                            video: fixedVideoUrl,
                        } as PartnerLandingSectionOptions['VideoComponent']
                    }
                }
            }
        }
        const updatedSections = editorSections.map(section => {
            if (section.id === clickedSectionId) {
                return {
                    ...section,
                    layout: newLayoutSettingsData as PartnerLandingSectionLayout
                };
            }
            return section;
        });
        if (landingId) {
            await updateLandingHandler(updatedSections)
        } else {
            await createLandingHandler(updatedSections)
        }
        setLoading(false)
    };

    const onPublishClick = async (statusId: number, transit?: string) => {
        try {
            if (landingId) {
                await updateLandingHandler();
            } else {
                await createLandingHandler();
            }
            await publishHandler(statusId, transit);
        } catch (err) {
            // Handle error based on lpId condition
            console.error(`Error ${landingId ? 'updating' : 'creating'} landing:`, err);
            return message.open({
                type: 'error',
                content: `Error ${landingId ? 'updating' : 'creating'} landing page`,
            });
        }
    };

    const onClosePublishedModal = () => {
        setIsPublishedModalOpen(false)
    }

    const getLayoutsByLayoutName = (layoutName: string) => {
        const foundSection = layoutsResponse?.data?.find((section) => {
            return section.section && layoutName.includes(section.section)
        })
        return {
            layouts: foundSection?.layouts?.filter(({ name }) => name !== layoutName) || [],
            selectedLayout: foundSection?.layouts?.find(({ name }) => name === layoutName),
        }
    }

    return (
        <LandingEditorContext.Provider value={{
            selectedSectionIndex,
            setSelectedSectionIndex,

            clickedSectionId,
            setClickedSectionId,
            fetchedSections,
            setFetchedSections,

            updateSectionOptionById,
            updateSectionHtmlById,
            onSectionAddToList,
            onDeleteSectionFromList,

            editorSections,
            setEditorSections,
            layoutSettingsData,
            setLayoutSettingsData,

            isAddSectionModalOpen,
            setIsAddSectionModalOpen,

            landingTitle,
            setLandingTitle,
            landingUrl,
            setLandingUrl,

            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            updateLandingHandler,
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            createLandingHandler,
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onPublishClick,
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onSaveSectionSettingsClick,
            onPreviewClick,

            isLoading,
            isPublishedModalOpen,
            onClosePublishedModal,
            isPublishLoading,
            landingErrors,
            setLandingErrors,

            isAiLoading,
            setAiLoading,
            isPublishDropdownOpen,
            setIsPublishDropdownOpen,
            getLayoutsByLayoutName,
            isSaveDisabled
        }}
        >
            {children}
        </LandingEditorContext.Provider>
    );
};

import { Routes as Router, Route, Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '@contexts/AuthContext';
import LoginPage from '@pages/CommonPages/Authentification/Login';
// import { DrawerProvider } from '@contexts/DrawerContext';
import MyEventEditPage from '@pages/PartnerRolePages/MyEvents/pages/MyEventEditPage';
import EventLeadsPage from '@pages/PartnerRolePages/Leads';
import MyEventsCatalogPage from '@pages/PartnerRolePages/MyEvents/pages/MyEventsCatalog';
import MyEventsCatalogEventItemPage from '@pages/PartnerRolePages/MyEvents/pages/MyEventsCatalogEventItem';
import MyEventsCardsPage from '@pages/PartnerRolePages/MyEvents/pages/MyEventsCardsPage';
import EventCampaignsPage from '@pages/PartnerRolePages/Campaigns';
// import LandingPageConstructor from '@pages/PartnerRolePages/LandingPageConstructor';
import AnalyticsPage from '@pages/PartnerRolePages/Analytics';
import AnalyticsEventsPage from '@pages/EventRolePages/AnalyticsEventsPage';
import ModerationPage from '@pages/EventRolePages/ModerationPage';
import PartnersPage from '@pages/EventRolePages/PartnersPage';
import AudiencesEventPage from '@pages/EventRolePages/AudiencesEventPage';
import CampaignEditorPage from '@pages/PartnerRolePages/Campaigns/CampaignEditorPage';
import LoadingView from "@components/common/LoadingView";
import NotFoundPage from "@pages/CommonPages/NotFoundPage";
import EventOnboarding from "@pages/Onboarding/EventOnboarding";
import { CampaignEditorProvider } from '@contexts/CampaignEditorContext';
import EventsPage from '@pages/EventRolePages/EventPage';
// import SolisticaPreview from '@styled-preview/Solistica';
import PartnerOnboarding from '@pages/Onboarding/PartnerOnboarding';
import { EventEditorContextProvider } from '@contexts/forms/event/EventEditorContext';
import BoostProPage from '@pages/PartnerRolePages/BoostPro';
import RegistrationPage from '@pages/CommonPages/Authentification/Register';
import { UserBillingPageProvider } from '@contexts/UserBillingPageContext';
import { StripeProvider } from '@contexts/StripeContext';
import EmailConfirmationSuccessPage from '@components/EmailConfirmation/EmailConfirmationSuccess';
import LandingCreationMethod from "@pages/PartnerRolePages/LandingCreationMethod/LandingCreationMethod";
import LandingPageConstructor from "@pages/PartnerRolePages/LandingPageConstructor";
import { LandingEditorContextProvider } from '@contexts/LandingEditorContext';
import NotificationsPage from "@pages/PartnerRolePages/Notifications";
import SettingsPage from "@pages/PartnerRolePages/SettingsPage";

import PasswordRecoverPage from '@pages/CommonPages/Authentification/PasswordRecover';
import PasswordChangePage from '@pages/CommonPages/Authentification/PasswordUpdateConfirm';
import PasswordChangeFromRedirectPage from '@pages/CommonPages/Authentification/PasswordUpdateConfirm/PasswordChangeFromRedirectPage';
import PrivacyPolicy from '@pages/CommonPages/PrivacyPolicyPage';
import TermsAndConditions from '@pages/CommonPages/Terms';
import CookiePolicy from "@pages/CommonPages/CookiePolicyPage";
import InitPage from "@pages/CommonPages/Authentification/InitPage";
import BillingPartner from '@pages/PartnerRolePages/Billing';
import BillingEvent from '@pages/EventRolePages/Billing';
import EventPage from '@pages/EventRolePages/EventPage/setup';
import EventsSetupPage from '@pages/EventRolePages/EventPage/create';
import EventPreview from '@pages/EventRolePages/EventPage/setup/preview';
import VideoTourPage from "@pages/PartnerRolePages/VideoTour";

const PrivateRoutes = () => {
    const { isAuthenticated } = useAuth();
    if (!isAuthenticated) return <Navigate to='/login' replace />;
    return <Outlet />;
};

//handle routes by organisation type: "admin" | "none" | "partner" | "event"
const OrganizationRoutesByType = {
    admin: (
        <Route element={<PrivateRoutes />}>
            <Route path='/settings' element={
                <SettingsPage />
            } />
            <Route path='/settings/:tabId' element={
                <SettingsPage />
            } />
            <Route path={'*'} element={<Navigate to='/settings' />} />
        </Route>
    ),
    none: (
        <Route element={<PrivateRoutes />}>
            <Route path='/registration' element={<RegistrationPage />} />
            <Route path='/pass_recover' element={<PasswordRecoverPage />} />
            <Route path='/pass_change' element={<PasswordChangePage />} />
            <Route path='/pass_recover_confirm' element={<PasswordChangeFromRedirectPage />} />
            <Route path='/privacy-policy' element={
                <PrivacyPolicy />
            } />
            <Route path='/cookie-policy' element={
                <CookiePolicy />
            } />
            <Route path='/email-confirmed' element={
                <EmailConfirmationSuccessPage />
            } />
            <Route path={'*'} element={<Navigate to='/registration' />} />
        </Route>
    ),
    partner: (
        <Route element={<PrivateRoutes />}>
            <Route path='/' element={
                <Navigate to='/events' />
            } />
            <Route path='/events' element={
                <MyEventsCardsPage />
            } />
            <Route path='/events/catalog/:id' element={
                <MyEventsCatalogEventItemPage />
            } />
            <Route path='/events/catalog' element={
                <MyEventsCatalogPage />
            } />
            <Route path='/events/edit/:id' element={
                <MyEventEditPage />
            } />
            <Route path='/events/edit/:eventId/campaign/:campaignId' element={
                <CampaignEditorProvider>
                    <StripeProvider>
                        <CampaignEditorPage />
                    </StripeProvider>
                </CampaignEditorProvider>
            } />
            <Route path='/events/create/:eventId' element={
                <CampaignEditorProvider>
                    <StripeProvider>
                        <CampaignEditorPage />
                    </StripeProvider>
                </CampaignEditorProvider>
            } />


            <Route path='/campaigns' element={
                <EventCampaignsPage />
            } />
            <Route path='/campaigns/create/new/:eventId' element={
                <CampaignEditorProvider>
                    <StripeProvider>
                        <CampaignEditorPage />
                    </StripeProvider>
                </CampaignEditorProvider>
            } />
            <Route path='/campaigns/create/:eventId' element={
                <CampaignEditorProvider>
                    <StripeProvider>
                        <CampaignEditorPage />
                    </StripeProvider>
                </CampaignEditorProvider>
            } />
            <Route path='/campaigns/edit/:eventId/campaign/:campaignId' element={
                <CampaignEditorProvider>
                    <StripeProvider>
                        <CampaignEditorPage />
                    </StripeProvider>
                </CampaignEditorProvider>
            } />

            <Route path='/leads' element={
                <EventLeadsPage />
            } />
            <Route path='/pro' element={
                <StripeProvider>
                    <BoostProPage />
                </StripeProvider>
            } />
            <Route path='/video-tour' element={
                <VideoTourPage />
            } />

            <Route path='/analytics' element={
                <AnalyticsPage />
            } />

            {/* new flow  */}
            {/* INITIAL STEP  */}
            <Route path='/events/create/:eventId/landing' element={
                <LandingEditorContextProvider>
                    <LandingCreationMethod />
                </LandingEditorContextProvider>
            } />
            <Route path='/events/:eventId/landing' element={
                <LandingEditorContextProvider>
                    <LandingCreationMethod />
                </LandingEditorContextProvider>
            } />
            <Route path='/campaigns/create/new/:eventId/landing' element={
                <LandingEditorContextProvider>
                    <LandingCreationMethod />
                </LandingEditorContextProvider>
            } />
            <Route path='/campaigns/edit/:eventId/campaign/:campaignId/landing' element={
                <LandingEditorContextProvider>
                    <LandingCreationMethod />
                </LandingEditorContextProvider>
            } />

            {/* EDITOR STEP  */}
            <Route path='/campaigns/create/new/:eventId/landing/:landingId/edit' element={
                <LandingEditorContextProvider>
                    <LandingPageConstructor />
                </LandingEditorContextProvider>
            } />
            <Route path='/campaigns/create/new/:eventId/landing/create/:type' element={
                <LandingEditorContextProvider>
                    <LandingPageConstructor />
                </LandingEditorContextProvider>
            } />
            <Route path='/campaigns/edit/:eventId/campaign/:campaignId/landing/create/:type' element={
                <LandingEditorContextProvider>
                    <LandingPageConstructor />
                </LandingEditorContextProvider>
            } />

            <Route path='/campaigns/edit/:eventId/campaign/:campaignId/landing/:landingId/edit' element={
                <LandingEditorContextProvider>
                    <LandingPageConstructor />
                </LandingEditorContextProvider>
            } />

            <Route path='/events/edit/:eventId/campaign/:campaignId/landing/:landingId/edit' element={
                <LandingEditorContextProvider>
                    <LandingPageConstructor />
                </LandingEditorContextProvider>
            } />
            <Route path='/events/edit/:eventId/campaign/:campaignId/landing' element={
                <LandingEditorContextProvider>
                    <LandingPageConstructor />
                </LandingEditorContextProvider>
            } />
            <Route path='/events/:eventId/landing/create/:type' element={
                <LandingEditorContextProvider>
                    <LandingPageConstructor />
                </LandingEditorContextProvider>
            } />
            <Route path='/events/create/:eventId/landing/create/:type' element={
                <LandingEditorContextProvider>
                    <LandingPageConstructor />
                </LandingEditorContextProvider>
            } />
            <Route path='/events/:eventId/landing/:landingId/edit' element={
                <LandingEditorContextProvider>
                    <LandingPageConstructor />
                </LandingEditorContextProvider>
            } />
            <Route path='/events/create/:eventId/landing/:landingId/edit' element={
                <LandingEditorContextProvider>
                    <LandingPageConstructor />
                </LandingEditorContextProvider>
            } />
            {/*  */}



            <Route path='/billing' element={
                <UserBillingPageProvider>
                    <StripeProvider>
                        <BillingPartner />
                    </StripeProvider>
                </UserBillingPageProvider>
            } />

            <Route path='/settings' element={
                <SettingsPage />
            } />
            <Route path='/settings/:tabId' element={
                <SettingsPage />
            } />

            <Route path='/registration' element={<RegistrationPage />} />

            <Route path='/email-confirmed' element={
                <EmailConfirmationSuccessPage />
            } />
            <Route path='/pass_recover' element={<PasswordRecoverPage />} />
            <Route path='/pass_recover_confirm' element={<PasswordChangeFromRedirectPage />} />

            <Route path='/notifications' element={
                <NotificationsPage />
            } />

            <Route path='/privacy-policy' element={
                <PrivacyPolicy />
            } />
            <Route path='/cookie-policy' element={
                <CookiePolicy />
            } />
            <Route path='/terms-and-conditions' element={
                <TermsAndConditions />
            } />

            <Route path={'*'} element={<Navigate to='/events' />} />
        </Route>),

    event: (
        <Route element={<PrivateRoutes />}>
            <Route path='/events' element={<EventsPage />} />
            <Route path='/events/setup' element={
                <EventEditorContextProvider>
                    <EventsSetupPage />
                </EventEditorContextProvider>
            } />
            <Route path='/events/edit/:eventId' element={
                <EventEditorContextProvider>
                    <EventsSetupPage />
                </EventEditorContextProvider>}
            />

            <Route path='/events/info/:eventId/:tabId' element={
                <EventEditorContextProvider>
                    <EventPage />
                </EventEditorContextProvider>
            } />
            <Route path='/events/info/:eventId/:tabId/:listId' element={
                <EventPage />
            } />
            <Route path='/events/info/:eventId/preview' element={
                <EventPreview />
            } />
            <Route path='/audiences' element={
                <AudiencesEventPage />
            } />
            <Route path='/audiences/:audienceId' element={
                <AudiencesEventPage />
            } />
            <Route path='/moderation' element={
                <ModerationPage />
            } />
            <Route path='/partners' element={
                <PartnersPage />
            } />
            <Route path='/analytics' element={
                <AnalyticsEventsPage />
            } />
            {/*COMMON */}
            <Route path='/email-confirmed' element={
                <EmailConfirmationSuccessPage />
            } />
            <Route path='/pass_recover' element={<PasswordRecoverPage />} />
            <Route path='/pass_recover_confirm' element={<PasswordChangeFromRedirectPage />} />

            <Route path='/notifications' element={
                <NotificationsPage />
            } />

            <Route path='/settings' element={
                <SettingsPage isEvent />
            } />
            <Route path='/settings/:tabId' element={
                <SettingsPage isEvent />
            } />
            <Route path='/settings/:tabId/:accountId' element={
                <SettingsPage isEvent />
            } />

            <Route path='/billing' element={
                <UserBillingPageProvider>
                    <StripeProvider>
                        <BillingEvent />
                    </StripeProvider>
                </UserBillingPageProvider>
            } />

            <Route path='/billing/:billingId' element={
                <UserBillingPageProvider>
                    <StripeProvider>
                        <BillingEvent />
                    </StripeProvider>
                </UserBillingPageProvider>
            } />

            <Route path='/billing/:billingId/:tabId' element={
                <UserBillingPageProvider>
                    <StripeProvider>
                        <BillingEvent />
                    </StripeProvider>
                </UserBillingPageProvider>
            } />

            <Route path='/privacy-policy' element={
                <PrivacyPolicy />
            } />
            <Route path='/cookie-policy' element={
                <CookiePolicy />
            } />
            <Route path='/terms-and-conditions' element={
                <TermsAndConditions />
            } />

            <Route path='/*' element={<Navigate to='/events' />} />

        </Route>),
};

const Routes = () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { user: data, isUserLoading } = useAuth();

    if (isUserLoading) {
        return <LoadingView />;
    }
    console.log('==========')
    console.log('data', data);
    console.log('==========')

    // not registered user
    if (!data || !data.email) {
        return (
            <Router>
                <Route path='/' element={<Navigate to='/login' />} />
                <Route path='/login' element={<LoginPage />} />
                {/*todo find places with login*/}
                <Route path='/init' element={<InitPage />} />

                <Route path='/pass_change' element={<PasswordChangePage />} />
                <Route path='/pass_recover' element={<PasswordRecoverPage />} />
                <Route path='/pass_recover_confirm' element={<PasswordChangeFromRedirectPage />} />

                <Route path='/registration' element={<RegistrationPage />} />

                <Route path='/privacy-policy' element={
                    <PrivacyPolicy />
                } />
                <Route path='/cookie-policy' element={
                    <CookiePolicy />
                } />
                <Route path='/terms-and-conditions' element={
                    <TermsAndConditions />
                } />

                <Route path={'*'} element={<Navigate to='/login' />} />
            </Router>
        );
    }

    // user during registration

    // onboarding event user
    if (data?.organization?.onboarding !== 'ready') {
        // event onboarding
        if (data?.organization?.type === 'event') {
            return (
                <Router>
                    <Route path='/pass_recover_confirm' element={<PasswordChangeFromRedirectPage />} />
                    <Route path='/' element={<Navigate to='/onboarding/event' />} />
                    <Route path='/init' element={<InitPage />} />
                    <Route path={'/onboarding/event'} element={
                        <EventEditorContextProvider>
                            <EventOnboarding />
                        </EventEditorContextProvider>
                    } />
                    <Route path='/privacy-policy' element={
                        <PrivacyPolicy />
                    } />
                    <Route path='/cookie-policy' element={
                        <CookiePolicy />
                    } />
                    <Route path='/terms-and-conditions' element={
                        <TermsAndConditions />
                    } />
                </Router>
            )
        }
        // partner onboarding
        if (data?.organization?.type === 'partner') {
            return (
                <Router>
                    <Route path='/pass_recover_confirm' element={<PasswordChangeFromRedirectPage />} />
                    <Route path='/*' element={<Navigate to='/onboarding/partner' />} />
                    <Route path='/init' element={<InitPage />} />
                    <Route path={'/onboarding/partner'} element={
                        <StripeProvider>
                            <PartnerOnboarding />
                        </StripeProvider>
                    } />
                    <Route path='/privacy-policy' element={
                        <PrivacyPolicy />
                    } />
                    <Route path='/cookie-policy' element={
                        <CookiePolicy />
                    } />
                    <Route path='/terms-and-conditions' element={
                        <TermsAndConditions />
                    } />
                </Router >
            )
        }
        if (data && !data?.organization?.type) {
            return (
                <Router>
                    <Route path='/' element={<Navigate to='/registration' />} />
                    <Route path='/init' element={<InitPage />} />
                    <Route path='/login' element={<LoginPage />} />
                    <Route path='/pass_recover' element={<PasswordRecoverPage />} />
                    <Route path='/pass_recover_confirm' element={<PasswordChangeFromRedirectPage />} />
                    <Route path='/registration' element={<RegistrationPage />} />
                    <Route path='/pass_recover' element={<PasswordRecoverPage />} />
                    <Route path='/privacy-policy' element={
                        <PrivacyPolicy />
                    } />
                    <Route path='/cookie-policy' element={
                        <CookiePolicy />
                    } />
                    <Route path='/terms-and-conditions' element={
                        <TermsAndConditions />
                    } />
                    <Route path={'*'} element={<NotFoundPage />} />
                </Router>
            )
        }

    }


    // registered user
    return data?.organization?.type ? <Router>{OrganizationRoutesByType[data.organization.type]}</Router> : null;
};

export default Routes;

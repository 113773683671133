import React, { useState } from 'react'
import { Select, Button, Divider, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import './styles.scss'
const { Option } = Select;
interface CatalogItem {
    id: number;
    name: string;
}

const mockData: CatalogItem[] = [
    { id: 1, name: 'Technological event venues' },
    { id: 2, name: 'Exclusive exhibition pavilions' },
    { id: 3, name: 'Modern business centers' },
    { id: 4, name: 'Elite conference halls' },
    { id: 5, name: 'Cozy event spaces' },
];

const EventAudienceCatalog = () => {
    const [catalog, setCatalog] = useState<CatalogItem[]>(mockData);
    // State for selected catalog item
    const [selected, setSelected] = useState<number | undefined>();
    const handleCreateCatalog = () => {
        const newCatalog: CatalogItem = { id: catalog.length + 1, name: 'New Catalog Item' };
        setCatalog([...catalog, newCatalog]);
    };
    return (
        <div className='background'>

            <article className='flex flex-col items-center max-w-md'>
                <div className='w-full'>
                    <Typography.Title level={4}>
                        Audience Catalog
                    </Typography.Title>
                </div>
                <span className='text-regular-14 text-neutral-8 mr-auto mb-4'>Choose audience catalog</span>
                <Select
                    style={{
                        width: '100%',
                        maxWidth: '740px'
                    }}
                    placeholder="Choose audience catalog"
                    value={selected}
                    onChange={(value: number) => setSelected(value)}
                    dropdownRender={(menu) => (
                        <>
                            {menu}
                            <Divider style={{ margin: '8px 0' }} />
                            <div className='flex'>
                                <Button
                                    type="text"
                                    icon={<PlusOutlined />}
                                    onClick={handleCreateCatalog}
                                // style={{ width: '100%' }}
                                >
                                    Create Catalog
                                </Button>
                            </div>
                        </>
                    )}
                >
                    {catalog.map((item) => (
                        <Option key={item.id} value={item.id}>
                            {item.name}
                        </Option>
                    ))}
                </Select>
                <Button
                    type="text"
                    style={{ border: '1px solid #D9DADC', marginTop: '12px', marginRight: 'auto' }}
                    icon={<PlusOutlined />}
                    onClick={handleCreateCatalog}
                // style={{ width: '100%' }}
                >
                    Create Catalog
                </Button>

            </article>
        </div>
    )
}

export default EventAudienceCatalog
import {useParams} from 'react-router-dom'
import EventsPageLayout from '@layouts/EventsPageLayout'
import ContentLayout from '@layouts/ContentLayout'
import EventInfo from './components/EventInfo'
import EventLandingPages from './components/EventLandingPages'
import EventCampaignsTableSection from './components/EventCampaignsTable'
import MyEventEditPageZeroState from './ZeroState'
import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react'
import {events} from '@pages/routes';
import HeaderBreadcrumb from '@components/Header/HeaderBreadcrumb';
import {useGetPartnerEventByIdQuery} from '@store/type-partner/events/partner-event.api'
import {
    useDeletePartnerLandingByIdMutation,
    useGetPartnerLandingsByEventIdQuery
} from '@store/type-partner/landings/landings.api'
import {useGetPartnerCampaignsQuery} from '@store/type-partner/campaigns/campaigns.api'
import {INIT_PAGE} from "@components/InfiniteScrollTable/constants";
import {useBreakpoints} from '@hooks/browser'
import {TourContext} from "@contexts/TourContext.tsx";
import {
    descriptionsEvent,
    EventsTourSteps,
    TourStages
} from "@components/TourComponent/constants.tsx";
import NewTourComponent from "@components/TourComponent/NewTourComponent.tsx";
import {scrollToElementCenter, setTooltipCoordinatesPlacementTop} from "@components/TourComponent/helpers.ts";

const MyEventEditPage = () => {
    const { id } = useParams()
    const isDesktop = useBreakpoints().md
    const [isDeleting, setIsDeleting] = useState(false)
    const [isScrolled, setIsScrolled] = useState(false)

    const landingPagesRef = useRef(null);
    const myCampaignsRef = useRef(null);

    const {
        tourStep,
        setTourStep,
        tourStage,
        onFinishTour
    } = useContext(TourContext);

    const [deleteLanding] = useDeletePartnerLandingByIdMutation()

    const { data: eventsRes, isLoading: eventsLoading } = useGetPartnerEventByIdQuery({ id: id! }, { skip: !id })
    const { data: landingsRes, isLoading: lpLoading, refetch: refetchLandingsData } = useGetPartnerLandingsByEventIdQuery({ id: +id!, page: INIT_PAGE }, { skip: !id })
    const { data: campaignsRes, isLoading: campaignsLoading, isFetching: isCampaignsFetching, refetch: refetchCampaignssData } = useGetPartnerCampaignsQuery({ filter: { events: [id || ''] } }, { skip: !id })

    const eventInfo = eventsRes?.data

    const [campaignsList, setCampaignsList] = useState(campaignsRes?.data || []);
    const [page, setPage] = useState(INIT_PAGE)

    useEffect(() => {
        setCampaignsList((prevLandings) => {
            const from = campaignsRes?.meta?.from
            if (from && prevLandings.length < from) {
                return [...(prevLandings || []), ...(campaignsRes?.data || [])]
            }
            return campaignsRes?.data || []
        })
    }, [campaignsRes])


    const isLastPage = useMemo(() => {
        const lastPage = campaignsRes?.meta?.last_page || INIT_PAGE
        return page >= lastPage
    }, [campaignsRes?.meta?.last_page, page])

    const onNextPage = useCallback(() => {
        setPage(page + 1)
    }, [setPage, page])

    const onDeleteLanding = useCallback(async (landingId: string) => {
        setIsDeleting(true)
        try {
            const res = await deleteLanding({ landing: landingId, event: id ?? '' });
            if (res) {
                refetchLandingsData()
                    .finally(() => {
                        setIsDeleting(false)
                    });
            }
            setIsDeleting(false)
        } catch (error) {
            setIsDeleting(false)
            console.error('Error deleting landing:', error);
        }
    }, [deleteLanding, id, refetchLandingsData])

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        void refetchLandingsData()
        void refetchCampaignssData()
    }, [])

    useEffect(() => {
        scrollToElementCenter(landingPagesRef.current, 190)
        setTimeout(() => {
            setTooltipCoordinatesPlacementTop({ current: landingPagesRef.current, id: 'my-event-tour-component', isDesktop })
            setIsScrolled(true)
        }, 700)
    }, [landingPagesRef, eventsLoading, lpLoading, campaignsLoading, isDesktop]);

    const breadCrumbs = useMemo(() => {
        return [
            {
                url: events,
                label: 'My events',
                idx: 1,
                isZeroLevel: true
            },
            {
                label: eventInfo?.name || '',
                idx: 2,
            }
        ]
    }, [eventInfo])

    if (eventsLoading || lpLoading || campaignsLoading) {
        return (
            <EventsPageLayout>
                <HeaderBreadcrumb
                    crumbs={breadCrumbs}
                />
                <ContentLayout>
                    <MyEventEditPageZeroState />
                </ContentLayout>
            </EventsPageLayout>
        )
    }

    // todo check mobile
    return (
        <EventsPageLayout className={`my-event-edit-page ${tourStage === TourStages.event ? 'my-event-edit-page__bottom' : ''}`}>
            {isDesktop
                ?
                <HeaderBreadcrumb
                    crumbs={breadCrumbs}
                />
                :
                null}
            {eventInfo && <EventInfo event={eventInfo} />}
            <EventLandingPages
                landingPagesRef={landingPagesRef}
                eventLandings={landingsRes?.data || []}
                eventId={id ?? ''}
                isActive={eventInfo?.status.name === 'active'}
                isDeleting={isDeleting}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onDeleteLanding={onDeleteLanding}
            />
            <EventCampaignsTableSection
                myCampaignsRef={myCampaignsRef}
                campaigns={campaignsList}
                isLastPage={isLastPage}
                onNextPage={onNextPage}
                isLoading={campaignsLoading || isCampaignsFetching}
                page={page}
                eventId={id ?? ''}
                isActive={eventInfo?.status.name === 'active'}
            />
                <NewTourComponent
                    id="my-event-tour-component"
                    isArrowBottom
                    mask
                    open={(tourStep === EventsTourSteps.landingPages || tourStep === EventsTourSteps.myCampaigns) && tourStage === TourStages.event && isScrolled}
                    onClose={() => {
                        onFinishTour()
                    }}
                    onChange={(step) => {
                        if (step === EventsTourSteps.myCampaigns) {
                            scrollToElementCenter(myCampaignsRef.current, 190)
                            setTimeout(() => {
                                setTourStep(step)
                            }, 200)
                            return
                        }
                        setTourStep(step)
                        if (!isDesktop) {
                            window.scrollTo({ top: 0, behavior: 'smooth' })
                        }
                    }}
                    animationAtEnd="hide-to-left"
                    isTransition={tourStep >= EventsTourSteps.myCampaigns}
                    current={tourStep > EventsTourSteps.myCampaigns ? EventsTourSteps.myCampaigns : tourStep}
                    totalCount={isDesktop ? 7 : 8}
                    description={tourStep > EventsTourSteps.myCampaigns ? descriptionsEvent[EventsTourSteps.myCampaigns] : descriptionsEvent[tourStep]}
                />
        </EventsPageLayout>
    )
}

export default MyEventEditPage

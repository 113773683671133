export const handleChangeBrushColor = (color?: string) => {
    const customBrush = document.querySelector('[data-ref="customBrush"] .jodit-toolbar-button__trigger') as HTMLElement
    if (customBrush) {
        if (color) {
            customBrush.style.backgroundColor = color;
            customBrush.style.backgroundImage = 'none';
        } else {
            customBrush.style.backgroundColor = 'none';
            customBrush.style.backgroundImage = 'url(\'/src/assets/lpEditor/ColorPreview.svg\')';
        }
    }
}
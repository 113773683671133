import { FilterByEnumItem } from '@store/main/enums/models'
import { RadioGoalIcon } from '..'
import { useAuth } from '@contexts/AuthContext'
import './styles.scss'
import GoalBadges from './badges'
import BadgePro from './badges/pro'
import GoalDropdown from './Dropdown'
import { Subscription } from '@store/models-to replace/auth'

interface Props {
    subscrionsAll: FilterByEnumItem[];
    goal: FilterByEnumItem,
    isSelected: boolean,
    onClick: (id: number) => void;
    onAddSubscription: (id: string) => void

}
const GoalCard = ({ subscrionsAll,
    goal,
    isSelected,
    onClick,
    onAddSubscription
}: Props) => {
    const { user } = useAuth()

    const enabledSubscriptionsIds = user?.organization?.subscriptions
        .filter(item => item?.status?.name === 'active')
        .map(item => item?.type.id.toString()) || [];

    const disabledSubscriptions = subscrionsAll
        .filter(item => !enabledSubscriptionsIds.includes(item.id.toString()));

    const hasDisabledSubscription = goal?.subscriptions?.some(subscriptionId =>
        !enabledSubscriptionsIds.includes(subscriptionId.toString())
    );

    return (
        <div
            className={`goal-card__item ${isSelected ? 'selected' : ''}`}
            key={goal.id}
            onClick={() => {
                hasDisabledSubscription
                    ?
                    null
                    :
                    onClick(+goal.id)
            }}
        >
            <GoalBadges>
                <Badges
                    subscriptionsAll={disabledSubscriptions || []}
                    subscriptionsGoal={goal?.subscriptions || []}
                    goal={goal}
                    onSubmit={onAddSubscription}
                />
            </GoalBadges>

            <div className='goal-card__item-title'>
                <p>
                    {goal.translate}
                </p>
                <RadioGoalIcon goal={+goal.id} />
            </div>
            <p className='goal-card__item-desc'>
                {goal?.description || ''}
            </p>

        </div>
    )
}

export default GoalCard


interface BadgesProps {
    subscriptionsAll: FilterByEnumItem[];
    subscriptionsGoal: string[];
    goal: FilterByEnumItem;
    onSubmit: (id: string) => void
}
const Badges = ({
    subscriptionsAll,
    subscriptionsGoal,
    goal,
    onSubmit
}: BadgesProps) => {
    const subscriptionsIds = subscriptionsAll.map(item => item?.id)
    const badgesIds = subscriptionsIds.filter(sub => subscriptionsGoal.includes(sub));

    if (!badgesIds.length) {
        return null;
    }

    return (
        <>
            {subscriptionsAll.map(item => {
                switch (item.id.toString()) {
                    case '1':
                        return (
                            <GoalDropdown
                                goal={goal}
                                onSubmit={onSubmit}
                                subscription={item}
                            >
                                <div>
                                    <BadgePro
                                        key={item.id}
                                    />
                                </div>
                            </GoalDropdown>
                        );
                    default:
                        return null;
                }
            })}
        </>
    );
};


import React, { useCallback, useContext, useMemo } from 'react';
import HtmlEditor
    from "@pages/PartnerRolePages/LandingPageConstructor/components/LandingPageWYSIWYGconstructor/Editor.tsx";
import { PlusOutlined } from "@ant-design/icons";
import { PartnerLandingSectionLayout } from "@store/type-partner/landings/models.ts";
import {
    JoditOptionName
} from "@pages/PartnerRolePages/LandingPageConstructor/components/LandingPageWYSIWYGconstructor/assets/models.tsx";
import { LandingEditorContext } from "@contexts/LandingEditorContext.tsx";
import cn from 'classnames';
import { HERO } from "@pages/PartnerRolePages/LandingPageConstructor/constants.ts";

type Props = {
    section: { id?: string | undefined, layout: PartnerLandingSectionLayout }
    idx: number
    isUpBtnDisabled: boolean
    onOptionClick: ({ option, id }: {option: JoditOptionName, id: string, selectedLayout?: PartnerLandingSectionLayout}) => void
    isActive: boolean
    isLoading: boolean
}

const SectionComponent: React.FC<Props> = React.memo(({
    section,
    isUpBtnDisabled,
    idx,
    isActive,
    onOptionClick,
    isLoading
}: Props) => {
    const {
        setClickedSectionId,
        setSelectedSectionIndex,
        setIsAddSectionModalOpen,
    } = useContext(LandingEditorContext);

    const layout = useMemo(() => section.layout, [section.layout])

    const isHero = section?.layout?.name?.includes(HERO)

    const handleSectionClick = useCallback(() => {
        setClickedSectionId(section.id)
    }, [section?.id, setClickedSectionId])

    return (
        <section
            className={cn(
                'html-editor cursor-pointer',
                isLoading && 'html-editor-loading',
                isActive ? 'html-editor__active' : '',
                isHero ? 'html-editor__hero' : '',
                isUpBtnDisabled ? 'html-editor__up-btn-disabled' : '',
            )}
            onMouseEnter={handleSectionClick}
        >
            <HtmlEditor
                id={section.id!}
                layout={layout}
                onCustomOptionClick={onOptionClick}
                idx={idx}
            />
            <button
                className='landing-editor-page__add-section-cta'
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation()
                    setClickedSectionId('')
                    setIsAddSectionModalOpen(true)
                    setSelectedSectionIndex(idx + 1)
                }}
            >
                <PlusOutlined />
                <span className="landing-editor-page__add-section-text">Add Section</span>
            </button>
        </section>
    )
})

export default SectionComponent

import React from "react";
import { TourTitle } from "@components/TourComponent/TourComponent.tsx";
import { Button } from "antd";
import TourComponentArrow from "@assets/TourComponentArrow.tsx";
import ExitCrossIcon from '@assets/ExitCrossIcon';

type Props = {
    id: string
    open: boolean
    isArrowBottom?: boolean
    isArrowTop?: boolean
    isArrowTopLeft?: boolean
    isArrowLeftTop?: boolean
    isArrowTopRight?: boolean
    isTransition?: boolean
    isCampaign?: boolean
    mask?: boolean
    onClose: () => void
    onChange: (step: number) => void
    current: number | null
    totalCount: number
    className?: string
    buttonText?: string
    animationAtEnd?: string
    animationAtStart?: string
    description: React.ReactNode
}

const NewTourComponent = ({
    id,
    isTransition,
    current,
    totalCount,
    isArrowBottom, isArrowTop, isArrowTopLeft, isArrowTopRight, isArrowLeftTop, isCampaign,
    open,
    mask,
    onClose, onChange, className, description, buttonText, animationAtEnd, animationAtStart
}: Props) => {
    return (
        <>
            {mask && (
                <>
                    <div id={`${id}__mask`}
                        className={`tour-component-wrapper__mask ${open ? 'tour-component-wrapper__mask__open' : ''}`}>
                        <div id={`${id}__mask__top`} className="tour-component-wrapper__mask__top" />
                        <div id={`${id}__mask__right`} className="tour-component-wrapper__mask__right" />
                        <div id={`${id}__mask__bottom`} className="tour-component-wrapper__mask__bottom" />
                        <div id={`${id}__mask__left`} className="tour-component-wrapper__mask__left" />
                    </div>
                </>
            )}
            <div
                id={id}
                className={`
                tour-component-wrapper
                ${open ? 'tour-component-wrapper__open' : ''}
                ${className || ''}
                ${isArrowBottom ? 'tour-component-wrapper__arrow-bottom' : ''}
                ${isArrowTop ? 'tour-component-wrapper__arrow-top' : ''}
                ${isArrowTopLeft ? 'tour-component-wrapper__arrow-top-left' : ''}
                ${isArrowLeftTop ? 'tour-component-wrapper__arrow-left-top' : ''}
                ${isArrowTopRight ? 'tour-component-wrapper__arrow-top-right' : ''}
                ${isTransition ? 'tour-component-wrapper__transition' : ''}
                ${isCampaign ? 'tour-component-wrapper__campaign' : ''}
                ${animationAtEnd && !open ? `tour-component-wrapper__${animationAtEnd}` : ''}
                ${animationAtStart && open ? `tour-component-wrapper__${animationAtStart}` : ''}
            `}
            >
                <TourTitle />
                <div className="tour-component-wrapper__description">
                    {description}
                </div>
                <div className="tour-component-wrapper__footer">
                    {current === null ? null : (
                        <div className="tour-indicators">
                            {current + 1}
                            /
                            {totalCount}
                        </div>
                    )}
                    <Button
                        onClick={() => {
                            onChange((current || 0) + 1)
                        }}
                        size="small"
                        type="primary"
                    >
                        {buttonText || 'Next'}
                    </Button>
                </div>
                <TourComponentArrow />
                <div onClick={onClose} className="tour-component-wrapper__close">
                    <ExitCrossIcon width="20" height="20" fill="#838898" />
                </div>
            </div>
        </>

    )
}

export default NewTourComponent

import EventsPageLayout from "@layouts/EventsPageLayout";
import HeaderBreadcrumb from "@components/Header/HeaderBreadcrumb";
import { Card, Spin } from 'antd'
import React, { useMemo } from "react";
import { getCrumbs } from "@pages/PartnerRolePages/LandingPageConstructor/helpers";
import { Link, useLocation, useParams } from "react-router-dom";
import { useGetEventByIdFromCatalogQuery } from "@store/type-partner/events-catalog/partner-event-catalog.api";
import './styles.scss';
import { useGetFiltersByEnumQuery } from "@store/main/enums/enums.api";
import { useAuth } from "@contexts/AuthContext.tsx";
import { Image, Descriptions, Titles } from "@pages/PartnerRolePages/LandingCreationMethod/constants.tsx";

const { Meta } = Card;

const LandingCreationMethod = () => {
    const { eventId = '', campaignId = '' } = useParams()
    const { user } = useAuth();

    const { data: presets, isLoading: isPresetsLoading } = useGetFiltersByEnumQuery({ name: 'landing_preset' })
    const { pathname } = useLocation()
    const { data: eventData, isLoading } = useGetEventByIdFromCatalogQuery(
        { id: eventId?.toString() || '' },
        { skip: !eventId }
    );
    const breadCrumbs = useMemo(() => getCrumbs({ pathname, eventData: eventData?.data, campaignId }), [pathname, eventData])

    const filteredPresets = useMemo(() => {
        // todo remove after demo
        if (user?.id === 81) {
            return presets?.data?.filter(({ slug }) => slug !== "ai")
        }
        return presets?.data
    }, [presets])

    return (
        <EventsPageLayout className='creation-methods-page'>
            <HeaderBreadcrumb
                crumbs={breadCrumbs}
            />
            <div className="main-page landing-creation-method__page">
                <div className='landing-creation-method__title'>Select a way to create your landing page</div>
                <div className='landing-creation-method__subtitle'>All ways are flexible. The templates are 100% customizable and you can change them at any time.</div>
                <div className='landing-creation-method__wrapper custom-scroll'>
                    {isLoading || isPresetsLoading
                        ?
                        <div className='flex flex-center landing-creation-method__spin'>
                            <Spin size="small" />
                        </div>
                        :
                        null}
                    {!isLoading && !isPresetsLoading && filteredPresets?.map(({
                        id,
                        slug
                    }) => {
                        return (
                            <div key={id} className=''>
                                <Link
                                    className='no-style'
                                    to={`${pathname}/create/${slug}`}
                                >
                                    <Card
                                        onClick={() => null}
                                        hoverable
                                        cover={(
                                            <div className='landing-creation-method__cover'>
                                                {Image[slug]}
                                            </div>
                                        )}
                                    >
                                        <Meta
                                            title={Titles[slug]}
                                            description={Descriptions[slug]}
                                        />
                                    </Card>
                                </Link>
                            </div>
                        )
                    })}
                </div>
            </div>
        </EventsPageLayout>
    )
}

export default LandingCreationMethod

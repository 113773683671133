import { Button, Dropdown, Input, Popover, Skeleton, Space } from "antd";
import Loader from "@components/Loader";
import TopIcon from "@assets/lpEditor/TopIcon.tsx";
import React, { useContext } from "react";
import { LandingEditorContext } from "@contexts/LandingEditorContext.tsx";

type Props = {
    id?: number
    isUrlLoading?: boolean
    subdomain?: string
    landingUrl: string
    landingErrors: { [key: string]: string[] };
    setLandingErrors: (errors: { [key: string]: string[] }) => void;
    setLandingUrl: (url: string) => void;
    onSaveClick: () => void
    onSaveSectionAndUrlClick?: () => void
    onPublishClick: (statusId: number, transit?: string) => void;
    transit: string
    isLoading: boolean
    isDropdownButtonLoading: boolean
    isSave?: boolean
    disabled?: boolean
}

const PopoverButton = ({
    id, isUrlLoading, subdomain, landingUrl, landingErrors,
    setLandingErrors, setLandingUrl, onSaveClick, onPublishClick, transit, isLoading, isDropdownButtonLoading, isSave, disabled, onSaveSectionAndUrlClick
}: Props) => {
    const {
        isPublishDropdownOpen, setIsPublishDropdownOpen
    } = useContext(LandingEditorContext);

    if (transit !== 'Publish' && transit !== 'Save') {
        return (
            <Button
                disabled={disabled}
                loading={isDropdownButtonLoading}
                type="primary"
                onClick={() => {
                    if (!landingUrl) {
                        setIsPublishDropdownOpen(true)
                        return
                    }
                    if (isSave) {
                        onSaveClick()
                        return;
                    }
                    if (id) {
                        onPublishClick(id, transit)
                    }
                }}
            >
                {transit}
            </Button>
        )
    }

    return (
        <Popover
            overlayClassName="landing-editor-page__popover"
            arrow={false}
            placement="bottomRight"
            key={id}
            open={isPublishDropdownOpen}
            onOpenChange={setIsPublishDropdownOpen}
            content={(
                <>
                    <div className="landing-editor-page__popover__title">
                        Specify page URL
                    </div>
                    <div className='mb-4'>
                        Page URL
                    </div>
                    <article>
                        {isUrlLoading ? (
                            <Skeleton
                                active
                                paragraph={false}
                                title={{ width: '300px' }}
                            />
                        ) : (
                            <>
                                <Space.Compact style={{ width: '100%' }} className={`landing-editor-page__landing-url-input ${landingErrors?.['url.path'] ? 'landing-editor-page__landing-url-input-error' : ""}`}>
                                    <Input
                                        style={{ width: '322px' }}
                                        addonBefore={`${subdomain || ''}/`}
                                        value={landingUrl}
                                        status={landingErrors?.['url.path'] ? 'error' : ''}
                                        onChange={e => {
                                            setLandingErrors({})
                                            setLandingUrl(e.target.value)
                                        }}
                                    />
                                    <Button
                                        disabled={!landingUrl}
                                        onClick={() => {
                                            if (onSaveSectionAndUrlClick) {
                                                onSaveSectionAndUrlClick()
                                            } else {
                                                onSaveClick()
                                            }
                                        }}
                                    >
                                        {transit}
                                    </Button>
                                </Space.Compact>
                                {landingErrors?.['url.path'] && (
                                    <div
                                        className='text-regular-14 label my-8 error'
                                    >
                                        {landingErrors['url.path']}
                                    </div>
                                )}
                                {!isSave && (
                                    <Button
                                        className="ml-auto"
                                        type="primary"
                                        disabled={!landingUrl}
                                        onClick={() => {
                                            if (id) {
                                                onPublishClick(id, transit)
                                            }
                                        }}
                                    >
                                        {transit}
                                    </Button>
                                )}
                            </>
                        )}
                    </article>
                    {(isLoading) &&
                        <Loader />}
                </>
            )}
            title=""
            trigger="click"
        >
            <Dropdown.Button
                disabled={disabled}
                open={false}
                onOpenChange={() => setIsPublishDropdownOpen((prev) => !prev)}
                loading={isDropdownButtonLoading}
                type="primary"
                trigger={['click']}
                icon={<TopIcon />}
                placement="bottomRight"
                onClick={() => {
                    if (!landingUrl) {
                        setIsPublishDropdownOpen(true)
                        return
                    }
                    if (isSave) {
                        onSaveClick()
                        return;
                    }
                    if (id) {
                        onPublishClick(id, transit)
                    }
                }}
            >
                {transit}
            </Dropdown.Button>
        </Popover>
    )
}

export default PopoverButton

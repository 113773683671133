import {
    socialIconsColored,
    socialIcons,
    socialLinksOptions,
    socialPlaceholder
} from "@pages/PartnerRolePages/LandingPageConstructor/components/drawers/settings/constants.tsx";
import React, { useEffect, useState } from 'react'
import { PlusOutlined } from "@ant-design/icons";
import './styles.scss'
import { Input } from "antd";
import { SocialLink } from "@pages/PartnerRolePages/LandingPageConstructor/models.ts";
import ErrorCrossIcon from "@assets/ErrorCrossIcon.tsx";

type Props = {
    socialLinks?: Record<string, string>
    index: number
    onCardValueChange: (value: { value: string | string[] } | Record<string, string>, key: string, index: number) => void
}

const SocialLinksItem = ({ socialLinks, onCardValueChange, index }: Props) => {
    const [currentLinks, setCurrentLinks] = useState<SocialLink[]>([])

    useEffect(() => {
        const links = Object.entries(socialLinks || {})
            .map(([key, value]) => ({type: key, value}))
            .filter(({ value }) => value !== null)
        setCurrentLinks(links || [])
    }, [socialLinks]);

    const onSocialLinkBlur = (value: string | null, type: string) => {
        const newLinks = currentLinks.map((link) => {
            return link.type === type ? { value, type } : link
        })
        const linksObject = newLinks.reduce((acc, next) => {
            const {type, value} = next
            if (acc) {
                acc[type] = value
            }
            return acc
        }, {} as Record<string, string>)
        onCardValueChange(linksObject, 'socialLinks', index)
    }

    const onTagClick = (type: string) => {
        setCurrentLinks((prev) => [...prev, { type, value: '' }])
    }

    const onDeleteLink = (type: string) => {
        setCurrentLinks((prev) => prev.filter((link) => link.type !== type))
    }

    const filteredOptions = socialLinksOptions.filter((linkId) => !currentLinks?.find(({ type }) => linkId === type))
    return (
        <>
            <div className="landing-constructor__social-links__tags">
                {filteredOptions.map((type) => {
                    return (
                        <div
                            className="landing-constructor__social-links__tag"
                            key={type}
                            onClick={() => onTagClick(type)}
                        >
                            <PlusOutlined />
                            {type?.slice(0, 1).toUpperCase()}
                            {type?.slice(1)}
                        </div>
                    )
                })}
            </div>

            {Boolean(currentLinks?.length) && (
                <div className="landing-constructor__social-links__inputs">
                    {currentLinks.map((socialLink) => {
                        return (
                            <Input
                                key={socialLink.type}
                                prefix={socialLink.value ? socialIconsColored[socialLink.type] : socialIcons[socialLink.type]}
                                suffix={(
                                    <div
                                        className="landing-constructor__social-links__cross-icon"
                                        onClick={() => onDeleteLink(socialLink.type)}
                                    >
                                        <ErrorCrossIcon width="16" height="16" fillColor="#D9DADC"/>
                                    </div>
                                )}
                                className="setting-drawer__text-input"
                                placeholder={socialPlaceholder[socialLink.type]}
                                defaultValue={socialLink?.value || ''}
                                onBlur={(e) => {
                                    const newValue = e.target.value === '' ? '' : e.target.value
                                    onSocialLinkBlur(newValue, socialLink.type)
                                }}
                            />
                        )
                    })}
                </div>
            )}
        </>
    )
}

export default SocialLinksItem

import BlankIcon from "@assets/lpEditor/BlankIcon.tsx";
import AIIcon from "@assets/lpEditor/AIIcon.tsx";
import DefaultIcon from "@assets/lpEditor/DefaultIcon.tsx";
import { ReactNode } from "react";

export const Descriptions: Record<string, ReactNode> = {
    'blank': 'Create your own website from scratch',
    'ai': <div>Generate template based <br/> on your website</div>,
    'default': 'Customize the ready-made template to fit your needs',
}

export const Titles: Record<string, string> = {
    'blank': 'Blank website',
    'ai': 'Ai generated template',
    'default': 'Universal template',
}

export const Image: Record<string, ReactNode> = {
    'blank': <BlankIcon />,
    'ai': <AIIcon />,
    'default': <DefaultIcon />
}
import React from 'react';
import styles from './EventInfoForm.module.css';
import {DatePicker, Divider, Form, Input} from "antd";
import { initEventInfoFormValues } from './utils';
import { CreateOrganizerEventRequestBody, OrganizerEventDataFullItem } from '@store/type-event/events/models';
import { useForm } from 'laravel-precognition-react';
import AvatarUpload from "@components/EventInfoForm/components/AvatarUpload";
import {getRangePickerPlaceholder} from "@shared/utils.ts";
import dayjs, {Dayjs} from "dayjs";
import InfoTooltipIcon from "@icons/InfoTooltipIcon.tsx";
import HeroImage from "@components/EventInfoForm/components/HeroImage";
import Locations from "@components/EventInfoForm/components/Locations";
import Categories from "@components/EventInfoForm/components/Categories";

export type EventInfoFormFooterPropsType = {
    onSubmit: () => void
    isSubmitDisabled: boolean
    isLoading?: boolean
    onPreview?: () => void
}

type EventInfoFormPropsType = {
    Header?: React.ReactNode
    Footer?: React.FC<EventInfoFormFooterPropsType>
    fetchedData?: OrganizerEventDataFullItem;
    onSubmit: (eventData: CreateOrganizerEventRequestBody, isEventInfoStep?: boolean) => void
    isLoading?: boolean;
    isEventInfoStep?: boolean;
    onPreview?: () => void;
}
const apiBaseUrl = import.meta.env.VITE_API_URL as string;

const EventInfoForm: React.FC<EventInfoFormPropsType> = ({
    Header,
    Footer,
    fetchedData,
    onSubmit,
    isLoading,
    isEventInfoStep,
    onPreview
}) => {
    const initialFormValues = initEventInfoFormValues(fetchedData);

    const form = useForm('post', `${apiBaseUrl}/organizer/events`, initialFormValues)

    const { errors, validating, processing, hasErrors } = form
    const hasEmptyFields = Object.values(form.data).some((value) => value === '') || !form.data.schedule.finished_at

    const disabledStartDate = (current: dayjs.Dayjs) => {
        return !!form.data.schedule?.finished_at && current > form.data.schedule.finished_at
    };

    const onSubmitClick = () => {
        const formattedEventData = {
            ...form.data,
            category: (form.data?.category?.id ? form.data.category.id : form.data?.category) as number,
            location: (form.data?.location?.id ? form.data.location.id : form.data?.location) as number,
            schedule: {
                finished_at: form.data?.schedule?.finished_at ? dayjs(form.data.schedule.finished_at).format('YYYY-MM-DD') : '',
                started_at: form.data?.schedule?.started_at ? dayjs(form.data.schedule.started_at).format('YYYY-MM-DD') : '',
            }
        }
        onSubmit(formattedEventData, isEventInfoStep);
    };

    const defaultValueRangeValue: [Dayjs, Dayjs] | undefined = form.data.schedule?.finished_at ? [form.data.schedule.started_at, form.data.schedule.finished_at] : undefined

    return (
        <div className={styles.EventInfoForm}>
            {Header || null}
            <Form
                layout={'vertical'}
                initialValues={form.data}>
                <Form.Item
                    name={"logo"}
                    help={form.errors.logo}
                    validateStatus={form.errors.logo ? "error" : ""}
                >
                    <AvatarUpload
                        onChangeFormState={(_, value) => {
                            if (value !== undefined) {
                                form.setData("logo", value)
                                form.validate('logo')
                            }
                        }}
                        url={form.data.logo}
                    />
                </Form.Item>
                <Divider />
                <Form.Item
                    name={'name'}
                    htmlFor={'EventNameField'}
                    label={<span className={styles.EventInfoForm__inputName}>Event name</span>}
                    help={form.errors.name}
                    validateStatus={form.errors.name ? "error" : ""}
                >
                    <Input
                        placeholder="The Summit 2024"
                        id={"EventNameField"}
                        size={'large'}
                        onChange={(e) => form.setData("name", e.target.value)}
                        onBlur={() => form.validate('name')}
                    />
                </Form.Item>
                <Form.Item
                    // name={'schedule'}
                    htmlFor={'DatesField'}
                    label={<span className={styles.EventInfoForm__inputName}>Dates</span>}
                    help={form.errors?.['schedule.finished_at'] || form.errors?.['schedule.started_at'] ? 'The date must be valid.' : ''}
                    validateStatus={form.errors?.['schedule.finished_at'] || form.errors?.['schedule.started_at'] ? "error" : ""}
                >
                    <DatePicker.RangePicker
                        placeholder={getRangePickerPlaceholder()}
                        id={"DatesField"}
                        size={'large'}
                        style={{ width: '100%' }}
                        defaultValue={defaultValueRangeValue}
                        onChange={(_, info) => {
                            form.setData("schedule", {
                                started_at: dayjs(info[0]).format('YYYY-MM-DD') as unknown as string,
                                finished_at: dayjs(info[1]).format('YYYY-MM-DD') as unknown as string
                            })
                            form.validate('schedule.started_at')
                            form.validate('schedule.finished_at')
                        }}
                        disabledDate={disabledStartDate}
                    />
                </Form.Item>
                <Form.Item
                    name={"image"}
                    label={
                        <div className={styles.EventInfoForm__heroImageBlock}>
                            <span className={styles.EventInfoForm__inputName}>Hero image</span>
                            <InfoTooltipIcon />
                        </div>
                    }
                    help={errors.image}
                    validateStatus={errors.image ? "error" : ""}
                >
                    <HeroImage
                        onChangeFormState={(_, value) => {
                            if (value !== undefined) {
                                form.setData("image", value)
                                form.validate('image')
                            }
                        }}
                        url={form.data.image}
                    />
                </Form.Item>
                <Form.Item
                    name={'description'}
                    label={<span className={styles.EventInfoForm__inputName}>Overview</span>}
                    htmlFor={'OverviewField'}
                    help={errors.description}
                    validateStatus={errors.description ? "error" : ""}
                >
                    <Input.TextArea
                        id={"OverviewField"}
                        rows={4}
                        placeholder="e.g. The Summit 2024 is the premier global tech conference, bringing together industry leaders, innovators, and entrepreneurs to explore the latest trends and technologies shaping the digital landscape"
                        className={styles.EventInfoForm__overview}
                        onChange={(e) => form.setData("description", e.target.value)}
                        onBlur={() => form.validate('description')}
                    />
                </Form.Item>
                <Form.Item
                    name={'url'}
                    htmlFor={'UrlField'}
                    label={<span className={styles.EventInfoForm__inputName}>URL</span>}
                    help={errors.url}
                    validateStatus={errors.url ? "error" : ""}
                >
                    <Input
                        id={"UrlField"}
                        size={'large'}
                        type={'url'}
                        placeholder="www.thesummit.com"
                        onChange={(e) => form.setData("url", e.target.value)}
                        onBlur={() => form.validate('url')}
                    />
                </Form.Item>
                <Form.Item
                    name={'location'}
                    htmlFor={'LocationField'}
                    label={<span className={styles.EventInfoForm__inputName}>Location</span>}
                    help={errors.location}
                    validateStatus={errors.location ? "error" : ""}
                >
                    <Locations
                        defaultValue={form.data.location.id ? { label: form.data.location.slug, value: form.data.location.id } : undefined}
                        className={styles.EventInfoForm__Location}
                        initialPlaceholder={form.data.location?.slug || 'Lisbon, Portugal'}
                        onLocationChange={(_, value) => {
                            form.setData("location", value.id)
                            form.validate("location")
                        }}
                    />
                </Form.Item>
                <Form.Item
                    label={<span className={styles.EventInfoForm__inputName}>Category</span>}
                    help={errors.category}
                    validateStatus={errors.category ? "error" : ""}
                >
                    <Categories
                        defaultValue={form.data.category.name ? { label: form.data.category.name, value: form.data.category.id } : undefined}
                        onCategoryChange={(_, value) => {
                            form.setData("category", value.id)
                        }}
                        onClear={() => {
                            form.setData("category", '')
                            form.validate("category")
                        }}
                        initialPlaceholder={form.data.category?.name || 'Education & EdTech'}
                    />
                </Form.Item>
                <Form.Item
                    name={'participants'}
                    htmlFor={'ParticipantsField'}
                    label={<span className={styles.EventInfoForm__inputName}>Number of participants</span>}
                    help={errors.participants}
                    validateStatus={errors.participants ? "error" : ""}
                >
                    <Input
                        id={"ParticipantsField"}
                        size={'large'}
                        type={'number'}
                        placeholder="500"
                        onChange={(e) => form.setData("participants", e.target.value)}
                        onBlur={() => form.validate("participants")}
                    />
                </Form.Item>
            </Form>
            {Footer ? (
                <Footer
                    isSubmitDisabled={validating || hasErrors || processing || hasEmptyFields}
                    onSubmit={onSubmitClick}
                    isLoading={isLoading || processing || validating}
                    onPreview={onPreview}
                />
            ) : null}
        </div>
    )
};

export default EventInfoForm;
